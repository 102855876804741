import React from 'react'

function SavedReports() {
  return (
  <>     
  <div>   
   
  <div className="bgpages  px-4 py-3 " >
    <div className="row my-3">
   <div className="col-sm-12">
   <h5 className='orangetheme' style={{ fontWeight: "700" }}>SAVED REPORTS</h5>
   <p className="text-light" >
    Access saved reports
   </p>
   </div>
      </div>
      <div className='container'>
        Feature would be here soon!
      
      </div>
      </div>
      </div>
      </>   
  )
}

export default SavedReports