import React from "react";
import quationlogoBg0 from "./Images/quationlogoBg0.png";
import { useState, useEffect, useRef } from "react";
import Loader from "react-js-loader";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { useDispatch } from "react-redux";
import UserService from "../services/UserService";
import mmxlogom from "./Images/mmxlogom.png";
import logosocialmedia from "./Images/logossocialmodeia.png";
import getNotification from "../Redux/Action/action";
import axios from "axios";
import Plot from "react-plotly.js";
import FooterPages from "./FooterPages";
import Chart from "react-apexcharts";
const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_UPLOAD_DATA2 } = process.env;
function Optimizer() {
  const dispatch = useDispatch();
  const [marketoptions, setmarketoptions] = useState(["APTG","DL","GJ","HR","KA","KL","MH","MPCH"])
 
  const [scenariooptions, setscenariooptions] = useState([]);
  const [scenarionewoldscreen, setscenarionewoldscreen] = useState("select");
  const [market, setmarket] = useState("");
  const [loader, setloader] = useState(false);

  const [viewscenariobtn, setviewscenariobtn] = useState(false)
  const [viewscenariodatatable,setviewscenariodatatable]=useState(false)
  const [newscenariofile, setnewscenariofile] = useState("")
  const [isHovered, setIsHovered] = useState(true);
  const [brandoptions, setbrandoptions] = useState([
    { value: "Brand_1", label: "Brand_1" },
    { value: "Brand_2", label: "Brand_2" },
    { value: "Brand_3", label: "Brand_3" },
    { value: "Brand_4", label: "Brand_4" },
    { value: "Brand_5", label: "Brand_5" },
    { value: "Brand_6", label: "Brand_6" },
  ]);
  const [totalBudget, settotalBudget] = useState(0)
  const [displaynames, setdisplaynames] = useState({});
  const [monthtotals, setmonthtotals] = useState([]);
  const colors = [
    "red",
    "blue",
    "green",
    "purple",
    "orange",
    "pink",
    "teal",
    "indigo",
    "brown",
    "grey",
    "olive",
    "navy",
    "maroon",
    "gold",
    "darkgreen",
    "darkred",
    "darkblue",
    "darkorange",
    "darkcyan",
    "darkmagenta",
    "darkyellow",
    "darklime",
    "darkpink",
    "darkteal",
    "darkviolet",
  ];
  const [edit, setedit] = useState([]);
  const [edit2, setedit2] = useState([]);
  const [selectedzone, setselectedzone] = useState("National")
  const [selectedbrand, setselectedbrand] = useState("");
  const [selectedscenarioname, setselectedscenarioname] = useState("");
  const [selectedscenarioid, setselectedscenarioid] = useState("");
  const [selectedscenarionametimestamp, setselectedscenarionametimestamp] =
    useState("");
  const [selectedyear, setselectedyear] = useState("2021-22");
  const [resultscreen, setresultscreen] = useState(false);
  const [resultscreen2, setresultscreen2] = useState(false);
  const [resultscreen3, setresultscreen3] = useState(true);
  const [newscenarionamegiven, setnewscenarionamegiven] = useState("");
  const [originalset2, setoriginalset2] = useState([
    {
      "is_active": 1,
      "lb":
        27035100,
      "sort_order": 2,
      "tl":
        434714824,
      "ub"
        :
        36995400,
      "variables"
        :
        "Variable 6",
      "vehicle_type"
        :
        "variable_6"
    },
    {
      "is_active": 1,
      "lb":
        98654234,
      "sort_order": 2,
      "tl":
        2124546,
      "ub"
        :
       5757525,
      "variables"
        :
        "Variable 6",
      "vehicle_type"
        :
        "variable_6"
    },
    {
      "is_active": 1,
      "lb":
       54545,
      "sort_order": 2,
      "tl":
        455454,
      "ub"
        :
      312454,
      "variables"
        :
        "Variable 7",
      "vehicle_type"
        :
        "variable_7"
    },
    {
      "is_active": 1,
      "lb":
        54554444,
      "sort_order": 2,
      "tl":
       245454,
      "ub"
        :
        54665457,
      "variables"
        :
        "Variable 8",
      "vehicle_type"
        :
        "variable_8"
    },
    {
      "is_active": 1,
      "lb":
        27035,
      "sort_order": 2,
      "tl":
        4347,
      "ub"
        :
        3699,
      "variables"
        :
        "Variable 10",
      "vehicle_type"
        :
        "variable_10"
    },

  ]);

  const [originalset, setoriginalset] = useState([
    {
        "attribute": "Competition Event",
        "attribute_backend": "competition_event",
        "subtotal": 6.0,
        "description": "Values to be entered 0 & 1\n0 :No competetion Event\n1 : Competition Event exists",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.0
            }
        ]
    },
    {
        "attribute": "Consumer Offer",
        "attribute_backend": "consumer_offer",
        "subtotal": 434714824.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 21692490.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 14110899.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 35713362.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 38284834.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 41917530.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 40061003.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 47646576.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 32863870.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 57272161.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 31032458.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 32935649.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 41183992.0
            }
        ]
    },
    {
        "attribute": "Corporate Print Advertisement",
        "attribute_backend": "corporate_print_advertisement",
        "subtotal": 705.0,
        "description": "Number of Insert at Corporate Level",
        "allow_decimal": 0,
        "frozen": 0,
        "is_optimizable":1,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 201.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 141.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 68.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 204.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 89.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 2.0
            }
        ]
    },
    {
        "attribute": "Corporate TV Advertisement",
        "attribute_backend": "corporate_tv_advertisement",
        "subtotal": 19.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 11.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 2.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 7.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Harrier TV Advertisement",
        "attribute_backend": "harrier_tv_advertisement",
        "subtotal": 0.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "is_optimizable":1,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "IPL",
        "attribute_backend": "ipl",
        "subtotal": 2.0,
        "description": "0: No IPL\n1: IPL",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Manpower Incentive",
        "attribute_backend": "manpower_incentive",
        "subtotal": 74589761.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 3804066.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 2628875.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 5831054.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 7139249.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 8105926.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 6032101.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 8662982.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 5314596.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 9045181.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 5401808.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 5827981.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 6795943.0
            }
        ]
    },
    {
        "attribute": "Safari TV Advertisement",
        "attribute_backend": "safari_tv_advertisement",
        "subtotal": 514.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 331.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 21.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 119.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 43.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Seasonal Index",
        "attribute_backend": "seasonal_index",
        "subtotal": 13.24,
        "description": "",
        "allow_decimal": 1,
        "frozen": 0,
        "to_show_in": "Not To show",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.62
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.38
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.88
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.08
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 1.13
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.05
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.55
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.94
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.36
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.41
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.35
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.48
            }
        ]
    },
    {
        "attribute": "Turnaround Time",
        "attribute_backend": "turnaround_time",
        "subtotal": 4.0,
        "description": "0: No Waiting Period\n1: Higher Waiting Period",
        "allow_decimal": 0,
        "frozen": 0,
        "is_optimizable":1,
        "to_show_in": "Not To show",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    }
]);
  const [sampledataset, setsampledataset] = useState([
    {
        "attribute": "Competition Event",
        "attribute_backend": "competition_event",
        "subtotal": 6.0,
        "description": "Values to be entered 0 & 1\n0 :No competetion Event\n1 : Competition Event exists",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.0
            }
        ]
    },
    {
        "attribute": "Consumer Offer",
        "attribute_backend": "consumer_offer",
        "subtotal": 434714824.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 21692490.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 14110899.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 35713362.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 38284834.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 41917530.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 40061003.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 47646576.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 32863870.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 57272161.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 31032458.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 32935649.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 41183992.0
            }
        ]
    },
    {
        "attribute": "Corporate Print Advertisement",
        "attribute_backend": "corporate_print_advertisement",
        "subtotal": 705.0,
        "description": "Number of Insert at Corporate Level",
        "allow_decimal": 0,
        "frozen": 0,
        "is_optimizable":1,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 201.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 141.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 68.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 204.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 89.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 2.0
            }
        ]
    },
    {
        "attribute": "Corporate TV Advertisement",
        "attribute_backend": "corporate_tv_advertisement",
        "subtotal": 19.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 11.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 2.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 7.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Harrier TV Advertisement",
        "attribute_backend": "harrier_tv_advertisement",
        "subtotal": 0.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "is_optimizable":1,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "IPL",
        "attribute_backend": "ipl",
        "subtotal": 2.0,
        "description": "0: No IPL\n1: IPL",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Manpower Incentive",
        "attribute_backend": "manpower_incentive",
        "subtotal": 74589761.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 3804066.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 2628875.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 5831054.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 7139249.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 8105926.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 6032101.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 8662982.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 5314596.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 9045181.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 5401808.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 5827981.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 6795943.0
            }
        ]
    },
    {
        "attribute": "Safari TV Advertisement",
        "attribute_backend": "safari_tv_advertisement",
        "subtotal": 514.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 331.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 21.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 119.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 43.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Seasonal Index",
        "attribute_backend": "seasonal_index",
        "subtotal": 13.24,
        "description": "",
        "allow_decimal": 1,
        "frozen": 0,
        "to_show_in": "Not To show",
        "is_optimizable":1,
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.62
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.38
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.88
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.08
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 1.13
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.05
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.55
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.94
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.36
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.41
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.35
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.48
            }
        ]
    },
    {
        "attribute": "Turnaround Time",
        "attribute_backend": "turnaround_time",
        "subtotal": 4.0,
        "description": "0: No Waiting Period\n1: Higher Waiting Period",
        "allow_decimal": 0,
        "frozen": 0,
        "is_optimizable":1,
        "to_show_in": "Not To show",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    }
]);
  const [plotdata, setplotdata] = useState({
    "optimized_data": {
      "Variables": [
        "Consumer Offer",
        "Corporate Print Advertisement",
        "Corporate TV Advertisement",
        "Harrier TV Advertisement",
        "Manpower Incentive",
        "Safari TV Advertisement",
        "Sales"
      ],
      "Before_optimization": [
        434714824.27,
        705.0958,
        19.091099999999997,
        0,
        74589760.78400001,
        514.3743999999999,
        28458
      ],
      "Optimized_plan": [
        216365165.10613835,
        715.0984561707802,
        18.964596104802375,
        66.45858045865951,
        34150362.27685487,
        135.9561215917991,
        72674.59362440242
      ],
      "diff": [
        -50.228252402141536,
        1.4186237062793654,
        -0.6626328247069174,
        0,
        -54.21575036854074,
        -73.56864540851973,
        155.37491610233474
      ]
    }
  });
  const [plotdata1, setplotdata1] = useState([]);
  const [plotdata2, setplotdata2] = useState([]);
  const [options2,setoptions2]=useState({})
  const [plotdata3, setplotdata3] = useState([]);
  const [options3,setoptions3]=useState({})
  const [plotdata4, setplotdata4] = useState([]);
  const [options4,setoptions4]=useState({})
  const [plotdata5, setplotdata5] = useState([]);
  const [options5,setoptions5]=useState({})
  const [plotdata6, setplotdata6] = useState([]);
  const [options6,setoptions6]=useState({})
  const [plotdata7, setplotdata7] = useState([]);
  const [options7,setoptions7]=useState({})
  const [plotdata8, setplotdata8] = useState([]);
  const [options8,setoptions8]=useState({})
  const [plotdata9, setplotdata9] = useState([]);
  const [options9,setoptions9]=useState({})
  const [allplotsdataset, setallplotsdataset] = useState({
    "base": {
        "model_id": "[1]",
        "f_year": "[\"2021-22\"]",
        "brand": "[\"Harrier\"]",
        "user_id": "[\"admin\"]",
        "created_dt": "[\"2024-09-18T10:41:24\"]",
        "scenario_name": "[\"Base Scenario\"]",
        "marketing_pc": [
            0.0
        ],
        "marketing_variable": [
            0.0
        ],
        "base_pc": [
            100.0
        ],
        "base": "[28053.087898353]"
    },
    "contribution": {
        "model_id": "[1,1,1,1,1,1,1,1,1]",
        "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
        "marketing_variable": [
            "Competition Event",
            "Consumer Offer",
            "Corporate Print Advertisement",
            "Corporate TV Advertisement",
            "Harrier TV Advertisement",
            "IPL",
            "Manpower Incentive",
            "Safari TV Advertisement",
            "Turnaround Time"
        ],
        "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
        "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
        "created_dt": "[\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\"]",
        "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
        "contribution_pc": [
          11,13,9,5,3,18,20,3,18
        ],
        "contribution": [
            -3856.28,
            881.74,
            1363.33,
            0.0,
            0.0,
            385.31,
            387.32,
            2899.41,
            -2259.77
        ]
    },
    "roi": {
        "model_id": "[1,1,1,1,1,1,1,1,1,1]",
        "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
        "marketing_variable": [
            "Competition Event",
            "Consumer Offer",
            "Corporate Print Advertisement",
            "Corporate TV Advertisement",
            "Harrier TV Advertisement",
            "IPL",
            "Manpower Incentive",
            "Safari TV Advertisement",
            "Seasonal Index",
            "Turnaround Time"
        ],
        "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
        "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
        "created_dt": "[\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\"]",
        "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
        "roi": [
            0.0,
            1.81,
            32.1,
            -1.0,
            0.0,
            0.0,
            9.11,
            28.83,
            0.0,
            0.0
        ]
    },
    "predictions": {
        "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
        "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
        "created_dt": "[\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\"]",
        "week_year": [
            "2021-APR",
            "2021-MAY",
            "2021-JUN",
            "2021-JUL",
            "2021-AUG",
            "2021-SEP",
            "2021-OCT",
            "2021-NOV",
            "2021-DEC",
            "2022-JAN",
            "2022-FEB",
            "2022-MAR"
        ],
        "weekly_Planned_sales": [
            2629.226125987585,
            765.5225672413728,
            1754.3702375491735,
            2110.5942683931476,
            2765.7224618237374,
            2438.3735914272515,
            2886.2376084099155,
            2034.2668355295707,
            2983.657651389547,
            2625.013716855235,
            2383.0941637194605,
            2677.008670026999
        ]
    },
    "monthly_base": {
        "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
        "month_year": "[\"2021-04\",\"2021-05\",\"2021-06\",\"2021-07\",\"2021-08\",\"2021-09\",\"2021-10\",\"2021-11\",\"2021-12\",\"2022-01\",\"2022-02\",\"2022-03\"]",
        "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
        "created_dt": "[\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\"]",
        "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
        "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
        "predict_sales": "[2629.2261259876,765.5225672414,1754.3702375492,2110.5942683931,2765.7224618237,2438.3735914273,2886.2376084099,2034.2668355296,2983.6576513895,2625.0137168552,2383.0941637195,2677.008670027]",
        "contribution": "[-593.3595620963,-73.8427621351,49.0663187678,-21.0691520724,700.9914359948,446.3723366005,-354.303280711,330.0285244472,221.1123705946,-239.6831025592,-304.9802063867,-359.2610329304]",
        "base": "[3222.5856880839,839.3653293765,1705.3039187814,2131.6634204656,2064.7310258289,1992.0012548267,3240.5408891209,1704.2383110824,2762.5452807949,2864.6968194144,2688.0743701062,3036.2697029574]",
        "model_id": "[1,1,1,1,1,1,1,1,1,1,1,1]"
    },
    "monthly_contribution": {
        "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
        "month_year": "[\"2021-04\",\"2021-05\",\"2021-06\",\"2021-07\",\"2021-08\",\"2021-09\",\"2021-10\",\"2021-11\",\"2021-12\",\"2022-01\",\"2022-02\",\"2022-03\"]",
        "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
        "created_dt": "[\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\",\"2024-09-18T10:41:24\"]",
        "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
        "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
        "competition_event": "[-544.4467533937,0.0,0.0,0.0,0.0,-504.9259827033,-597.6673820063,0.0,-617.8406282674,-543.5744691678,-493.4790003964,-554.3413192182]",
        "consumer_offer": "[90.9588834088,0.0,50.3192703313,64.9988614321,92.7790746246,78.4497450922,107.9985512344,53.1914634713,128.158186085,64.0401113464,62.4711872599,88.3712023764]",
        "corporate_print_advertisement": "[0.0,0.0,207.1448456196,233.612348388,263.7470413056,288.6578718218,0.0,0.0,301.4520201538,0.0,0.0,68.7160195629]",
        "corporate_tv_advertisement": "[0.0,0.0,0.0,0.0000510764,0.0000121692,0.0,0.0000444481,0.0,0.0,0.0,0.0,0.0]",
        "harrier_tv_advertisement": "[0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]",
        "ipl": "[0.0,0.0,0.0,0.0,0.0,385.3138110414,0.0,0.0,0.0,0.0,0.0,0.0]",
        "manpower_incentive": "[35.7286150497,0.0,20.9083536692,31.4969274954,46.475472259,30.289034636,51.3377249214,21.4366796887,54.9740890003,28.2980282368,28.3827216041,37.9930643485]",
        "safari_tv_advertisement": "[642.8122402627,164.4453288639,316.7855683213,305.798038983,297.9898356364,168.5878567124,84.0277806914,255.4003812872,354.3687036229,211.5532270254,97.6448851456,0.0]",
        "turnaround_time": "[-818.4125474238,-238.288090999,-546.0917191737,-656.9753794474,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]",
        "model_id": "[1,1,1,1,1,1,1,1,1,1,1,1]"
    }
});
  const sectionRef = useRef(null);
  const [monthlist, setmonthlist] = useState(["April", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec", "Jan", "Feb", "March"])
  useEffect(() => {
    handlebrandmenu();
    fetchscenariooptions();
    //fetchdatasettableonload();

  }, []);

  const orderedMonths = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

  const scrollToTop = () => {
    if (sectionRef.current) {
      const yOffset = -50; // Adjust this value based on your preference
      const elementTop = sectionRef.current.offsetTop;
      const offsetPosition = elementTop + yOffset;

      window.scrollBy(500, 500);
      // window.scroll({
      //   top: offsetPosition,
      //   behavior: 'smooth',
      // });
    }
  };
 
  const handlebrandmenu = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("jwttoken", UserService.getToken());
        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/brand/getbrands`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          setbrandoptions(getResponse.data);
          setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/optimizer`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/optimizer`,
        });
      }, 1000);
    }
  };
  const scrollToSection = (sectionname) => {
    // Replace 'section2' with the id of the section you want to scroll to

    if (document.getElementById(sectionname)) {
      document
        .getElementById(sectionname)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      dispatch(
        getNotification({
          message: "Please proceed with first analysis ",
          type: "Default",
        })
      );
    }
  };

  const fetchscenariooptions = async () => {
    if (UserService.isLoggedIn()) {
      if (true) {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("jwttoken", UserService.getToken());

          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/Simulation/getscenario`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: sendData,
          };
          const getResponse = await axios(config);
          //console.log(getResponse);

          if (getResponse.status === 200) {
            if (getResponse.data !== "Invalid User!") {
              // setscenariooptions(getResponse.data?.map((item)=>{
              //   return {label:item.scenario_name,value:item.scenario_name}
              // }));
              setscenariooptions(getResponse.data);
              // setselectedscenarioname(getResponse.data[1].scenario_name);
              // setselectedscenarioid(getResponse.data[1].id)
              // setselectedscenarionametimestamp(getResponse.data[1].updated_dt);
            }
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      } else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/optimizer`,
        });
      }, 1000);
    }
    setloader(false);
  };




  const fetchdatasettable = async (value) => {
    console.log(value)
    // setviewscenariodatatable(true)
    // setresultscreen(true)
    // setTimeout(()=>{
    //   document.getElementById("main1").classList.remove("col-sm");
    //   document.getElementById("main1").classList.add("col-sm-4");
    //   document.getElementById("main2").classList.remove("col-sm");
    //   document.getElementById("main2").classList.add("col-sm-8");
    // },100)
//     if (UserService.isLoggedIn()) {
//       try {
//         setloader(true)
//         let config = {};
//         if (selectedscenarioname === "Base Scenario") {
//           const FormData = require("form-data");
//           const sendData = new FormData();

//           //sendData.append("jwttoken", UserService.getToken());
//           sendData.append("brand", selectedbrand);
//           sendData.append("f_year", selectedyear);

//           config = {
//             method: "post",
//             url: `${REACT_APP_UPLOAD_DATA2}/base_scenario/`,
//             headers: {
//               Accept: "text/plain",
//               "Content-Type": "application/json",
//               "access_token": UserService.getToken(),
//             },
//             data: sendData,
//           };

//         }
//         else {
//           const FormData = require("form-data");
//           const sendData = new FormData();

//           sendData.append("scenario_name", value.scenario_name);
//           sendData.append("scenario_user", "admin");
//           // sendData.append("jwttoken", UserService.getToken());
//           sendData.append("scenario_id", value.id);

//           config = {
//             method: "post",
//             url: `${REACT_APP_UPLOAD_DATA2}/other_scenarios/`,
//             headers: {
//               Accept: "text/plain",
//               "Content-Type": "application/json",
//               "access_token": UserService.getToken(),
//             },
//             data: sendData,
//           };

//         }

//         const getResponse = await axios(config);
//         console.log(getResponse)

//         if (getResponse.status === 200) {

//           if (Array.isArray(getResponse.data?.table1) && getResponse.data !== "No Records Found" && getResponse.data?.table1.length !== 0
//           ) {
// setviewscenariodatatable(true)
      
//             setdisplaynames({
//               ...displaynames,
//               brand: selectedbrand,
//               scenarioname: selectedscenarioname,
//               id:selectedscenarioid,
//               zone: selectedzone,
//               year: selectedyear,
//               timestamp: selectedscenarionametimestamp
//             })


//             setsampledataset(getResponse?.data?.table1.sort((a, b) => a.is_optimizable - b.is_optimizable))
//             setoriginalset(getResponse?.data?.table1.sort((a, b) => a.is_optimizable - b.is_optimizable));
//             //setsampledataset2(getResponse?.data?.table2)
//             setoriginalset2(getResponse?.data?.table2);
//             //setresultscreen2(true)
//             let arr = new Array(13).fill(0); // Initialize an array of size 13 with all values set to 0

//             getResponse?.data?.table1.forEach((item) => {
//               item.month_data.forEach((monthData, index) => {

//                 arr[monthData.month] += Number(monthData.value);
//               });
//             });
//             arr = arr.slice(1);
//             arr.push(getResponse?.data?.table1.reduce((prev, next) => prev + next.subtotal, 0))
//             // Remove the first element if you don't need it, as months are 1-based


//             console.log(arr);
//             setmonthtotals(arr)
//             setTimeout(()=>{
//               document.getElementById("main1").classList.remove("col-sm");
//               document.getElementById("main1").classList.add("col-sm-4");
//               document.getElementById("main2").classList.remove("col-sm");
//               document.getElementById("main2").classList.add("col-sm-8");
//             },100)
//           }
//           else if (getResponse.data?.table1.length === 0) {
//             if(displaynames){
//               setselectedbrand(displaynames.brand)
//               setselectedscenarioname(displaynames.scenarioname)
//               setselectedzone(displaynames.zone)
//               setselectedyear(displaynames.year)
//               setselectedscenarioid(displaynames.id)
//             }
//             else{
//               fetchscenariooptions()
//               handlebrandmenu()
//             }
//             dispatch(
//               getNotification({
//                 message: `There is no valid data to display for selected brand and scenario combination`,
//                 type: "default",
//               }))
//           }
//           else if (getResponse?.data[0]?.Error === "Invalid User!") {
//             UserService.doLogin({
//               redirectUri: `${REACT_APP_REDIRECT_URI}/optimizer`,
//             });
//           }
//           else {
          
//             if(displaynames){
//               setselectedbrand(displaynames.brand || brandoptions[0].brand)
//               setselectedscenarioname(displaynames.scenarioname || scenariooptions[1].scenario_name)
//               setselectedzone(displaynames.zone || 'National')
//               setselectedyear(displaynames.year || '2021-22')
//               setselectedscenarioid(displaynames.id || scenariooptions[1].id)
//             }
//             dispatch(
//               getNotification({
//                 message: `There is no valid data to display for selected brand and scenario combination`,
//                 type: "default",
//               })

//             );

//             setresultscreen2(false)
//           }
//         }
//       } catch (err) {
//         console.log("Server Error", err);
//         if(displaynames){
//           setselectedbrand(displaynames.brand || brandoptions[0].brand)
//           setselectedscenarioname(displaynames.scenarioname || scenariooptions[1].scenario_name)
//           setselectedzone(displaynames.zone || 'National')
//           setselectedyear(displaynames.year || '2021-22')
//           setselectedscenarioid(displaynames.id || scenariooptions[1].id)
//         }
//         if (err.response && err.response.status === 500) {
//           dispatch(
//             getNotification({
//               message: "Server is Down! Please try again after sometime",
//               type: "default",
//             })
//           );
//         } else if (err.response && err.response.status === 400) {
//           dispatch(
//             getNotification({
//               message: "Input is not in prescribed format",
//               type: "default",
//             })
//           );
//         } else if (err.response && err.response.status === 422) {
//           dispatch(
//             getNotification({
//               message: "Input is not in prescribed format",
//               type: "default",
//             })
//           );
//         } else if (err.response && err.response.status === 404) {
//           dispatch(
//             getNotification({
//               message: "Page not Found",
//               type: "default",
//             })
//           );
//         } else if (err.response && err.response.status === 401) {
//           dispatch(
//             getNotification({
//               message: "Session expired! Please log in again",
//               type: "default",
//             })
//           ); setTimeout(UserService.doLogin(), 1000)
//         } else {
//           dispatch(
//             getNotification({
//               message: "Server is Down! Please try again after sometime",
//               type: "default",
//             })
//           );
//         }
//       }
//     } else {
//       setTimeout(() => {
//         UserService.doLogin({
//           redirectUri: `${REACT_APP_REDIRECT_URI}/optimizer`,
//         });
//       }, 1000);
//     }
       setviewscenariodatatable(true) 
    setTimeout(()=>{

      document.getElementById("main1").classList.remove("col-sm");
        document.getElementById("main1").classList.add("col-sm-4");
        document.getElementById("main2").classList.remove("col-sm");
        document.getElementById("main2").classList.add("col-sm-8");
       
    },100)
  };

  const changeelements = (variableIndex, valueIndex, e) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].month_data[valueIndex].frozen === 0) {
      if (updatedDataset[variableIndex].frozen === 0) {
        updatedDataset[variableIndex].month_data[valueIndex].value =
          parseInt(e.target.value) || 0;
        updatedDataset[variableIndex].subtotal = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, value) => acc + value.value, 0);
      } else {
        const subtotalFrozenValues = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, item, index) => {
          if (item.frozen === 1) {
            return acc + item.value;
          } else {
            return acc;
          }
        }, 0);
        const subtotalValue =
          updatedDataset[variableIndex].subtotal -
          (parseInt(e.target.value) || 0) -
          subtotalFrozenValues;
        const month_data = updatedDataset[variableIndex].month_data;
        // console.log(subtotalValue, month_data);

        if (subtotalValue < 0) {
          dispatch(getNotification({ message: "Values not valid", type: "danger" }))
        }
        // else if(subtotalValue===0){
        //   console.log("h")
        //   updatedDataset[variableIndex].month_data[valueIndex].value =
        //   parseInt(e.target.value) || 0;
        // }
        else {
          updatedDataset[variableIndex].month_data[valueIndex].value =
            parseInt(e.target.value) || 0;
          const nonFrozenArray = updatedDataset[
            variableIndex
          ].month_data.filter((it, index) => {
            if (index === valueIndex) {
            } else {
              return it.frozen === 0;
            }
          });
          //console.log(nonFrozenArray);

          const numberOfNonFrozenMonths = nonFrozenArray.length;
          if (numberOfNonFrozenMonths > 0) {
            const subtotalNonFrozenValues = nonFrozenArray.reduce(
              (acc, item) => acc + item.value,
              0
            );
            //console.log(subtotalNonFrozenValues);

            updatedDataset[variableIndex].month_data = month_data.map(
              (item, index) => {
                if (item.frozen === 0 && index !== valueIndex) {
                  const prorataValue = parseFloat(
                    (subtotalValue * (item.value / subtotalNonFrozenValues)).toFixed(
                      2
                    )
                  );

                  return {
                    ...item,
                    value: prorataValue,
                  };
                } else {
                  return item;
                }
              }
            );
          }
        }
      }

      setsampledataset(updatedDataset);
    }
  };

  const changeelementsdecimal = (variableIndex, valueIndex, e) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].month_data[valueIndex].frozen === 0) {
      if (updatedDataset[variableIndex].frozen === 0) {
        updatedDataset[variableIndex].month_data[valueIndex].value =
          e.target.value || 0;
        updatedDataset[variableIndex].subtotal = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, value) => acc + parseFloat(value.value), 0);

      }
      else {
        const subtotalFrozenValues = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, item, index) => {
          if (item.frozen === 1) {
            return acc + parseFloat(item.value);
          } else {
            return acc;
          }
        }, 0);
        //console.log(subtotalFrozenValues);
        const subtotalValueforprorata = updatedDataset[variableIndex].subtotal - (parseFloat(e.target.value) || 0) - subtotalFrozenValues;
        ;


        if (subtotalValueforprorata < 0) {
          dispatch(getNotification({ message: "Values not valid", type: "danger" }))
        }
        // else if(subtotalValue===0){
        //   console.log("h")
        //   updatedDataset[variableIndex].month_data[valueIndex].value =
        //   parseInt(e.target.value) || 0;
        // }
        else {
          updatedDataset[variableIndex].month_data[valueIndex].value =
            parseFloat(e.target.value) || 0;
          const nonFrozenArray = updatedDataset[
            variableIndex
          ].month_data.filter((it, index) => {
            if (index === valueIndex) {
            } else {
              return it.frozen === 0;
            }
          });
          //console.log(nonFrozenArray);

          const numberOfNonFrozenMonths = nonFrozenArray.length;
          if (numberOfNonFrozenMonths > 0) {
            const subtotalNonFrozenValues = nonFrozenArray.reduce((acc, item) => acc + parseFloat(item.value), 0);


            //console.log(subtotalNonFrozenValues);
            updatedDataset[variableIndex].month_data = updatedDataset[variableIndex].map(
              (item, index) => {
                if (item.frozen === 0 && index !== valueIndex) {
                  const prorataValue =
                    (subtotalValueforprorata * (parseFloat(item.value) / subtotalNonFrozenValues)

                    );

                  return {
                    ...item,
                    value: parseFloat(prorataValue),
                  };
                } else {
                  return item;
                }
              }
            );
          }
        }
      }
      setsampledataset(updatedDataset);
    }
  };

  const changesubtotal = (e, variableIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].frozen === 0) {
      const subtotalValue = parseInt(e.target.value) || 0;

      if (subtotalValue === 0 || Number.isNaN(subtotalValue)) {

        updatedDataset[variableIndex].subtotal = 0;
        setsampledataset(updatedDataset);
      }
      else {

        const month_data = updatedDataset[variableIndex].month_data;
        const nonFrozenArray = updatedDataset[variableIndex].month_data.filter(
          (it) => it.frozen === 0
        );
        const numberOfNonFrozenMonths = nonFrozenArray.length;
        if (numberOfNonFrozenMonths > 0) {
          const subtotalNonFrozenValues = nonFrozenArray.reduce(
            (acc, item) => acc + item.value,
            0
          );
          console.log(subtotalNonFrozenValues)
          updatedDataset[variableIndex].month_data = month_data.map((item) => {
            if (item.frozen === 0) {
              console.log(subtotalValue)
              const prorataValue =
                subtotalValue * (item.value / subtotalNonFrozenValues);

              return {
                ...item,
                value: prorataValue,
              };
            } else {
              return item;
            }
          });
          updatedDataset[variableIndex].subtotal = subtotalValue;
          setsampledataset(updatedDataset);
        }

      }
    }
  };

  const changesubtotaldecimal = (e, variableIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].frozen === 0) {
      const subtotalValue = e.target.value || 0;
      if (subtotalValue === "0" || subtotalValue === 0 || Number.isNaN(subtotalValue)) {
        console.log("h")
        // console.log("Condition met. Do nothing.");
        updatedDataset[variableIndex].subtotal = 0;
        setsampledataset(updatedDataset);
      }
      else {

        const month_data = updatedDataset[variableIndex].month_data;
        const nonFrozenArray = updatedDataset[variableIndex].month_data.filter(
          (it) => it.frozen === 0
        );
        const numberOfNonFrozenMonths = nonFrozenArray.length;
        if (numberOfNonFrozenMonths > 0) {
          const subtotalNonFrozenValues = nonFrozenArray.reduce(
            (acc, item) => acc + item.value,
            0
          );
          updatedDataset[variableIndex].month_data = month_data.map((item) => {
            if (item.frozen === 0) {
              const prorataValue =
                subtotalValue * (item.value / subtotalNonFrozenValues);
              return {
                ...item,
                value: parseFloat(prorataValue),
              };
            } else {
              return item;
            }
          });
          updatedDataset[variableIndex].subtotal = subtotalValue;
          setsampledataset(updatedDataset);
        }
      }
    }
  };

  const handlecancel = (variableIndex) => {

    const updatedDataset1 = [...sampledataset];
    const updatedDataset2 = [...originalset];
    updatedDataset1[variableIndex].month_data = updatedDataset2[
      variableIndex
    ].month_data.map((it) => {
      return it;
    });
    updatedDataset1[variableIndex].subtotal = updatedDataset2[variableIndex].subtotal;
    updatedDataset1[variableIndex].frozen = 0;
    setsampledataset(updatedDataset1);
    //setsampledataset(originalset);
  };

  const clearAll = (variableIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset));
    updatedDataset[variableIndex].month_data = updatedDataset[
      variableIndex
    ].month_data.map((item) => {
      return { ...item, value: 0, frozen: 0 };
    });
    updatedDataset[variableIndex].subtotal = 0;
    //console.log(updatedDataset[variableIndex]);
    setsampledataset(updatedDataset);
  };
  const togglelock = (variableIndex, valueIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].month_data[valueIndex].frozen === 0) {
      updatedDataset[variableIndex].month_data[valueIndex].frozen = 1;
    } else if (
      updatedDataset[variableIndex].month_data[valueIndex].frozen === 1
    ) {
      updatedDataset[variableIndex].month_data[valueIndex].frozen = 0;
    }
    setsampledataset(updatedDataset);
  };
  const togglelocksubtotal = (variableIndex) => {
    const updatedDataset = [...sampledataset];
    if (updatedDataset[variableIndex].frozen === 0) {
      updatedDataset[variableIndex].frozen = 1;
    } else if (updatedDataset[variableIndex].frozen === 1) {
      updatedDataset[variableIndex].frozen = 0;
    }
    setsampledataset(updatedDataset);
  };
  const getUserID = () => {
    const token = UserService.getToken();
    const subs = parseJwt(token);
    // console.log(token);
    const sub = subs.sub;
    //console.log(sub);
    return sub;
  };
  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };
  const predictchart = async () => {
    setviewscenariodatatable(false)
    if (UserService.isLoggedIn()) {
if(market && selectedscenarioname && selectedbrand){
  setloader(true)
  setTimeout(()=>{
setloader(false)
setresultscreen2(true)
let arr = [];
for (let i = 0; i < plotdata.optimized_data.Variables.length; i++) {
  arr.push({
    Variables:plotdata.optimized_data.Variables[i],
    Before_optimization: Number(plotdata.optimized_data.Before_optimization[i].toFixed(0)),
    Optimized_plan:Number(plotdata.optimized_data.Optimized_plan[i].toFixed(0)),
    diff: Number(plotdata.optimized_data.diff[i].toFixed(0))
  });
}
setplotdata1(arr)
setoptions2(
  {
    labels: ['Base', 'Marketing'],
    plotOptions: {
      pie: {
        donut: {
          size: '40%',
        },
      },

    },
    title: {
      text: ' Base Vs Marketing (Planned)',
      align: 'center',
      style:{
        fontWeight:"100"
      }
    },
    colors: ['#007bff', '#f4d03f'],
    chart: {
      id: `Download-Base vs Marketing chart`,
      type: "pie",
      toolbar: {
        show: true,
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },





  });

setplotdata2([{ name: "Base", data: 83 },
{ name: "Marketing", data: 17 }

])
setoptions3(
  {
    labels: ['Base', 'Marketing'],
    plotOptions: {
      pie: {
        donut: {
          size: '40%',
        },
      },

    },
    title: {
      text: ' Base vs Marketing (Optimized)',
      align: 'center',
      style:{
        fontWeight:"100"
      }
    },
    colors: ['#007bff', '#f4d03f'],
    chart: {
      id: `Download-Base vs Marketing chart`,
      type: "pie",
      toolbar: {
        show: true,
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },





  });

setplotdata3([{ name: "Base", data: 85 },
{ name: "Marketing", data: 15 }

])
setoptions4(
  {
    chart: {
      id: `Download-Contribution by Variable`,
      type: "bar",

      toolbar: {
        show: true,
      },
    },
    grid:{
      show:false
    },
    title: {
      text: 'Contribution-by Variable(Planned)',
      align: 'center',
      style:{fontWeight:"100"}
    },
    plotOptions:{
      bar:{dataLabels: {
        position: 'top',
    },
    },},
    colors: ['#e22f37'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },

    xaxis: {
      categories:allplotsdataset.contribution?.marketing_variable,
      title: {
        text: 'Variables',
        style:{fontWeight:"100"}
      },

      labels: {
        style:{fontWeight:"100"},
        rotate: -45,

        formatter: function (val) {
          return val
        }
      },
    },
    yaxis: [
      {
        seriesName: 'Percentage',
        opposite: false,
        axisBorder: {
          show: true // Ensures Y-axis line is visible
        },
        
        labels: {
          style: {
           fontWeight:"100",

          },
          formatter: function (val) {
            return val + '%'
          }
        },
        title: {
          text: "Percentage",
          showAlways: true,
          floating: true,
          style: {
            fontWeight:"100",
 
           },

        },
      },
      ,
      
 


   
    ],
    annotations: {
      yaxis: [{
        y: 0, // Place the annotation at zero
        borderColor: '#939598',
        label: {
          text: '',
          style: {
            color: '#939598',
            background: '#000'
          }
        }
      }]
    } 

  });
setplotdata4([{ name: "Contribution Percentage", data: allplotsdataset.contribution?.contribution_pc.map((it) => { return Number(it.toFixed(0)) }) },

])
setoptions5(
  {
    chart: {
      id: `Download-Contribution by Variable`,
      type: "bar",

      toolbar: {
        show: true,
      },
    },
    grid:{
      show:false
    },
    title: {
      text: 'Contribution-by Variable(Planned)',
      align: 'center',
      style:{fontWeight:"100"}
    },
    plotOptions:{
      bar:{dataLabels: {
        position: 'top',
    },
    },},
    colors: ['#e22f37'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },

    xaxis: {
      categories:allplotsdataset.contribution?.marketing_variable,
      title: {
        text: 'Variables',
        style:{fontWeight:"100"}
      },

      labels: {
        style:{fontWeight:"100"},
        rotate: -45,

        formatter: function (val) {
          return val
        }
      },
    },
    yaxis: [
      {
        seriesName: 'Contribution Percentage',
        opposite: false,
        axisBorder: {
          show: true // Ensures Y-axis line is visible
        },
        
        labels: {
          style: {
           fontWeight:"100",

          },
          formatter: function (val) {
            return val + '%'
          }
        },
        title: {
          text: "Percentage",
          showAlways: true,
          floating: true,
          style: {
            fontWeight:"100",
 
           },

        },
      },
      ,
      
 


   
    ],
    annotations: {
      yaxis: [{
        y: 0, // Place the annotation at zero
        borderColor: '#939598',
        label: {
          text: '',
          style: {
            color: '#939598',
            background: '#000'
          }
        }
      }]
    } 

  });
setplotdata5([{ name: "Contribution Percentage", data:allplotsdataset.contribution?.contribution_pc.map((it) => { return Number(it.toFixed(0))-2 }) },

])
setoptions6(
  {
    chart: {
      id: `Download-Contribution by Base`,
      type: "bar",

      toolbar: {
        show: true,
      },
    },
    grid:{
      show:false
    },
    title: {
      text: 'Contribution-by Base(Planned)',
      align: 'center',
      style:{fontWeight:"100"}
    },
    plotOptions:{
      bar:{dataLabels: {
        position: 'top',
    },
    },},
    colors: ['#6ab53c'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },

    xaxis: {
      categories:allplotsdataset.contribution?.marketing_variable,
      title: {
        text: 'Base',
        style:{fontWeight:"100"}
      },

      labels: {
        style:{fontWeight:"100"},
        rotate: -45,

        formatter: function (val) {
          return val
        }
      },
    },
    yaxis: [
      {
        seriesName: 'Contribution Percentage',
        opposite: false,
        axisBorder: {
          show: true // Ensures Y-axis line is visible
        },
        
        labels: {
          style: {
           fontWeight:"100",

          },
          formatter: function (val) {
            return val + '%'
          }
        },
        title: {
          text: "Percentage",
          showAlways: true,
          floating: true,
          style: {
            fontWeight:"100",
 
           },

        },
      },
      ,
      
 


   
    ],
    annotations: {
      yaxis: [{
        y: 0, // Place the annotation at zero
        borderColor: '#939598',
        label: {
          text: '',
          style: {
            color: '#939598',
            background: '#000'
          }
        }
      }]
    } 

  });
setplotdata6([{ name: "Contribution Percentage", data:allplotsdataset.contribution?.contribution_pc.map((it) => { return Number(it.toFixed(0))-1 }) },

])
setoptions7(
  {
    chart: {
      id: `Download-Contribution by Base`,
      type: "bar",

      toolbar: {
        show: true,
      },
    },
    grid:{
      show:false
    },
    title: {
      text: 'Contribution-by Base(Optimized)',
      align: 'center',
      style:{fontWeight:"100"}
    },
    plotOptions:{
      bar:{dataLabels: {
        position: 'top',
    },
    },},
    colors: ['#6ab53c'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },

    xaxis: {
      categories:allplotsdataset.contribution?.marketing_variable,
      title: {
        text: 'Base',
        style:{fontWeight:"100"}
      },

      labels: {
        style:{fontWeight:"100"},
        rotate: -45,

        formatter: function (val) {
          return val
        }
      },
    },
    yaxis: [
      {
        seriesName: 'Contribution Percentage',
        opposite: false,
        axisBorder: {
          show: true // Ensures Y-axis line is visible
        },
        
        labels: {
          style: {
           fontWeight:"100",

          },
          formatter: function (val) {
            return val + '%'
          }
        },
        title: {
          text: "Percentage",
          showAlways: true,
          floating: true,
          style: {
            fontWeight:"100",
 
           },

        },
      },
      ,
      
 


   
    ],
    annotations: {
      yaxis: [{
        y: 0, // Place the annotation at zero
        borderColor: '#939598',
        label: {
          text: '',
          style: {
            color: '#939598',
            background: '#000'
          }
        }
      }]
    } 

  });
setplotdata7([{ name: "Contribution Percentage", data:allplotsdataset.contribution?.contribution_pc.map((it) => { return Number(it.toFixed(0))+2 }) },

])

setoptions8(
  {
    chart: {
      id: `Download-ROI by Variable`,
      type: "bar",

      toolbar: {
        show: true,
      },
    },
    grid:{
      show:false
    },
    title: {
      text: 'ROI-by Variable(Planned)',
      align: 'center',
      style:{
      fontWeight:"100"
      }
    },
    plotOptions:{
      bar:{dataLabels: {
        position: 'top',
    },
    },},
    colors: ['#0068A6'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },

    xaxis: {
      categories: allplotsdataset.contribution?.marketing_variable,
      title: {
        text: 'Variables',
        style: {

          fontWeight:"100"
                  },
      },
      labels: {
        style: {

fontWeight:"100"
        },
        rotate: -45,
        formatter: function (val) {
          return val
        }
      },
    },
    yaxis: [
      {
        seriesName: 'ROI',
        opposite: false,
        axisBorder: {
          show: true // Ensures Y-axis line is visible
        },

        labels: {
          style: {
           fontWeight:"100"

          },
          formatter: function (val) {
            return val + '%'
          }
        },
        title: {
          text: "ROI",
          showAlways: true,
          floating: true,
          style: {
        fontWeight:"100"
          },

        },
      },
      ,
    


 
    ],
    annotations: {
      yaxis: [{
        y: 0, // Place the annotation at zero
        borderColor: '#939598',
        label: {
          text: '',
          style: {
            color: '#939598',
            background: '#000'
          }
        }
      }]
    } 

  });
setplotdata8([{ name: "Contribution Percentage", data:allplotsdataset.roi?.roi.map((it) => { return it.toFixed(0) }) },

])
setoptions9(
  {
    chart: {
      id: `Download-ROI by Variable`,
      type: "bar",

      toolbar: {
        show: true,
      },
    },
    grid:{
      show:false
    },
    title: {
      text: 'ROI-by Variable (Optimized)',
      align: 'center',
      style:{
      fontWeight:"100"
      }
    },
    plotOptions:{
      bar:{dataLabels: {
        position: 'top',
    },
    },},
    colors: ['#0068A6'],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '9px',
        fontWeight: "100"
      },
      formatter: function (val) {
        return val + '%'
      }
    },

    xaxis: {
      categories: allplotsdataset.contribution?.marketing_variable,
      title: {
        text: 'Variables',
        style: {

          fontWeight:"100"
                  },
      },
      labels: {
        style: {

fontWeight:"100"
        },
        rotate: -45,
        formatter: function (val) {
          return val
        }
      },
    },
    yaxis: [
      {
        seriesName: 'ROI',
        opposite: false,
        axisBorder: {
          show: true // Ensures Y-axis line is visible
        },

        labels: {
          style: {
           fontWeight:"100"

          },
          formatter: function (val) {
            return val + '%'
          }
        },
        title: {
          text: "ROI",
          showAlways: true,
          floating: true,
          style: {
        fontWeight:"100"
          },

        },
      },
      ,
    


 
    ],
    annotations: {
      yaxis: [{
        y: 0, // Place the annotation at zero
        borderColor: '#939598',
        label: {
          text: '',
          style: {
            color: '#939598',
            background: '#000'
          }
        }
      }]
    } 

  });
setplotdata9([{ name: "Contribution Percentage", data:allplotsdataset.roi?.roi.map((it) => { return it.toFixed(0)-4 }) },

])
}

      // if (selectedbrand && selectedscenarioname) {
      //   try {
      //     setloader(true);
      //     // if(displaynames){
      //     //   setselectedbrand(displaynames.brand)
      //     //   setselectedscenarioname(displaynames.scenarioname)
      //     //   setselectedzone(displaynames.zone)
      //     //   setselectedyear(displaynames.year)
      //     //   setselectedscenarioid(displaynames.id)
      //     // }
      //     const userid = getUserID();
      //     // const timestamp=Math.floor(new Date().getTime()/1000)
      //     const requestData = {
      //       scenario_name: selectedscenarioname,
      //       scenario_timestamp:selectedscenarionametimestamp,
      //       user_id: "admin",
      //       brand: selectedbrand,
      //       f_year: "2021-22",
      //       model_id: 1,
      //       dataset1: originalset,
      //       dataset2: originalset2,
      //       total_budget:  String(totalBudget)
      //     };

      //     const config = {
      //       method: "post",
      //       url: `${REACT_APP_UPLOAD_DATA2}/optimize`,
      //       headers: {
      //         Accept: "text/plain",
      //         "Content-Type": "application/json",
      //       },
      //       data: requestData,
      //     };
      //     const getResponse = await axios(config);
      //     console.log(getResponse);
      //     // setplot1list(Array.from(getResponse.data));
      //     if (getResponse.status === 200) {

      //       if (getResponse.data.optimized_data) {
      //         setloader(false)
      //         setresultscreen2(true);
      //         let arr = [];
      //         for (let i = 0; i < getResponse.data.optimized_data.Variables.length; i++) {
      //           arr.push({
      //             Variables: getResponse.data.optimized_data.Variables[i],
      //             Before_optimization: Number(getResponse.data.optimized_data.Before_optimization[i].toFixed(0)),
      //             Optimized_plan: Number(getResponse.data.optimized_data.Optimized_plan[i].toFixed(0)),
      //             diff: Number(getResponse.data.optimized_data.diff[i].toFixed(0))
      //           });
      //         }


      //         setplotdata1(arr);
      //         setTimeout(() => {
      //           document.getElementById("OptimizationInputs").click()
      //         }, 1000)
             

      //         scrollToTop();

             
      //       } else {
      //         dispatch(
      //           getNotification({
      //             message: "There is no data for selected options",
      //             type: "default",
      //           })
      //         );
      //       }
      //       //(getResponse.data)
      //     }
      //   } catch (err) {
      //     console.log("Server Error", err);
      //     if (err.response && err.response.status === 500) {
      //       if (err.response.data.detail === "total budget is not optimal, please try again") {
      //         dispatch(
      //           getNotification({
      //             message: "Total budget is not optimal, please add optimal total budget value",
      //             type: "default",
      //           })
      //         );
      //       }
      //       else {
      //         dispatch(
      //           getNotification({
      //             message: "Server is Down! Please try again after sometime",
      //             type: "default",
      //           })
      //         );
      //       }
      //     }
      //     else if (err.response && err.response.status === 400) {
      //       dispatch(
      //         getNotification({
      //           message: "Input is not in prescribed format",
      //           type: "default",
      //         })
      //       );
      //     } else if (err.response && err.response.status === 422) {
      //       dispatch(
      //         getNotification({
      //           message: "Input is not in prescribed format",
      //           type: "default",
      //         })
      //       );
      //     } else if (err.response && err.response.status === 404) {
      //       dispatch(
      //         getNotification({
      //           message: "Page not Found",
      //           type: "default",
      //         })
      //       );
      //     } else if (err.response && err.response.status === 401) {
      //       dispatch(
      //         getNotification({
      //           message: "Session expired! Please log in again",
      //           type: "default",
      //         })
      //       ); setTimeout(UserService.doLogin(), 1000)
      //     } else {
      //       dispatch(
      //         getNotification({
      //           message: "Server is Down! Please try again after sometime",
      //           type: "default",
      //         })
      //       );
      //     }
      //   }
      // } 
    
  ,3000)
}
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/optimizer`,
        });
      }, 1000);
    }
   

  };
  function formatDate() {
    // Create a Date object using the specified date string
    const date = new Date();

    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    // Return the date in "YYYY-MM-DD" format
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  const savescenario = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const updatedDataset = [...sampledataset];
        console.log(updatedDataset[0].frozen)
        updatedDataset.map((item) => {
          if (item.frozen === 0) {
            item.subtotal = item.month_data.reduce((acc, value) => acc + value.value, 0);
          }

        })
        setoriginalset(updatedDataset);
        let arr = [];
        setedit(arr);
        //console.log(formatDate())
        
        const requestData = {
          scenario_name: newscenarionamegiven,
          scenario_timestamp: formatDate(),
          user_id: "admin",
          brand: displaynames.brand,
          f_year: "2021-22",
          model_id: 1,
          dataset1: originalset,

        };

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA2}/savescenario`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: requestData
        };
        const getResponse = await axios(config);
        if (getResponse.status === 200) {
          predictchart();
          fetchscenariooptions();
          setdisplaynames({ ...displaynames, scenarioname: newscenarionamegiven });
          dispatch(
            getNotification({
              message: "Scenario has been saved successfully",
              type: "success",
            })
          );
        }
      }
      catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          ); setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }

      }

    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/optimizer`,
        });
      }, 1000);
    }





  };
  const updatedataset = (variableIndex) => {

    const updatedDataset = [...sampledataset];

    if (updatedDataset[variableIndex].frozen === 0) {
      updatedDataset[variableIndex].subtotal = updatedDataset[
        variableIndex
      ].month_data.reduce((acc, value) => acc + value.value, 0);
    }

    setoriginalset(updatedDataset);

    let arr = [];
    setedit(arr);
    dispatch(getNotification({
      message: `Value updated for ${updatedDataset[variableIndex].attribute}`,
      type: "Success"
    }))

  }
  const updatedatasetdecimal = (variableIndex) => {
    const updatedDataset = [...sampledataset];

    updatedDataset[variableIndex].subtotal = updatedDataset[
      variableIndex
    ].month_data.reduce((acc, value) => acc + parseFloat(value.value), 0);

    setoriginalset(updatedDataset);

    let arr = [];
    setedit(arr);
    dispatch(getNotification({
      message: `Value updated for ${updatedDataset[variableIndex].attribute}`,
      type: "Success"
    }))

  }
  // const table2edit=(variableIndex)=>{
  //   const updatedDataset = [...originalset2];
  //   // updatedDataset[variableIndex].tl = updatedDataset[
  //   //   variableIndex
  //   // ].ub +updatedDataset[
  //   //   variableIndex
  //   // ].lb
  //   setoriginalset2(updatedDataset);
  // }
  const table2edit = (e, variableIndex, type) => {
    const updatedDataset = [...originalset2];
    const enteredvalue = parseInt(e.target.value.replace(/,/g, '')) || 0;
    if (type === "lb") {
      if (e.target.value > updatedDataset[variableIndex].ub) {
        dispatch(
          getNotification({
            message: "Entered Lower Bound is greater than upper bound! ",
            type: "default",
          }))
      }
      else { updatedDataset[variableIndex].lb = parseInt(enteredvalue) || 0; }
    }

    else if (type === "ub") {
      if (e.target.value < updatedDataset[variableIndex].lb) {
        dispatch(
          getNotification({
            message: "Entered Upper Bound is less than lower bound!",
            type: "default",
          }))
      }
      else { updatedDataset[variableIndex].ub = parseInt(enteredvalue) || 0; }

    }

    else { updatedDataset[variableIndex].tl = parseInt(enteredvalue) || 0; }
    //updatedDataset[variableIndex].tl=updatedDataset[variableIndex].lb+updatedDataset[variableIndex].ub || 0;


    setoriginalset2(updatedDataset)
  }
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 className="modal-title" id="exampleModalLabel">
                Save Scenario
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label>Please enter Scenario Name: </label>
              <input
                type="text"
                id="scenarionamebox"
                className="form-control"
                value={newscenarionamegiven}
                onChange={(e) => setnewscenarionamegiven(e.target.value)}
              />

              <br />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (newscenarionamegiven === "" || newscenarionamegiven === "Base Scenario") {
                    document.getElementById("scenarionamebox").focus();
                  } else {
                    savescenario();

                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="">

<div className="bgpages px-2 py-3 " >

  <div style={{ backgroundColor: "" }} className="row my-2 mx-2">
    {/* <div className="col-sm-9 align-self-center">
      <h5 className='orangetheme' style={{ fontWeight: "500" }} >optimizer</h5>
      <p className="text-light" >
        Dissects expenditure trends among multiple brands in a specific source of expenditure over a defined time frame.
      </p>
    </div> */}
    {/* <div className="col-sm-3">
      {resultscreen && displaynames
     
        &&
        <div className="rounded-4  m-1 p-2 card" style={{ backgroundColor: "#FAFFE7", border: "2px solid red", width: "300px", fontSize: "14.5px" }}>
          <span className=" orangetheme" ><b>User Selections</b>
            <hr className="m-1" style={{ border: "1px solid #f15e23" }} />
          </span>
          <table className="mx-3">
            <thead>
              <tr>
                <th style={{ color: "darkblue" }}>Parameter</th>
                <th className="text-right " style={{ color: "darkblue" }}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="" className="" style={{ color: "darkblue" }} >Brand</td>
                <td className="text-right">{displaynames.brand}</td>

              </tr>
              <tr>
                <td scope="" className="" style={{ color: "darkblue" }}>Scenario</td>
                <td className="text-right">{displaynames.scenarioname}</td>
              </tr>

              <tr>
                <td scope="" className="" style={{ color: "darkblue" }}>Zone</td>
                <td className="text-right">{displaynames.zone}</td>
              </tr>
              <tr>
                <td scope="" className="" style={{ color: "darkblue" }}>Year</td>
                <td className="text-right">{displaynames.year}</td>
              </tr>
            </tbody>
          </table>

        </div>}
    </div> */}
  </div>
  <div className="rounded-5 card mx-1 mb-3" style={{ backgroundColor: "white", border: "1px solid #E4CCFF" }}>

    <div style={{ fontWeight: "500", backgroundColor: "#EAF7FF" }} className="headingcolor p-3 rounded-5" >
      {resultscreen2 ? <div>OPTIMIZER
        <span className="float-end">
          {/* <button className="btn btn-outline-secondary btn-sm m-1 position-relative" onClick={()=>{setresultscreen(false)}}>Compare</button> */}
          {/* <button className="btn btn-outline-secondary btn-sm m-1"
 data-toggle="modal"
 data-target="#exampleModal" onClick={()=>savescenario()}>Save Scenario</button> */}



          {resultscreen2 && (
            <>
              <button
                className={"btn btn-outline-danger  btn-sm m-1"}
                onClick={() => {
                  setresultscreen(false)
                  setresultscreen2(false)
                  setscenarionewoldscreen("old")
                  setmarket("")
                  //setresultscreen(false)
                  //setoriginalset([])
                  //setsampledataset([])
                  setdisplaynames({})
                  setselectedscenarioname("Base Scenario")

setviewscenariodatatable(false)
                }}
              >
                Reset
              </button>
              {/* {displaynames.scenarioname !== "Base Scenario" && <button className="btn btn-sm btn-danger m-1 noborder"
                onClick={() => {
                  // savescenario();
                  updatescenario(true);
                }}>
                Update Scenario
              </button>} */}
              { <button className="btn btn-sm btn-danger noborder"
                onClick={() => {
                  // savescenario();
                  predictchart()
                }}>
              Optimize
              </button>}

              {/* {displaynames.scenarioname === "Base Scenario" &&
                <button className="btn btn-sm btn-danger noborder"
                  data-toggle={displaynames.scenarioname === "Base Scenario" ? "modal" : ""}
                  data-target="#exampleModal"
                  onClick={() => {
                    //savescenario();
                  }}> Save Scenario
                </button>} */}
                </>
          )}

        </span>
      </div> : "OPTIMIZER"}

    </div>
    <div className="row px-3" >
      {loader ? (
        <div
          className="row d-flex  justify-content-center align-items-center "
          style={{ height: "60vh" }}
        >
          <Loader
            type="box-rectangular"
            bgColor={"#007bff"}
            title={"Fetching data..."}
            color={"#000000"}
            size={75}
          />
        </div>
      ) : resultscreen2 ?
        <>
          <div className="d-flex justify-content-around py-2 px-3 shadow" style={{ backgroundColor: "#fafafa" }} >


            <div className="my-2">
              <label>Brand:<span className="text-danger">*</span></label>

              <select

                value={selectedbrand}
                className="form-select "
                onChange={(e) => setselectedbrand(e.target.value)}
              >

                {brandoptions?.map((option) => {
                  return <option>{option.brand}</option>;
                })}

              </select>
            </div>
            <div className="my-2">
              <label>Market:<span className="text-danger">*</span></label>
              <select className="form-select"
                        placeholder="Select market"
                        options={marketoptions}
                        onChange={(e) => {
                          if(e.target.value!=="Select")
                          setmarket(e.target.value);
                        }}
                        value={market}
                      >
                        <option>Select</option>
                        {marketoptions?.map((item)=>{
                          return <option>{item}</option>
                        })}
                      </select>





            </div>
            <div>
              <label>Scenario:<span className="text-danger">*</span></label>
            
              

                <select
                  className="form-select"
                  value={selectedscenarioname}
                  onChange={(e) => {
                    setviewscenariodatatable(false)
                    setselectedscenarioname(e.target.value);
                    let arr = scenariooptions.filter((it) => { return e.target.value === it.scenario_name })
                    setselectedscenarioid(arr[0].id)
                    setselectedscenarionametimestamp(arr[0].updated_dt)
                  }}
                >
                  {scenariooptions?.map((option) => {
                    return (
                      <option

                      >
                        {option.scenario_name}
                      </option>
                    );
                  })}
                </select>
            </div><div>
           
           
            </div>
            {/* <div>

              {" "}
              <label htmlFor="textarea">Year: </label>

              <select className="form-select" value={selectedyear} onChange={(e) => setselectedyear(e.target.value)}>
                <option>2021-22</option>
                <option>2022-23</option>
                <option>2023-24</option>
              </select>


            </div> */}
            {/* <div className="form-check ">
              <input
                className="form-check-input "
                type="radio"
                checked
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={() => { }}
              />
              <label
                className="form-check-label selected"
                for="flexRadioDefault2"
              >
                Annual
              </label>{" "}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                disabled
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={() => { }}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Quarterly
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                disabled
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={() => { }}
              />
              <label className="form-check-label" for="flexRadioDefault2">
                Semi-Annual
              </label>{" "}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                disabled
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={() => { }}
              />
              <label className="form-check-label" for="flexRadioDefault2">
                Define
              </label>{" "}
            </div> */}
            <div>
              {/* <button
                className="btn btn-primary my-2"
                onClick={() => {
                  fetchdatasettable();
                }}
              >
                Submit
              </button> */}
            </div>
          </div>




          <div className="col-sm-12 p-3 mt-3">
            <div className="" id="accordionExample">
              {/* <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne" >
                  <button id="optimizerInputs" className="accordion-button" style={{ backgroundColor: "#cfe2ff" }} type="button" data-toggle={resultscreen2 ? "collapse" : ""} data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    optimizer Inputs
                  </button>
                </h2>
                <div id="collapseOne" className="show collapse" aria-labelledby="headingOne" >
                  <div className="accordion-body">
                    <table className="table table-sm my-3 table-striped shadow table-bordered table-responsive-md" style={{ fontSize: "1.7vh" }}>


                      <thead>
                        <th>Attribute</th>

                        {monthlist.slice(0, sampledataset[0].month_data?.length).map((it) => {
                          return <th>{it}</th>
                        })}

                        <th>Subtotal</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {sampledataset?.map((item, variableIndex) => {
                          return (
                            <tr key={`outside${variableIndex}`}>
                              <td>{item.attribute}</td>
                              {item.month_data?.map((it, valueIndex) => {
                                return (
                                  <td>
                                    {edit[variableIndex] ? (
                                      <div>
                                        <input
                                          className={it.frozen ? "noborder" : ""}
                                          style={{ width: "60px" }}
                                          value={it.value}
                                          onChange={(e) => {

                                            item.allow_decimal === 0 ?
                                              changeelements(
                                                variableIndex,
                                                valueIndex,
                                                e
                                              ) : changeelementsdecimal(
                                                variableIndex,
                                                valueIndex,
                                                e
                                              );
                                          }}
                                        />
                                        <button
                                          className="btn btn-sm  my-2"

                                          onClick={() => {
                                            togglelock(
                                              variableIndex,
                                              valueIndex
                                            );
                                          }}
                                        >
                                          {it.frozen === 0
                                            ? <i class="fa fa-unlock text-warning"></i>
                                            : <i class="fa fa-lock text-secondary" ></i>}
                                        </button>

                                      </div>
                                    ) : (

                                      Number.isInteger(it.value) ? Number(it.value).toLocaleString("en-IN") :
                                        parseFloat(it.value).toFixed(2)
                                    )}
                                  </td>
                                );
                              })}
                              <td>
                                {edit[variableIndex] ? (
                                  <div>
                                    <input
                                      className={item.frozen ? "noborder ml-1" : ""}
                                      style={{ width: "60px" }}
                                      defaultValue={item.subtotal}
                                      value={item.subtotal}
                                      onChange={(e) => {
                                        item.allow_decimal === 0 ?
                                          changesubtotal(e, variableIndex) : changesubtotaldecimal(e, variableIndex);
                                      }}
                                    />
                                    <button
                                      className="btn btn-sm my-2"
                                      onClick={() => {
                                        togglelocksubtotal(variableIndex);
                                      }}
                                    >
                                      {item.frozen === 0
                                        ? <i class="fa fa-unlock text-warning"></i>
                                        : <i class="fa fa-lock text-secondary" ></i>}
                                    </button>
                                  </div>
                                ) : (
                                  Number.isInteger(item.subtotal) ? Number(item.subtotal).toLocaleString("en-IN") :
                                    parseFloat(item.subtotal).toFixed(2)

                                )}
                              </td>
                              <td>
                                {edit[variableIndex] ? (
                                  <div className="d-flex">

                                    <button
                                      className="btn btn-sm m-1 checktickbtn"

                                      onClick={() => { item.allow_decimal === 0 ? updatedataset(variableIndex) : updatedatasetdecimal(variableIndex) }}
                                    >
                                      <i class="fa fa-check" style={{ color: "green", fontSize: "15px" }}></i>
                                    </button>
                                    <button
                                      className="btn btn-sm   m-1"
                                      onClick={() => {
                                        clearAll(variableIndex);
                                      }}
                                    >
                                      <i class='fas fa-trash-alt' style={{ color: "red", fontSize: "15px" }} ></i>
                                    </button>
                                    <button
                                      className="btn btn-sm  m-1"
                                      onClick={() => {
                                        let arr = [];
                                        setedit(arr);
                                        handlecancel(variableIndex);
                                      }}
                                    >
                                      <i class="fa fa-arrow-circle-left text-primary" style={{ fontSize: "15px" }}></i>
                                    </button>

                                  </div>
                                ) : (
                                  <button
                                    className="btn"

                                    onClick={() => {
                                      //handlecancel();
                                      let arr = [];
                                      arr[variableIndex] = true;
                                      setedit(arr);
                                    }}
                                  >
                                    <i class='fas fa-edit text-primary' ></i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}
              {resultscreen2 &&
                <div className=" my-2">
                  {/* <h2 className="accordion-header " id="headingTwo">
                    <button className="accordion-button collapsed orangetheme2"
                      type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      optimizer Output/Result
                    </button>
                  </h2> */}
                  <div id="collapseTwo" className="accordion-collapse show" aria-labelledby="headingTwo" >
                    <div className="accordion-body">
                      {
                        loader ?
                          <div
                            className="d-flex  justify-content-center align-items-center "
                            style={{ height: "10vh" }}
                          >
                            <Loader
                              type="box-rectangular"
                              bgColor={"#007bff"}
                              title={"Fetching data..."}
                              color={"#000000"}
                              size={75}
                            /></div> :

                          <div>
                            <div className="row ml-4">

                              <h5 className="my-4">Optimized Data</h5>
                 
                              <table className="text-center table table-sm table-bordered m-auto col-sm-8 text-center table-striped-columns" style={{ fontSize: "2vh" }}>
                                        <thead className="orangetheme3">
                                          <th>Marketing Variables</th>
                                          <th>Planned Spends for Q1</th>
                                          <th>Optimized Spends for Q1</th>
                                          <th>Change(%)</th>
                                        </thead>
                                        
                                        <tbody>
                                          {plotdata1?.map((item) => {
                                            return <tr>
                                              <td className="text-left">{item.Variables}</td>
                                              <td>{item.Before_optimization.toLocaleString("en-IN")}</td>
                                              <td>{item.Optimized_plan.toLocaleString("en-IN")}</td>
                                              <td>{item.diff.toLocaleString("en-IN")}%</td>
                                            </tr>
                                          })}
                                        </tbody>
                                      </table>
                              
                             
                  <div className="row mb-4 mt-5">
                  <div className="col-sm card mx-3">
               <Chart
                        className="my-auto"
                        align="center"
                        type="donut"
                        height={250}
                                            options={options2}
                                            series={plotdata2.map((item) => item.data)}
                                          
                                          />
               </div>
                <div className="col-sm card mx-3">
                                          <Chart
                                             
                                             className="my-auto"
                                             align="center"
                                             type="donut"
                                             height={250}
                                            options={options3}
                                            series={plotdata3.map((item) => item.data)}
                                          
                                          />
                                          </div>
                    </div>
                    <div className="row my-4">
                                          <div className="col-sm card mx-3">
                                          <Chart
                                            className=""
                                          align="center"
                                          options={options4}
                                          series={plotdata4}
                                          type="bar"
                                          height={350}
                                          width={'80%'}
                                        />
                                            </div>
                                              <div className="col-sm card mx-3">
                                        <Chart
                                          className=""
                                          align="center"
                                          options={options5}
                                          series={plotdata5}
                                          type="bar"
                                          height={350}
                                          width={'80%'}
                                        />
                                        </div>
                                            </div>        
                                        
                                          
                                            <div className="row  mx-auto  my-4"> 
                                            <div className="col-sm card mx-3">
                                          <Chart
                                            className=""
                                          align="center"
                                          options={options6}
                                          series={plotdata6}
                                          type="bar"
                                          height={350}
                                          width={'80%'}
                                        />
                                        </div>
                                        <div className="col-sm card mx-3">
                                        <Chart
                                          className=""
                                          align="center"
                                          options={options7}
                                          series={plotdata7}
                                          type="bar"
                                          height={350}
                                          width={'80%'}
                                        />
                                        </div>
                                            </div> 
                                            
                                            <div className="row    my-4"> 
                                            <div className="col-sm card mx-3">
                                          <Chart
                                            className=""
                                          align="center"
                                          options={options8}
                                          series={plotdata8}
                                          type="bar"
                                          height={350}
                                          width={'80%'}
                                        />
                                        </div>   <div className="col-sm card mx-3">
                                        <Chart
                                          className="col-sm"
                                          align="center"
                                          options={options9}
                                          series={plotdata9}
                                          type="bar"
                                          height={350}
                                          width={'80%'}
                                        />
                                        </div>
                                            </div> 
                            </div>
                         
                           
                            <div className="row ">

                              {/* {plotdata5.length > 0 && (
                                options3
                                  align="center"
                                  options={options5}
                                  series={plotdata5}
                                  type="bar"
                                  height={500}
                                  width={'80%'}
                                />
                              )} */}
                            </div>
                          </div>}
                    </div>
                  </div>


                </div>}
            </div>
          </div>

        </> :
        <div className="row">
          <div className="col-sm" id="main1">
            {brandoptions.length > 0 && scenariooptions.length > 0 ?

              <div className="">
                <div className=" px-3 pt-2 " >

                  <div>
                    <label>Brand:<span className="text-danger">*</span></label>

                    <select
                      value={selectedbrand}
                      className="form-select mb-2"
                      onChange={(e) => setselectedbrand(e.target.value)}
                    >
                      {brandoptions?.map((option) => {
                        return <option>{option.brand}</option>;
                      })}
                    </select>
                    <div className="my-2">
                      <label>Market:<span className="text-danger">*</span></label>
                      <select className="form-select"
                        placeholder="Select market"
                        options={marketoptions}
                        onChange={(e) => {
                          if(e.target.value!=="Select")
                          setmarket(e.target.value);
                        }}
                        value={market}
                      >
                        <option>Select</option>
                        {marketoptions?.map((item)=>{
                          return <option>{item}</option>
                        })}
                      </select>





                    </div>
                    <label>Scenario:<span className="text-danger">*</span></label>
                    {/* <select className="form-select" onChange={(e) => {setscenarionewoldscreen(e.target.value);setviewscenariodatatable(false);setviewscenariobtn(false)}}>
                      <option value="old">Select from already present Scenarios</option>
                      <option value="new">Create new Scenario</option>
                    </select> */}
                    
                    <select
                      className="form-select mb-2"
                      value={selectedscenarioname}
                      onChange={(e) => {
                        if(e.target.value==="select"){
                          if(viewscenariodatatable){
                            setselectedscenarioname("select")
                            setviewscenariodatatable(false)
                            document.getElementById("main2").classList.remove("col-sm-8");
                            document.getElementById("main1").classList.remove("col-sm-4");
                             document.getElementById("main1").classList.add("col-sm");
                            document.getElementById("main2").classList.add("col-sm");
                           }
                        }
                        else{
                          setscenarionewoldscreen("old")
                          setselectedscenarioname(e.target.value);
                          let arr = scenariooptions.filter((it) => { return e.target.value === it.scenario_name })
                          setselectedscenarioid(arr[0].id)
                          setselectedscenarionametimestamp(arr[0].updated_dt)
                          fetchdatasettable(arr[0]);
                          
                     
                        }
                      
                      }}
                    >
                      <option value="select">Select</option>
                      {scenariooptions?.map((option) => {
                        return (
                          <option
                          
                          >
                            {option.scenario_name}
                          </option>
                        );
                      })}
                    </select>
                  
                      { scenarionewoldscreen==="old" && <div className="mt-2">
                          <button className="btn btn-sm btn-primary" 
                          onClick={() =>{if(viewscenariodatatable){setviewscenariodatatable(false)
                            document.getElementById("main2").classList.remove("col-sm-8");
                            document.getElementById("main1").classList.remove("col-sm-4");
                             document.getElementById("main1").classList.add("col-sm");
                            document.getElementById("main2").classList.add("col-sm");
                           }else{fetchdatasettable();}}}>
                            {!viewscenariodatatable ? "View Scenario Data" : "Hide"}
                            </button></div> }
                        
                      <div className="d-flex flex-row-reverse my-2"> <button
                      className="btn btn-primary "
                      onClick={() => {
                      // fetchdatasettable()
                      predictchart()
                      }}
                    >
                      Optimize
                    </button></div>
                    {/* <label>Zone:</label>
                    <select className="form-select  mb-2" value={selectedzone} onChange={(e) => setselectedzone(e.target.value)}>
                      <option selected>National</option>
                      <option>East</option>
                      <option>West</option>
                      <option>North</option>
                      <option>South</option>
                      <option>Campaign Proxy</option>
                    </select> */}
                    {/* <div>
                      {" "}
                      <label htmlFor="textarea">Year: </label>
                      <div className="d-flex justify-items-between">
                        <div className="form-check mx-2  mb-2">
                          <input
                            className="form-check-input "
                            type="radio"
                            checked
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            onClick={() => { }}
                          />
                          <label
                            className="form-check-label selected"
                            for="flexRadioDefault2"
                          >
                            Annual
                          </label>{" "}
                        </div>
                        <div className="form-check mx-2 mb-2">
                          <input
                            className="form-check-input"
                            disabled
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            onClick={() => { }}
                          />
                          <label className="form-check-label" for="flexRadioDefault1">
                            Quarterly
                          </label>
                        </div>
                        <div className="form-check mx-2 mb-2">
                          <input
                            className="form-check-input"
                            disabled
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            onClick={() => { }}
                          />
                          <label className="form-check-label" for="flexRadioDefault2">
                            Semi-Annual
                          </label>{" "}
                        </div>
                        <div className="form-check mx-2 mb-2">
                          <input
                            className="form-check-input"
                            disabled
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            onClick={() => { }}
                          />
                          <label className="form-check-label" for="flexRadioDefault2">
                            Define
                          </label>{" "}
                        </div>  </div>
                      <div className="">

                        <select className="form-select  mb-2" value={selectedyear} onChange={(e) => setselectedyear(e.target.value)}>
                          <option>2021-22</option>
                          <option>2022-23</option>
                          <option>2023-24</option>
                        </select>
                      </div>

                    </div> */}

                  </div>
                </div>



              </div> : "Loading..."}
          </div>
          <div className="col-sm " id="main2">
          {!viewscenariodatatable ?
         
            <img src="../Assets/Images/image1.png" className="" align="right" style={{ width: "500px" }} />
          :
           <div className=" my-2">
          
                 <table className=" text-center table table-sm  my-3 m-auto table-striped table-striped shadow table-bordered p-2     " style={{ fontSize: "2vh" }}>
                                    <thead className="bluetheme2">
                                      <th>Attribute</th>
                                      <th>Lower Bound</th>
                                      <th>Upper Bound</th>


                                      <th>Total</th>

                                    </thead>
                                    <tbody>
                                      {originalset2?.map((item, variableIndex) => {
                                        return (
                                          <tr key={`outside${variableIndex}`}>
                                            <td>{item.variables}</td>
                                            <td>
                                              <div>
                                                <input value={item.lb.toLocaleString("en-IN")} onChange={(e) => table2edit(e, variableIndex, "lb")} />
                                              </div>

                                            </td>
                                            <td> <div>
                                              <input value={item.ub.toLocaleString("en-IN")} onChange={(e) => table2edit(e, variableIndex, "ub")} />
                                            </div></td>
                                            <td> <div>
                                              <input value={item.tl.toLocaleString("en-IN")} onChange={(e) => table2edit(e, variableIndex, "tl")} />
                                            </div></td>

                                          </tr>
                                        );
                                      })}
                                      <tr>
                                        <td>Total</td>
                                        <td>
                                          {originalset2?.reduce((prev, next) =>
                                            prev + next.lb, 0).toLocaleString("en-IN")}

                                        </td>
                                        <td>
                                          {originalset2?.reduce((prev, next) =>
                                            prev + next.ub, 0).toLocaleString("en-IN")}
                                        </td>
                                        <td>
                                          {originalset2?.reduce((prev, next) =>
                                            prev + next.tl, 0).toLocaleString("en-IN")}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  
            <div className=" mx-auto my-3">
                                  <span className="" >  Objective:</span>
                                  <select className="  form-select rounded my-1">
                                    <option>Optimizing Budget Allocation & maximizing Sales</option>
                                    <option>Optimizing Budget Allocation & maximizing Profit</option>
                                    <option>Blue Sky Optmization-No Budget Constraints</option>

                                  </select>
                                  </div>
                                  <div className="mt-2">
                                  Enter Total Budget: <input className="mx-3" value={totalBudget} onChange={(e) => settotalBudget(e.target.value)} /></div>
                           
                          </div>}
                      </div>

                      
          <div id="" className="p-4  ">
            <div>
           
            </div>
          </div>





        </div>}
    </div>
  </div>




</div>




</div>
     





      <div className='' >
        <FooterPages />
      </div>
    </>
  );
}

export default Optimizer;
