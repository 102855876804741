import React, { useEffect, useState } from "react";
import 'spiketip-tooltip/spiketip.min.css'
import quationlogoBg0 from "./Images/quationlogoBg0.png";
import { Link } from "react-router-dom";
import UserService from "../services/UserService.js";
import mmxlogom from "./Images/mmxlogom.png";
import logosocialmedia from "./Images/logossocialmodeia.png";
import Select, { components } from "react-select";
import axios from "axios";
import { useDispatch } from "react-redux";
import getNotification from "../Redux/Action/action.js";
//import styled from 'styled-components';
import Plot from "react-plotly.js";
import Loader from "react-js-loader";
import demovideocompetitive from "./Images/demovideocompetitive.mp4";
import FooterPages from './FooterPages.jsx'
import Chart from "react-apexcharts";
// import FusionCharts from "fusioncharts";
// import charts from "fusioncharts/fusioncharts.charts";
// import ReactFusioncharts from "react-fusioncharts";
// import ZoomLine from 'fusioncharts/fusioncharts.zoomline'; 

// charts(FusionCharts);
// ZoomLine(FusionCharts);
const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_UPLOAD_DATA3 } = process.env;

function BrandAnalysis() {

  const [options,setoptions]=useState({})
  const [salesdata,setsalesdata]=useState([])
  const [displaynames, setdisplaynames] = useState({});
  const [currentScreen, setcurrentScreen] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [brandoptions, setbrandoptions] = useState([ ]);
  const [marketoptions, setmarketoptions] = useState([  ]);
  const [market, setmarket] = useState([]);
  const [loader, setloader] = useState(false);
  const [resultscreen, setresultscreen] = useState(false);
  const [comparescreen, setcomparescreen] = useState(false);
  const [brandlist1, setbrandlist1] = useState("");
 
  const [variableslist, setvariableslist] = useState([]);
  const [spendtoplot1, setspendtoplot1] = useState([]);
  const [spendtoplot1main, setspendtoplot1main] = useState([]);
  const [plotdata1, setplotdata1] = useState({});
  const [statisticsdata1, setstatisticsdata1] = useState([]);
  const [corelationdata, setcorelationdata] = useState([{"Correlation":"Harrier TV Advertisement","Harrier TV Advertisement":"NaN","Safari TV Advertisement":"NaN"},{"Correlation":"Safari TV Advertisement","Harrier TV Advertisement":"NaN","Safari TV Advertisement":"1"}]);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const [reportName, setreportName] = useState("");
  const [subvariablesoptions, setsubvariablesoptions] = useState([])

  const [variablesoptions, setvariablesoptions] = useState([])
  const [allvariabledata,setallvariabledata]=useState([])
  useEffect(() => {
    // handledatemenu();
    handlevariablesfetch();
    // handlevariablesmenu();
    
  }, []);
  const reverseDate=(dateString)=>{
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  }
  const handleDropdownChange = (selectedOption, dropdownType) => {



        setsubvariablesoptions(variableslist.filter((it) => it.spend_to_display === selectedOption.value)[0]?.child_Items.map((item) => {
          return {
            value: item.spend_to_display,
            label: item.spend_to_display,
            category:item.category
            //fullset:item
          }
        }))
      };
    
  const formatMonthYear=(inputString)=> {
    // Split the input string into month and year
    const [month, year] = inputString.split('-');
    
    // Get the first three characters of the month and the last two characters of the year
    const formattedMonth = month.slice(0, 3);
    const formattedYear = year.slice(2);

    // Combine the formatted month and year with a hyphen
    const formattedString = formattedMonth + '-' + formattedYear;

    return formattedString;
}
  const handleNext = () => {
    if (currentScreen === 0) {
      setcurrentScreen(currentScreen + 1);
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
    //document.getElementById("userparabtn").classList.add("d-none");
    // document.getElementById("sidenav").style.width = "350px";
    //document.getElementById("mainscreen").classList.add("blurry-component");
    var element1 = document.getElementById('sidenav');
  var element2 = document.getElementById('mainscreen');

    // var maxHeight = Math.max(element1.offsetHeight, element2.offsetHeight);
   
    // console.log(element1.offsetHeight, element2.offsetHeight)
 
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    document.getElementById("userparabtn").classList.remove("d-none");
    //document.getElementById("sidenav").style.width = "245px";
    //document.getElementById("sidenav").classList.remove("col-sm-6");
    document.getElementById("mainscreen").classList.remove("blurry-component");
 
  };

  const processplotdata = (fetchplotresponse) => {

    // setsalesdata([{name:"Actual Sales",data:modelperformanceplotdata.actual_sales.map((it)=>it.sales.toFixed(0))},
    //   {name:"Predicted Sales",data:modelperformanceplotdata.predictions.map((it)=>it.weekly_predicted_sales.toFixed(0))}
    // ])
  
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];
    let plot_data = [];
    const colors = [
      "red",
      "blue",
      "green",
      "purple",
    
      "cyan",
      "magenta",
      "yellow",
      "lime",
      "pink",
      "teal",
      "indigo",
      "brown",
      "grey",
      "violet",
      "olive",
      "navy",
      "maroon",
      "gold",
      "darkgreen",
      "darkred",
      "darkblue",
      "darkorange",
      "darkcyan",
      "darkmagenta",
      "darkyellow",
      "darklime",
      "darkpink",
      "darkteal",
      "darkviolet",
    ];

    // fetchplotresponse?.map((item,index) => {
      

    //   arr1.push(item.week_format);
    //   arr2.push(Number(item.rounded_value))
    //   arr3.push(item.variable)
    // });
    // setoptions (
    //   {
    //   chart: {    
    //     id: `Download`,
    //     type: "line",
    
     
        
    //     toolbar: {
    //       show: true,
    //     },
    //   },
    //   grid: {
    //     show: false, // Keep grid enabled
     
    //   },
    //   title: {
    //     text: `${displaynames.brand} ${displaynames.market}`,
    //     align: 'center',
    //   },
    //   plotOptions: {
      
    //   },
    //   colors: ['#4CAF50', '#FFC107'],
    //   dataLabels: {
    //     enabled: true,
    //     style: {
    //       fontSize: '8px !important',
     
    //     },
    //     formatter: function (val) {
    //       return val
    //     }
    //   },
    
    //   xaxis: {
    //     categories:arr2.slice(0,13) ,
    //     title: {
    //       text: 'Months',
    //       style:{
    //         color:"#FFC107",
    //           },
    //     },
    //     labels: {
    //       style: {
    //         fontSize: "10px !important",
            
    //       },
    //       formatter: function (val) {
    //         return val 
    //       }
    //     },
    //   },
    //   yaxis: [
    //     {
    //       seriesName: 'Sales',
    //       opposite: false,
    //       axisBorder: {
    //         show: true // Ensures Y-axis line is visible
    //       },
    //       title: {
    //         text: "Sales",
    //         showAlways: true,
    //         floating: true,
    //         style:{
    //           color:"#4CAF50",
    //             },
            
    //       },
    //       labels: {
    //         style: {
    //           fontSize:'10px !important' ,
              
    //         }}
    //               },
    //     ,
    //     // {
    //     //   seriesName: 'Predicted Sales',
    //     //   opposite: true,
    //     //   title: {
    //     //     text: "Predicted Sales",
    //     //     showAlways: true,
    //     //     floating: true, 
    //     //      style:{
    //     //     color:"#FFC107"
    //     //   },
          
    //     //   },
                        
          
    //     // },
    //   ],
  
    // });
    // let arr1unique=Array.from(new Set(arr1)).map((item)=>{return {label:item}})
    // for (let i = 0; i * 6 < arr1.length; i++) {
    //   arr2.push(
    //     {
    //     seriesname: fetchplotresponse[i*6].spend,
    //     data:fetchplotresponse.slice(i * 6, (i + 1) * 6)}
    //   )
    // }
    // plot_data = {
    //   chart: {
    //     caption: `${displaynames.brandlist1}-${displaynames.market}`,
    //     subcaption: "",
    //     yaxisname: "variables",
    //     xaxisname: "Month/Year",
    //     forceaxislimits: "1",
    //     pixelsperpoint: "0",
    //     pixelsperlabel: "30",
    //     compactdatamode: "1",
    //     dataseparator: "|",
    //     theme: "u"
    //   },
    //   categories: [
    //     {
    //       category:arr1unique
    //     }
    //   ],
    //   dataset:   arr3 
        
        
   
    // };
    fetchplotresponse?.map((item,index)=>{

      plot_data.push(
        {
          x: item?.values?.map((it)=>it.week_format),
          y: item?.values?.map((it)=>it.rounded_value),
          name: `${item.variable}`,
          showlegend: true,
          type: "scatter",
          mode: "lines",
          marker: { color: colors[index] },
        },
        {
          x: item?.values?.map((it)=>it.week_format),
          y: linear(item?.values?.map((it)=>it.rounded_value)),
          name: `trendline-${item.variable}`,
          showlegend: true,
          type: "dash",
          mode: "lines",
          visible: "legendonly",
          line: {
            dash: "dot",
            width: 1,
          },
          marker: { color: colors[index] },
        }
      ); 
    })
  
    
    setplotdata1(plot_data);
  };
  const linear = (data) => {
    const xaxis = [0, 1, 2, 3, 4, 5];
    const yaxis = [];
    data.forEach((it) => yaxis.push(it));
    const n = xaxis.length;
    //console.log(xaxis,yaxis)
    // Calculate mean of X and Y
    const meanX = xaxis.reduce((sum, point) => sum + point, 0) / n;
    const meanY = yaxis.reduce((sum, point) => sum + point, 0) / n;
    //console.log(meanX,meanY)
    let numerator = 0;
    let denominator = 0;

    for (let i = 0; i < n; i++) {
      const diffX = xaxis[i] - meanX;
      const diffY = yaxis[i] - meanY;
      numerator += diffX * diffY;
      denominator += diffX * diffX;
    }

    const m = numerator / denominator;
    const b = meanY - m * meanX;

    let lineararr = [];

    xaxis.forEach((it) => {
      lineararr.push(m * it + b);
    });

    return lineararr;
  };
  const scrollToSection = (sectionname) => {
    // Replace 'section2' with the id of the section you want to scroll to

    if (document.getElementById(sectionname)) {
      document
        .getElementById(sectionname)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      dispatch(
        getNotification({
          message: "Please proceed with first analysis ",
          type: "Default",
        })
      );
    }
  };
  const handledatemenu = async () => {
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
      sendData.append("jwttoken", UserService.getToken());

      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/dates/loaddates`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
      const getResponse = await axios(config);
      setStartDate(getResponse.data[1]._date);
      setEndDate(getResponse.data[0]._date);
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  };
  const handlevariablesfetch = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
       
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA3}/app/fetchvars`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
            // "req-header":UserService.getToken()
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        if (getResponse.data !== "Invalid User!") {
   
            setStartDate(reverseDate(getResponse.data.dates[0].min[0].start_date));
            setEndDate(reverseDate(getResponse.data.dates[0].max[0].end_date));
         
            setmarketoptions(
              getResponse.data.markets?.map((it) => {
                return { value: it.final_market, label: it.final_market };
              })
            );
          setbrandoptions(
            getResponse.data.brands?.map((it) => {
              return { value: it.brand, label: it.brand };
            })
          );
          setvariablesoptions(
            getResponse.data.variables?.map((it) => {
              return { value: it.variable, label: it.variable };
            })
          );
          setbrandoptions(
            getResponse.data.brands?.map((it) => {
              return { value: it.brand, label: it.brand };
            })
          );
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
        });
      }, 1000);
    }
  };
  const handlevariablesmenu = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("jwttoken", UserService.getToken());

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/variables/gettactics`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);

        if (getResponse.data !== "Invalid User!") {
          setvariableslist(getResponse.data);
          setvariablesoptions(getResponse.data.map((item) => {
            return {
              label: item.spend_to_display,
              value: item.spend_to_display
            }
          }))
          
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
        });
      }, 1000);
    }
  };

  //   if (UserService.isLoggedIn()) {
  //     try {
  //       const FormData = require("form-data");
  //       const sendData = new FormData();
  //       sendData.append("jwttoken", UserService.getToken());
  //       sendData.append("spend", spendtoplot1);
  //       const config = {
  //         method: "post",
  //         url: `${REACT_APP_UPLOAD_DATA}/variables/forcompare`,
  //         headers: {
  //           Accept: "text/plain",
  //           "Content-Type": "application/json",
  //         },
  //         data: sendData,
  //       };
  //       const getResponse = await axios(config);
  //       console.log(getResponse);
  //       if (getResponse.data !== "Invalid User!") {
  //         setvariableslist(getResponse.data);
  //       }
  //     } catch (err) {
  //       console.log("Server Error", err);
  //       if (err.response && err.response.status === 500) {
  //         dispatch(
  //           getNotification({
  //             message: "Server is Down! Please try again after sometime",
  //             type: "default",
  //           })
  //         );
  //       } else if (err.response && err.response.status === 400) {
  //         dispatch(
  //           getNotification({
  //             message: "Input is not in prescribed format",
  //             type: "default",
  //           })
  //         );
  //       } else if (err.response && err.response.status === 422) {
  //         dispatch(
  //           getNotification({
  //             message: "Input is not in prescribed format",
  //             type: "default",
  //           })
  //         );
  //       } else if (err.response && err.response.status === 404) {
  //         dispatch(
  //           getNotification({
  //             message: "Page not Found",
  //             type: "default",
  //           })
  //         );
  //       } else if (err.response && err.response.status === 401) {
  //         dispatch(
  //           getNotification({
  //             message: "Session expired! Please log in again",
  //             type: "default",
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           getNotification({
  //             message: "Server is Down! Please try again after sometime",
  //             type: "default",
  //           })
  //         );
  //       }
  //     }
  //   } else {
  //     setTimeout(() => {
  //       UserService.doLogin({
  //         redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
  //       });
  //     }, 1000);
  //   }
  // };
  const handlecheckboxesselection = (selection) => {
    const arr=[]
    console.log(selection)
    if(selection.length===6){
      dispatch(getNotification({message:"Only 6 variables allowed at one time",type:"default"}))
      //document.getElementById(`${arr[0]}`).checked=false;        
    // arr.shift();
    }
    else{
     selection.map((it)=>{
      arr.push(it.value)
     })
    }
        // if (event.target.checked === true) {
       
        // else{
        //      arr.push(selection);  
        // }
 
    
    // } else {
    //   arr.pop(selection);

    // }
    console.log(arr)
        setspendtoplot1(arr);
  };
  const fetchplotdata = async () => {
    if (UserService.isLoggedIn()) {
      if (spendtoplot1 && brandlist1 && startDate && endDate && market) {
        try {
          setloader(true);
  
          fetchstatisticsdata();
          fetchcorelationdata();
       
         console.log(spendtoplot1main)
            const requestData = {
            // jwttoken: UserService.getToken(),
            start_date: startDate,
            end_date: endDate,
            variable: spendtoplot1,
            brand: brandlist1,
          };

          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA3}/app/brandanalysis`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: requestData,
          };
          const getResponse = await axios(config);

          // setplot1list(Array.from(getResponse.data));
          if (getResponse.status === 200) {
                 
           if(getResponse.data[0]!=="Invalid User!"){
            processplotdata(getResponse.data);
            setresultscreen(true);
            setdisplaynames({
              ...displaynames,
                  startDate: startDate,
                  endDate: endDate,
                  brandlist1: brandlist1,
                  spendtoplot1: spendtoplot1,
                  market:market

                })
           }
                else if (getResponse.data[0] === "Invalid User!") {
                  UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
                  });
                }
          }
        } catch (err) {
   //handleMouseEnter();

          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      } else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
        });
      }, 1000);
    }
    setloader(false);
  };

  const fetchstatisticsdata = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const requestData = {
          // jwttoken: UserService.getToken(),
          start_date: startDate,
          end_date: endDate,
          variable: spendtoplot1,
          brand: brandlist1,
        };

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA3}/app/statistics`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: requestData,
        };
        const getResponse = await axios(config);

        // setplot1list(Array.from(getResponse.data));
        if (getResponse.status === 200) {
          if (getResponse.data === "No Records Found." || getResponse.data==="42883: function round(real, integer) does not exist\n\nPOSITION: 25") {
            dispatch(
              getNotification({
                message: "There are no statistics record",
                type: "default",
              })
            );
          } else {

 setstatisticsdata1(getResponse.data);}
          
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
        });
      }, 1000);
    }
  };
  const handlesavereport = async () => {
    if (UserService.isLoggedIn()) {
      if (reportName && spendtoplot1 && brandlist1 && startDate && endDate) {
        try {
          setloader(true);

          const requestData = {
            jwttoken: UserService.getToken(),
            reportName: reportName,
            startDate: startDate,
            endDate: endDate,
            spends: spendtoplot1,
            compare_spend: "",
            createdby: UserService.getFullName(),
            brand: brandlist1,
          };
          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/brandanalysis/savereport`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: requestData,
          };
          const getResponse = await axios(config);

          // setplot1list(Array.from(getResponse.data));
          if (getResponse.status === 200) {
            dispatch(
              getNotification({
                message: "Report has been saved successfully",
                type: "success",
              })
            );
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      } else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
        });
      }, 1000);
    }
    setloader(false);
  };
  const fetchcorelationdata = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const requestData = {
          // jwttoken: UserService.getToken(),
          start_date: startDate,
          end_date: endDate,
          variable: spendtoplot1,
          brand: brandlist1,
        };
        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA3}/app/correlation`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: requestData,
        };
        const getResponse = await axios(config);
        console.log(getResponse); // setplot1list(Array.from(getResponse.data));
        if (getResponse.status === 200) {
          if (getResponse.data === "No Records Found.") {
            dispatch(
              getNotification({
                message: "There are not statistics record",
                type: "default",
              })
            );
          } else {
            setcorelationdata(getResponse.data);
          }
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/brandanalysis`,
        });
      }, 1000);
    }
  };
  function matchHeights() {
    // Get the elements
    var element1 = document.getElementById('sidenav');
    var element2 = document.getElementById('mainscreen');

    var maxHeight = Math.max(element1.offsetHeight, element2.offsetHeight);
   
    console.log(element1.offsetHeight, element2.offsetHeight)
    // Set the height of both elements

    element1.style.height = maxHeight +25+ 'px';
   element2.style.height = maxHeight + 'px';
    // console.log(element1.style.height,element2.style.height)
  }
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title" id="exampleModalLabel">
                Save Report
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <label>Please enter report Name: </label>
              <input
                type="text"
                id="reportnamebox"
                className="form-control"
                value={reportName}
                onChange={(e) => setreportName(e.target.value)}
              />

              <br />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                  if (reportName === "") {
                    document.getElementById("reportnamebox").focus();
                  } else {
                    handlesavereport();
                  }
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="">
      
      <div className="bgpages px-2 py-3 " >
   
   <div style={{}} className="row my-3">
     {/* <div className="col-sm-9 align-self-center">
     <h5 className='orangetheme' style={{ fontWeight: "700" }} >BRAND ANALYSIS</h5>
     <p className="text-light" >
       Dissects expenditure trends among multiple brands in a specific source of expenditure over a defined time frame.
     </p>
     </div> */}
  
   </div>

   <div className="">

     <div className="rounded-5 card mx-5" style={{ backgroundColor: "white", border:"1px solid #E4CCFF" }}>
       {/* <span className="float-end"><img src="./Assets/Images/icon1.png" style={{ width: "70px", height: "60px" }} /></span> */}
       <div style={{ fontWeight: "500", backgroundColor: "#EAF7FF" }} className="headingcolor p-3 rounded-5" >
         {resultscreen ? <div>BRAND ANALYSIS
           <span className="float-end">
             {/* <button className="btn btn-outline-secondary btn-sm m-1 position-relative" onClick={()=>{setresultscreen(false)}}>Compare</button> */}
             {/* <button className="btn btn-outline-secondary btn-sm m-1"
               data-toggle="modal"
               data-target="#exampleModal">Save Report</button> */}
               
{/* 
           {comparevariablesmenu && <div className="mt-4 row position-absolute" style={{zIndex: 5}}>
             <div id="accordion2" className="" >
       
               <div id="comparesectionvariablesmenu" className=" p-3 card" style={{  backgroundColor: "#F7EFFF" }}>
                  
                 {variableslist2.length > 0 && brandoptions.length > 0
                  ?
                       <div className="">
                        <Select
                         
                         placeholder="Select Compare variables"
                         options={variablesoptions}
                         //value={brandlist1show}
                         onChange={(value) => {
                          
                             handleDropdownChange(value, 'category2')

                           
                         }}

                       />{" "}
                       <Select
                         className="mt-2"
                          placeholder="Select Sub variables"
                         options={subvariablesoptions2}
                         value={displaynames.variables}
                         onChange={(value) => {
                          
                             setspendtoplot2(value.value)

                           
                         }}

                       />{" "}
                       </div>
                   
                   : "You are not authorized to proceed"}
               
                 <button
                   id="compare"
                   className="btn btn-primary btn-sm w-100  mt-4"
                   onClick={() => {
                    
                     fetchplotdatacompare();
                   }}
                 >
                   Submit
                 </button>
               </div>
               </div></div>} */}

               
             {resultscreen && (
               <button

                 className={"btn btn-outline-danger  btn-sm m-1"}
                 onClick={() => {
                   setresultscreen(false)
                  //  setcomparevariablesmenu(false);
                   //scrollToSection("heightmainscreen")
                    // setbrandlist1("")
                    setspendtoplot1([])
                  //  setbrandlist1show([])
                  //  setspendtoplot2("")
                  // setvariableslist("")
                   setcomparescreen(false);
                   setdisplaynames({})
                   setspendtoplot1main([])

                 }}
               >
                 Reset
               </button>
             )}

           </span>
         </div> : "BRAND ANALYSIS"}

       </div>

       <div className=" " >
         {loader ? (
           <div
             className="row d-flex  justify-content-center align-items-center "
             style={{ height: "83vh" }}
           >
             <Loader
               type="box-rectangular"
               bgColor={"#007bff"}
               title={"Generating your plots..."}
               color={"#000000"}
               size={75}
             />
           </div>
         ) : resultscreen ? 
         <div>
           
<div className="py-2 mx-1 shadow row" style={{ backgroundColor: "#fafafa" }}>
           <div className="col-sm-11 mx-auto">
           <div className="d-flex justify-content-between  "  >
                   <div className="">
                     <label htmlFor="" >
                       Start Date<span className="text-danger">*</span>
                     </label>
                     <input
                       type="date"
                  
                       className="form-control input-lg"
                       id="StartDate"
                       value={startDate}
                       onChange={(e) => {
                        console.log(e.target.value)
                         if (endDate) {
                           if (e.target.value <= endDate) {
                            
                             setStartDate(e.target.value);
                           } else {
                             alert("Entered start date is after end date");
                           }
                         } else {
                           setStartDate(e.target.value);
                         }
                       }}
                       placeholder=" Start Date"
                     />

                   </div>

                   <div className="  ">
                     <label htmlFor="">
                       End Date<span className="text-danger">*</span>
                     </label>
                     <input
           
                       type="date"
                       className=" form-control "
                       id="EndDate"
                       placeholder="End Date"
                       value={endDate}
                       onChange={(e) => {
                         if (e.target.value >= startDate) {
                           setEndDate(e.target.value);
                         } else {
                           alert("Please enter End date after start date");
                         }
                       }}
                     />

                   </div>
                   <div className="">
                     <label>Brand:<span className="text-danger">*</span></label>
                     <Select
                                       placeholder="Select Brand"
                  options={brandoptions}
                  value={{label:brandlist1,value:brandlist1}}
                  onChange={(value) => {
                           
                      setbrandlist1(value.value);
              
                  }}
                />





                   </div>
                   <div className="">
                     <label>Market:<span className="text-danger">*</span></label>
                     <Select
                  placeholder="Select market"
                  options={marketoptions}
                  value={{label:market,value:market}}
                  onChange={(value) => {
                           
                      setmarket(value.value);
              
                  }}
                />





                   </div>
                  

  <div>
                       <button
                         id="subm"
                         className="btn btn-primary  mt-4"
                         onClick={() => {
                           //scrollToSection("heightmainscreen");
                           fetchplotdata();
                         }}
                       >
                         Submit
                       </button>
                     </div>
                     </div>
<div className="row ">
<div className="col-sm-4">
                     
                     <label>Variables:
                     <span className="text-danger">*</span>
                     </label>
                   
                     <Select
                      
                       placeholder="Select variables"
                       options={variablesoptions}
                       value={spendtoplot1main}
                       //value={brandlist1show}
                       onChange={(value) => {
                        setspendtoplot1main(value)
                        handlecheckboxesselection(
                          value)
                       
                         
                       }}
isMulti
                     />{" "}
                   
               
                 
                 </div>
                 </div></div>
                 </div>
             
        
             <div className="px-5 py-3  row mt-5">
         
                         {/* <Chart
                         align="center"
        options={options}
        series={salesdata}
        height={400}
        width={'80%'}
      />  */}
     
        <div className="col-sm-3">
        {resultscreen && displaynames.brandlist1?.length > 0
         // && displaynames.variableslist?.length>0
         &&
         <div className=" " style={{width:"300px"}} >
           <span className=" orangetheme" ><b>User Selections</b>

             </span>
           <table className="table table-bordered  table-striped my-1 ">
             <thead>
               <tr>
                 <th style={{color:"darkblue"}}>Parameter</th>
                 <th className="text-right " style={{color:"darkblue"}}>Value</th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td scope="" className="" style={{color:"darkblue"}} >Start Date</td>
                 <td className="text-right">{displaynames.startDate}</td>

               </tr>
               <tr>
                 <td scope=""  className="" style={{color:"darkblue"}}>End Date</td>
                 <td className="text-right">{displaynames.endDate}</td>
               </tr>
               <tr>
                 <td scope=""  className="" style={{color:"darkblue"}}>Brands</td>
                 {/* {displaynames.brandlist1?.length > 1 ? <td className="text-right">{brandlist1.map((brand, index) => (
                   <span key={index}>{brand}{index !== brandlist1.length - 1 && <br />}</span>
                 ))}</td> : <td className="text-right">{brandlist1.map((brand) => { return `${brand}` })}</td>} */}
                 <td className="text-right">{brandlist1}</td>
               </tr>
               <tr>
                 <td scope="" className="" style={{color:"darkblue"}}>variables</td>
              
                                   {displaynames.spendtoplot1?.length > 1 ? <td className="text-right " style={{fontSize:"9px"}}>{displaynames.spendtoplot1.map((brand, index) => (
                   <span key={index}>{brand}{index !== displaynames.spendtoplot1.length - 1 && <br />}</span>
                 ))}</td> : <td className="text-right" style={{fontSize:"9px"}}>{displaynames.spendtoplot1.map((brand) => { return `${brand}` })}</td>}

               </tr>
               {/* {displaynames.spendtoplot2 && <tr>
                 <td scope="" className="" style={{color:"darkblue"}}>Compare variables</td>
                 <td className="text-right">{displaynames.spendtoplot2}</td>
               </tr>} */}
             </tbody>
           </table>

         </div>}
      
         </div>
         <div className="col-sm-9">
      <div className="">
             <Plot
                      data={plotdata1}
                      layout={{
                        width: 1000,
                        height: 545,
                        title: `${displaynames.brandlist1}-${market}`,
                        xaxis: { title: "Month/Year",showgrid:false },
                        yaxis: { title: "variables",showgrid:false },
                        showlegend: true,
                        font:{
                          size: 10
                        },
                        legend: { xanchor:"top",orientation: "h", y: -0.3 },
                      }}
                    />
        </div>
        <div className=" my-3">
            {statisticsdata1.length > 0 ? (
                 <div className=""  id="statistics">
                   <h6 className="orangetheme">Statistics for Main Plot  </h6>
                   <table className="table table-bordered  table-striped shadow">
                        <thead className="">
                          <th style={{color:"#647f99"}}>Variables</th>
                          <th style={{color:"#647f99"}}>Min</th>
                          <th style={{color:"#647f99"}}>Max</th>
                          <th style={{color:"#647f99"}}>Avg</th>
                          <th style={{color:"#647f99"}}>St. Deviation</th>
                        </thead>
                        <tbody>
                      
                          {statisticsdata1?.map((item) => {
                            return (
                              <tr>
                                <td>{item.variable}</td>
                                <td>{item.min_value}</td>
                                <td>{item.max_value}</td>
                                <td>{item.avg_value}</td>
                               <td>{item.stdev_value}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                 </div>
               ): 
               <div className="border rounded-3 p-3 card" style={{backgroundColor:"#fafafa",border:"1px solid #CACCCE"}} id="statistics">  <h6 className="purpletheme">Statistics for main Plot  </h6>There are no records for main plot selections </div>}
{/*             
            {corelationdata?.length > 0 ? (
                           <div className="mt-3" > 
                             <h6 className="orangetheme" >Co-relation Data</h6>
                             
                             <table className="table table-bordered  table-striped " >
                        <thead>
                          {Object.keys(corelationdata[0])?.map((ele) => {
                            return <th style={{color:"#647f99"}}>{ele}</th>;
                          })}
                        </thead>
                        <tbody>
                          {corelationdata?.map((item) => {
                            return (
                              <tr>
                                {Object.keys(corelationdata[0]).map((ele) => {
                                  return <td>{item[`${ele}`]==="Nan"?0:item[`${ele}`]}</td>;
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                           </div>
                         ):
                         <div className="border rounded-3 p-3 card mt-3" style={{backgroundColor:"#fafafa",border:"1px solid #CACCCE"}} id="statistics">  <h6 className="orangetheme">Co-relation Data  </h6>There are no records for main plot selections </div>}
       */}
              </div>
      </div>
     
            
     </div>
       
              
               
               
                           
             {/* <div className="d-flex flex-row justify-content-between">                    <img src="../Assets/Images/image2.png" className="mt-5" align="center" style={{}} />
             <img src="../Assets/Images/image3.png" className="" align="center" style={{}} /> 
           </div> */}
           </div>
       :
           <>
          <div className="row">
          <div className="col-sm px-5 py-4">
             {brandoptions.length > 0 && variablesoptions.length > 0 ?
               
                 <div>
                   <div className="form-group-sm ">
                     <label htmlFor="" >
                       Start Date<span className="text-danger">*</span>
                     </label>
                     <input
                       type="date"
                       disabled={resultscreen}
                       className="form-control input-lg"
                       id="StartDate"
                       value={startDate}
                       onChange={(e) => {
                         if (endDate) {
                           if (e.target.value <= endDate) {
                             setStartDate(e.target.value);
                           } else {
                             alert("Entered start date is after end date");
                           }
                         } else {
                           setStartDate(e.target.value);
                         }
                       }}
                       placeholder=" Start Date"
                     />

                   </div>

                   <div className=" my-2 ">
                     <label htmlFor="">
                       End Date<span className="text-danger">*</span>
                     </label>
                     <input
                       disabled={resultscreen}
                       type="date"
                       className=" form-control "
                       id="EndDate"
                       placeholder="End Date"
                       value={endDate}
                       onChange={(e) => {
                         if (e.target.value >= startDate) {
                           setEndDate(e.target.value);
                         } else {
                           alert("Please enter End date after start date");
                         }
                       }}
                     />

                   </div>
                   <div className="my-2">
                     <label>Brand:<span className="text-danger">*</span></label>
                     <Select
               placeholder="Select Brand"
                  options={brandoptions}
                 
                  value={{label:brandlist1,value:brandlist1}}
                  
                  onChange={(value) => {
            
                 
                      setbrandlist1(value.value);
              
                  }}
                />





                   </div>
                   <div className="my-2">
                     <label>Market:<span className="text-danger">*</span></label>
                     <Select
                  placeholder="Select Market"
                  options={marketoptions}
                  value={{label:market,value:market}}
                  onChange={(value) => {
                           console.log(value)
                      setmarket(value.value);
              
                  }}
                />





                   </div>
                   <div className="">
                     <div className="">
                       <label>Variables:
                       <span className="text-danger">*</span>
                       </label>
                       
                       <Select
                         isDisabled={resultscreen}
                         placeholder="Select variables"
                         options={variablesoptions}
                         value={spendtoplot1main}
                         //value={brandlist1show}
                         onChange={(value) => {
                          setspendtoplot1main(value)
                          handlecheckboxesselection(                            value)}}
isMulti                      />
                      
                 
                     </div><div>
                     
                       <button
                         id="subm"
                         className="btn btn-primary btn-sm w-100  my-4"
                         onClick={() => {
                           //scrollToSection("heightmainscreen");
                           fetchplotdata();
                         }}
                       >
                         Submit
                       </button>
                       </div>
                   </div>



                 </div>
               : "Loading..."}</div>
             <div className="col-sm my-auto">
               <img src="../Assets/Images/image1.png" className="" align="right" style={{}} />
             </div>
          </div>
             </>
         }

       </div>



     </div>


   </div>
 </div>



</div>
<div className='' >
 <FooterPages />
</div>
      

  
            

          
              
     
            
            
              
                        
                                    
                                
                                  
                                     
                                    
                                     
                           
    
     
          
           
             
               
              
                  
   
         
              
          
                 
                
       

          


     
  
    </>
  );
}

export default BrandAnalysis;
