import React,{useState} from 'react'
import FooterPages from './FooterPages'
import Loader from "react-js-loader";
import UserService from '../services/UserService';
import { useDispatch } from "react-redux";
import getNotification from '../Redux/Action/action';
import swal from 'sweetalert'
import Chart from "react-apexcharts";
const XLSX = require("xlsx");


const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;

function RefreshModel() {
  const fileTypes = ["XLS", "XLSX", "CSV", "TSV"];
    const [resultscreen, setresultscreen] = useState(false);
    const [resultscreen3, setresultscreen3] = useState(false);
    const [loader, setloader] = useState(false);
    const [loader2, setloader2] = useState(false);
    const [fileupload,setfileupload]=useState("")
    const [uploadfile,setuploadfile]=useState("")
const [uploadfiledata,setuploadfiledata]=useState([])
const [resultscreen4,setresultscreen4]=useState(false)
const [resultscreen2,setresultscreen2]=useState(true)
const [newscenarionamegiven, setnewscenarionamegiven] = useState("");
let counter=1;

const [modelperformanceplotdata,setmodelperformanceplotdata]=useState({
  "actual_sales": [
      {
          "month_year": "2021-04",
          "year": "2021",
          "month": "04",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 1470.0
      },
      {
          "month_year": "2021-05",
          "year": "2021",
          "month": "05",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 889.0
      },
      {
          "month_year": "2021-06",
          "year": "2021",
          "month": "06",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2084.0
      },
      {
          "month_year": "2021-07",
          "year": "2021",
          "month": "07",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2543.0
      },
      {
          "month_year": "2021-08",
          "year": "2021",
          "month": "08",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2659.0
      },
      {
          "month_year": "2021-09",
          "year": "2021",
          "month": "09",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2483.0
      },
      {
          "month_year": "2021-10",
          "year": "2021",
          "month": "10",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 3655.0
      },
      {
          "month_year": "2021-11",
          "year": "2021",
          "month": "11",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2216.0
      },
      {
          "month_year": "2021-12",
          "year": "2021",
          "month": "12",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 3198.0
      },
      {
          "month_year": "2022-01",
          "year": "2022",
          "month": "01",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2361.0
      },
      {
          "month_year": "2022-02",
          "year": "2022",
          "month": "02",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2239.0
      },
      {
          "month_year": "2022-03",
          "year": "2022",
          "month": "03",
          "brand": "Harrier",
          "scenario_name": "Base Scenario",
          "f_year": "2021-22",
          "model_id": 1,
          "sales": 2661.0
      }
  ],
  "predictions": [
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-04",
          "weekly_predicted_sales": 2629.2261259876,
          "temp_month_year": 1617235200000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-05",
          "weekly_predicted_sales": 765.5225672414,
          "temp_month_year": 1619827200000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-06",
          "weekly_predicted_sales": 1754.3702375492,
          "temp_month_year": 1622505600000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-07",
          "weekly_predicted_sales": 2110.5942683931,
          "temp_month_year": 1625097600000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-08",
          "weekly_predicted_sales": 2765.7224618237,
          "temp_month_year": 1627776000000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-09",
          "weekly_predicted_sales": 2438.3735914273,
          "temp_month_year": 1630454400000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-10",
          "weekly_predicted_sales": 2886.2376084099,
          "temp_month_year": 1633046400000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-11",
          "weekly_predicted_sales": 2034.2668355296,
          "temp_month_year": 1635724800000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2021-12",
          "weekly_predicted_sales": 2983.6576513895,
          "temp_month_year": 1638316800000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2022-01",
          "weekly_predicted_sales": 2625.0137168552,
          "temp_month_year": 1640995200000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2022-02",
          "weekly_predicted_sales": 2383.0941637195,
          "temp_month_year": 1643673600000,
          "financial_year": 2021
      },
      {
          "scenario_name": "scenario_0909",
          "f_year": "2021-22",
          "created_dt": "2024-09-09T15:26:43",
          "week_year": "2022-03",
          "weekly_predicted_sales": 1638.9092782476,
          "temp_month_year": 1646092800000,
          "financial_year": 2021
      }
  ]
})
const [salesdata,setsalesdata]=useState([])
const [options,setoptions]=useState({})
const dispatch = useDispatch();
const downloadSampleFile=async()=>{

   const fileUrl = `assets/Files/SampleFile.xlsx`;
   const link = document.createElement('a');
   link.href = fileUrl;
   link.setAttribute('download', 'samplefile.xlsx'); // Set the name of the file for download
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link); // Clean up the DOM
    }

    const Uploadfile = async () => {
      if (uploadfile) {
          setloader(true);
          if (UserService.isLoggedIn()) {
              setresultscreen3(false);
              setresultscreen4(false);
              const reader = new FileReader();
              reader.readAsBinaryString(uploadfile);
              reader.onload = (e) => {
                  const data = e.target.result;
                  const workbook = XLSX.read(data, { type: "binary" });
                  const sheetName = workbook.SheetNames[0];
                  const sheet = workbook.Sheets[sheetName];
                  const parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });
  
                  // Convert date fields if necessary
                  const formattedData = parsedData.map(row => {
                      return Object.fromEntries(
                          Object.entries(row).map(([key, value]) => {
                              // Check if the value is a date (could be a number)
                              if (typeof value === 'number' && !isNaN(value)) {
                                  // Convert Excel serial date to JavaScript Date
                                  const date = XLSX.SSF.parse_date_code(value);
                                  return [key, new Date(date.y, date.m - 1, date.d)];
                              }
                              return [key, value];
                          })
                      );
                  });
  
                  setuploadfiledata(formattedData);
                  console.log(formattedData);
              };
              setresultscreen(true);
              setTimeout(() => {
                  setresultscreen(true);
                  setloader(false);
              }, 4000);
          } else {
              setTimeout(() => {
                  UserService.doLogin({
                      redirectUri: `${REACT_APP_REDIRECT_URI}/refreshmodel`,
                  });
              }, 1000);
          }
      } else {
          dispatch(
              getNotification({
                  message: "Please attach excel file to upload!",
                  type: "Default",
              })
          );
      }
  };
  
    const refreshModel=async ()=>{
        if (UserService.isLoggedIn()) {
         setloader(true)
        setTimeout(() => {
          setloader(false)
          setresultscreen2(true);
           setresultscreen3(true);
          setresultscreen4(false);
          setresultscreen(false)
          document.getElementById("closemodal").click()
            swal('Model has been refreshed successfully!','', 'success');   
        }, 3000);
          } else {
            setTimeout(() => {
              UserService.doLogin({
                redirectUri: `${REACT_APP_REDIRECT_URI}/refreshmodel`,
              });
            }, 1000);
          }
       
    }
    const fetchmodelperformanceplot=()=>{
      setloader(true)
      setTimeout(() => {
      
      setresultscreen4(true)
      setresultscreen3(false)
      setloader(false)
      // setresultscreen2(false)
      setsalesdata([{name:"Actual Sales",data:modelperformanceplotdata.actual_sales.map((it)=>it.sales.toFixed(0))},
        {name:"Predicted Sales",data:modelperformanceplotdata.predictions.map((it)=>it.weekly_predicted_sales.toFixed(0))}
      ])
      setoptions (
        {
        chart: {    
          id: `Download`,
          type: "line",
      
       
          
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: 2 // Change this value to reduce the line width
        },
        grid: {
          show: false, // Keep grid enabled
       
        },
        title: {
          text: 'Actual vs Predicted',
          align: 'center',
        },
        plotOptions: {
        
        },
        colors: ['#4CAF50', '#FFC107'],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '8px !important',
       
          },
          formatter: function (val) {
            return val
          }
        },
      
        xaxis: {
          categories:modelperformanceplotdata.actual_sales.map((it)=>it.month_year) ,
          title: {
            text: 'Months',
            style:{
              color:"#FFC107",
                },
          },
          labels: {
            style: {
              fontSize: "10px !important",
              
            },
            formatter: function (val) {
              return val 
            }
          },
        },
        yaxis: [
          {
            seriesName: 'Sales',
            opposite: false,
            axisBorder: {
              show: true // Ensures Y-axis line is visible
            },
            title: {
              text: "Sales",
              showAlways: true,
              floating: true,
              style:{
                color:"#4CAF50",
                  },
              
            },
            labels: {
              style: {
                fontSize:'10px' ,
                
              }}
                    },
          ,
          // {
          //   seriesName: 'Predicted Sales',
          //   opposite: true,
          //   title: {
          //     text: "Predicted Sales",
          //     showAlways: true,
          //     floating: true, 
          //      style:{
          //     color:"#FFC107"
          //   },
            
          //   },
                          
            
          // },
        ],
    
      });
    },3000);
    }
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title" id="exampleModalLabel">
                Save Scenario
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <label>Please enter Model Callibration Name:</label>
              <input
                type="text"
                id="scenarionamebox"
                className="form-control"
                value={newscenarionamegiven}
                onChange={(e) => setnewscenarionamegiven(e.target.value)}
              />

              <br />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                  if (newscenarionamegiven === "" || newscenarionamegiven === "Base Scenario") {
                    document.getElementById("scenarionamebox").focus();
                  } else {
                    refreshModel()

                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="">
      <div className="bgpages px-5 py-3" >
   
   <div style={{}} className="row my-3">
     <div className="col-sm-9 align-self-center">
     {/* <h5 className='orangetheme' style={{ fontWeight: "700" }} >REFRESH MODEL</h5> */}
     {/* <p className="text-light" >
       Upload excel files and build new models.
     </p> */}
     </div>
     </div>
     <div className="">
     <div className="rounded-5 card mx-5" style={{ backgroundColor: "white", border:"1px solid #E4CCFF",minHeight:"500px" }}>
       {/* <span className="float-end"><img src="./Assets/Images/icon1.png" style={{ width: "70px", height: "60px" }} /></span> */}
       <div style={{ fontWeight: "500", backgroundColor: "#EAF7FF" }} className="headingcolor p-3 rounded-5 " >
     <span className='d-flex align-items-center mx-auto justify-items-center' > REFRESH MODEL</span> 
       <span className="float-end">
         
               

               
     

           </span>
       </div>

       <div className="row p-4" >
         {loader2 ? (
           <div
             className="row d-flex  justify-content-center align-items-center "
             style={{ height: "83vh" }}
           >
             <Loader
               type="box-rectangular"
               bgColor={"#007bff"}
               title={"Generating your plots..."}
               color={"#000000"}
               size={75}
             />
           </div>
         ) :
        resultscreen2 &&  <div>
        <div className='row p-4'>
        <div className="col-sm ">
       <div className='d-flex justify-content-between '>     
       <label>Upload File ( Excel <i class='ml-1 text-success fas fa-file-excel'></i> ) :<span className="text-danger">*</span></label>
       <a className=' ' onClick={()=>{downloadSampleFile()}}> Sample File (Excel <i class='ml-1 text-success fas fa-file-excel'></i> )</a></div>
       <div className='d-flex'>
       <input className='form-control my-2 w-50' type="file" onChange={(e)=>setuploadfile(e.target.files[0])} />
       
      
       <button className=' btn btn-primary btn-sm mx-2 my-2' style={{height:"35px"}} onClick={()=>Uploadfile()}>Upload
      
       </button> <button className=' btn btn-danger btn-sm my-2' style={{height:"35px"}} onClick={()=>{setresultscreen(false) ;setresultscreen3(false)}}>Reset
      
      </button></div>
 
 {loader ?
    <div
         className="row d-flex  justify-content-center align-items-center "
         style={{ height: "15vh" }}
       >
         <Loader
           type="box-rectangular"
           bgColor={"#007bff"}
           title={"Fetching Data..."}
           color={"#000000"}
           size={50}
         />
       </div>: <div>
       
 {(resultscreen || resultscreen3) &&
     <div className='d-flex flex-row-reverse my-2'>
    
            <button className=' btn btn-primary' data-toggle="modal"
                      data-target="#exampleModal"
                      // onClick={()=>refreshModel()}
                      >Refresh Model
           
            </button>
        {resultscreen3 &&    <button className='btn btn-primary mx-1' onClick={()=>{fetchmodelperformanceplot()}}>Summary</button>   }
 </div>}

 {resultscreen && 
<>

<div className='row mt-3'>
<span className='col-sm'>
<label class="d-inline-block ">Financial Year:</label>
<select class="form-select w-50 d-inline-block  ml-2">
<option>FY 2023-24</option>
</select>
</span>
<span className='col-sm'> <label className='d-inline-block'>Brand:</label><select className='d-inline-block form-select w-50 ml-2'><option>	YIPPEE NOODLES</option></select></span> 
<span className='col-sm'><label className='d-inline-block'> Market:</label><select className='d-inline-block form-select w-50 ml-2'><option>APTG</option></select> </span>
</div>

<div className="tablescrollbar mt-4 mx-auto col-sm-11" style={{ maxHeight: '400px', overflowY: 'auto' }}>
  <table className="table table-striped shadow table-bordered text-center">
    <thead className="" style={{ position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 1 }}>
      
       <tr>
         <th scope="col">#</th>
         {Object.keys(uploadfiledata[0])?.map((item) => {
  return (
    <th
   scope="col"
      style={(item === "Brand" || item === "FY") ? { minWidth: "125px" } : {}}
    >
      { item}
    </th>
  );
})}
       </tr>
     </thead>
     <tbody className=''>
      

       {uploadfiledata?.map((row, index) => (
        row.Brand==="YIPPEE NOODLES" && row.FY==="FY 2023-24" && row.Final_Market==="APTG" &&
         <tr key={index}>
           <td>{counter++}</td>

           {
Object.values(row).map((value, index) => (
             <td key={index}>
              {(!isNaN(Number(value)) && isFinite(Number(value))) ?Number(value).toFixed(0):value}</td>
           ))}
         </tr>
       ))}
     </tbody>
   </table>
  </div>
  </>
     }
    {resultscreen3 && <><div>
      </div><table className='text-center table table-sm my-4 table-striped shadow table-bordered table-responsive-md col-sm-10 mx-auto' style={{fontSize:"12px"}}>
        <thead className='' style={{}}>
            <th>S. No.</th>
            <th>Brand</th>
            <th>Market</th>
            <th> R<sup>2</sup></th>
            <th>MAPE</th>
            <th>Select</th>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>YIPPEE NOODLES</td>
                <td>APTG</td>
                 <td>0.67</td>
                 <td style={{backgroundColor:"#a9d08e"}}>14%</td>
                <td><input type="checkbox" checked/></td>
            </tr>
            <tr>
                <td>2</td>
                <td>YIPPEE NOODLES</td>
                <td>DL</td>
                 <td>0.10</td>
                 <td style={{backgroundColor:"#ff0000"}}>31%</td>
                <td><input  type="checkbox" /></td>
            </tr>
            <tr>
                <td>3</td>
                <td>YIPPEE NOODLES</td>
                <td>GJ</td>
                 <td>0.45</td>
                 <td style={{backgroundColor:"#e2efda"}}>20%</td>
                <td><input type="checkbox" checked/></td>
            </tr>
            <tr>
                <td>4</td>
                <td>YIPPEE NOODLES</td>
                <td>HR</td>
                 <td>0.27</td>
                 <td style={{backgroundColor:"#ff0000"}}>51%</td>
                <td><input  type="checkbox"/></td>
            </tr>
            <tr>
                <td>5</td>
                <td>YIPPEE NOODLES</td>
                <td>KA</td>
                 <td>0.51</td>
                 <td style={{backgroundColor:"#a9d08e"}}>14%</td>
                <td><input type="checkbox" checked/></td>
            </tr>
            <tr>
                <td>6</td>
                <td>YIPPEE NOODLES</td>
                <td>KL</td>
                 <td>0.65</td>
                 <td style={{backgroundColor:"#548235"}}>9%</td>
                <td><input type="checkbox" checked/></td>
            </tr>
            <tr>
                <td>7</td>
                <td>YIPPEE NOODLES</td>
                <td>MH</td>
                 <td>0.60</td>
                 <td style={{backgroundColor:"#a9d08e"}}>12%</td>
                <td><input type="checkbox" checked/></td>
            </tr>
            <tr>
                <td>8</td>
                <td>YIPPEE NOODLES</td>
                <td>MPCH</td>
                 <td>0.47</td>
                 <td style={{backgroundColor:"#548235"}}>9%</td>
                <td><input type="checkbox" checked/></td>
            </tr>
            
        </tbody>
     </table>
     <div>
     *R<sup>2</sup> :Coefficient of Determination,
     * MAPE: Mean Absolute Percentage Error
     </div></>}
    {resultscreen4 && 

    <div>
      <div className='d-flex flex-row-reverse' onClick={()=>{     setresultscreen3(true);
          setresultscreen4(false);}}><button className='btn btn-primary'>Back to Refresh Model</button> </div>
      Model Callibration Name: {newscenarionamegiven}
      <div className='row mt-3'>

<span className='col-sm'> <label className='d-inline-block'>Brand:</label><select className='d-inline-block form-select w-50 ml-2'><option>	YIPPEE NOODLES</option></select></span> 
<span className='col-sm'><label className='d-inline-block'> Market:</label><select className='d-inline-block form-select w-50 ml-2'><option>APTG</option></select> </span>
</div>
      <table className='text-center table table-sm my-4 table-striped shadow table-bordered table-responsive-md col-sm-10 mx-auto'>
        <thead className='text-center' style={{}}>
          <tr>  <th>S. No.</th>
            <th>Brand</th>
            <th>Market</th>
            <th colspan="2">Data Available</th>
            <th colspan="2">Model Refresh</th>
            <th>R<sup>2</sup></th>
            <th>MAPE</th>
            <th>Color Code</th></tr>
            <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>From</th>
            <th>To</th>
            <th>From</th>
            <th>To</th>
            <th></th>
            <th></th>
            <th></th>
            
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>YIPPEE NOODLES</td>
                <td>APTG</td>
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.67</td>
                 <td >14%</td>
                 <td style={{backgroundColor:"#a9d08e"}}></td>
            </tr>
            <tr>
                <td>2</td>
                  <td>YIPPEE NOODLES</td>
                <td>DL</td>
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.10</td>
                 <td >31%</td>
                 <td style={{backgroundColor:"#ff0000"}}></td>
            </tr>
        
            <tr>
                <td>3</td>
                  <td>YIPPEE NOODLES</td>
                <td>GJ</td>
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.45</td>
                 <td>20%</td>
                 <td style={{backgroundColor:"#e2efda"}}></td>
            </tr>
            <tr>
                <td>4</td>
                <td>YIPPEE NOODLES</td>
                <td>HR</td>
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.27</td>
                 <td >51%</td>
                 <td style={{backgroundColor:"#ff0000"}}></td>
            </tr> 
            <tr>
                <td>5</td>
                  <td>YIPPEE NOODLES</td>
                <td>KA</td>
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.51</td>
                 <td >14%</td>
                 <td style={{backgroundColor:"#a9d08e"}}></td>
            </tr>
            <tr>
                <td>6</td>
                  <td>YIPPEE NOODLES</td>
                <td>KL</td>
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.65</td>
                 <td >9%</td>
                 <td style={{backgroundColor:"#548235"}}></td>
            </tr>
            <tr>
                <td>7</td>
                  <td>YIPPEE NOODLES</td>
                <td>MH</td>
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.60</td>
                 <td >12%</td>
                 <td style={{backgroundColor:"#a9d08e"}}></td>
            </tr>
            <tr>
            <td>8</td>
                <td>YIPPEE NOODLES</td>
                <td>MH</td>
                 
               
                 <td>2023-01-01</td>
                 <td>2023-12-31</td>
                 <td>2024-01-01</td>
                 <td>2024-08-31</td>
                 <td>0.47</td>
                 <td >9%</td>
                 <td style={{backgroundColor:"#548235"}}></td>
            </tr>
        </tbody>
     </table>
     <div className='my-3'>
     <Chart
                     align="center"
    options={options}
    series={salesdata}
    height={400}
    width={'80%'}
  /> 
      </div>
  
  </div>}

    </div>}
        </div>

        {/* <div className="col-sm-5 my-auto">
           <img src="../Assets/Images/image1.png" className="mr-1" align="right" style={{width:"500px"}} />
         </div> */}

     </div></div> }
     </div>    </div>

   
     </div>
     </div>
     </div>
     <div className='' >
     <FooterPages />
     </div>
    </>
    
  )
}

export default RefreshModel