import React from "react";
import quationlogoBg0 from "./Images/quationlogoBg0.png";
import { useState, useEffect, useRef } from "react";

import Loader from "react-js-loader";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { useDispatch } from "react-redux";
import UserService from "../services/UserService";

import getNotification from "../Redux/Action/action";
import axios from "axios";
import Chart from "react-apexcharts";
import Plot from "react-plotly.js";

import FooterPages from "./FooterPages";
const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_UPLOAD_DATA2 } = process.env;
function Simulator() {
  const dispatch = useDispatch();
  const [marketoptions, setmarketoptions] = useState(["APTG","DL","GJ","HR","KA","KL","MH","MPCH"])
 
  const [market, setmarket] = useState("APTG");
  const [options1, setoptions1] = useState({})
  const [plotdata1, setplotdata1] = useState([]);
  const [options2, setoptions2] = useState({})
  const [plotdata2, setplotdata2] = useState([]);
  const [options3, setoptions3] = useState({})
  const [plotdata3, setplotdata3] = useState([]);
  const [plotdata4, setplotdata4] = useState([]);
  const [options4, setoptions4] = useState({})

  const [plotdata5, setplotdata5] = useState([]);
  const [options5, setoptions5] = useState({})

  const [loader, setloader] = useState(false);
  const [scenariooptions, setscenariooptions] = useState([]);
  const [scenarionewoldscreen, setscenarionewoldscreen] = useState("select")
  const [viewscenariobtn, setviewscenariobtn] = useState(false)
  const [viewscenariodatatable,setviewscenariodatatable]=useState(false)
  const [newscenariofile, setnewscenariofile] = useState("")
  const [isHovered, setIsHovered] = useState(true);

  const [brandoptions, setbrandoptions] = useState([
    { value: "Brand_1", label: "Brand_1" },
    { value: "Brand_2", label: "Brand_2" },
    { value: "Brand_3", label: "Brand_3" },
    { value: "Brand_4", label: "Brand_4" },
    { value: "Brand_5", label: "Brand_5" },
    { value: "Brand_6", label: "Brand_6" },
  ]);
  const [displaynames, setdisplaynames] = useState({});
  const colors = [
    "red",
    "blue",
    "green",
    "purple",
    "orange",
    "pink",
    "teal",
    "indigo",
    "brown",
    "grey",
    "olive",
    "navy",
    "maroon",
    "gold",
    "darkgreen",
    "darkred",
    "darkblue",
    "darkorange",
    "darkcyan",
    "darkmagenta",
    "darkyellow",
    "darklime",
    "darkpink",
    "darkteal",
    "darkviolet",
  ];

  const [edit, setedit] = useState([]);
  const [selectedzone, setselectedzone] = useState("National")
  const [selectedbrand, setselectedbrand] = useState("");
  const [selectedscenarioname, setselectedscenarioname] = useState("");
  const [selectedscenarioid, setselectedscenarioid] = useState("");
  const [selectedscenarionametimestamp, setselectedscenarionametimestamp] =
    useState("");
  const [selectedyear, setselectedyear] = useState("2021-22");
  const [resultscreen, setresultscreen] = useState(false);
  const [resultscreen2, setresultscreen2] = useState(false);
  const [newscenarionamegiven, setnewscenarionamegiven] = useState("");
  const [originalset, setoriginalset] = useState([
    {
        "attribute": "Competition Event",
        "attribute_backend": "competition_event",
        "subtotal": 6.0,
        "description": "Values to be entered 0 & 1\n0 :No competetion Event\n1 : Competition Event exists",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.0
            }
        ]
    },
    {
        "attribute": "Consumer Offer",
        "attribute_backend": "consumer_offer",
        "subtotal": 434714824.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 21692490.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 14110899.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 35713362.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 38284834.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 41917530.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 40061003.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 47646576.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 32863870.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 57272161.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 31032458.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 32935649.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 41183992.0
            }
        ]
    },
    {
        "attribute": "Corporate Print Advertisement",
        "attribute_backend": "corporate_print_advertisement",
        "subtotal": 705.0,
        "description": "Number of Insert at Corporate Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 201.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 141.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 68.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 204.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 89.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 2.0
            }
        ]
    },
    {
        "attribute": "Corporate TV Advertisement",
        "attribute_backend": "corporate_tv_advertisement",
        "subtotal": 19.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 11.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 2.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 7.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Harrier TV Advertisement",
        "attribute_backend": "harrier_tv_advertisement",
        "subtotal": 0.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "IPL",
        "attribute_backend": "ipl",
        "subtotal": 2.0,
        "description": "0: No IPL\n1: IPL",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Manpower Incentive",
        "attribute_backend": "manpower_incentive",
        "subtotal": 74589761.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 3804066.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 2628875.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 5831054.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 7139249.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 8105926.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 6032101.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 8662982.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 5314596.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 9045181.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 5401808.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 5827981.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 6795943.0
            }
        ]
    },
    {
        "attribute": "Safari TV Advertisement",
        "attribute_backend": "safari_tv_advertisement",
        "subtotal": 514.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 331.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 21.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 119.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 43.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Seasonal Index",
        "attribute_backend": "seasonal_index",
        "subtotal": 13.24,
        "description": "",
        "allow_decimal": 1,
        "frozen": 0,
        "to_show_in": "Not To show",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.62
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.38
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.88
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.08
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 1.13
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.05
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.55
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.94
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.36
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.41
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.35
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.48
            }
        ]
    },
    {
        "attribute": "Turnaround Time",
        "attribute_backend": "turnaround_time",
        "subtotal": 4.0,
        "description": "0: No Waiting Period\n1: Higher Waiting Period",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Not To show",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    }
]);
  const [sampledataset, setsampledataset] = useState([
    {
        "attribute": "Competition Event",
        "attribute_backend": "competition_event",
        "subtotal": 6.0,
        "description": "Values to be entered 0 & 1\n0 :No competetion Event\n1 : Competition Event exists",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.0
            }
        ]
    },
    {
        "attribute": "Consumer Offer",
        "attribute_backend": "consumer_offer",
        "subtotal": 434714824.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 21692490.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 14110899.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 35713362.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 38284834.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 41917530.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 40061003.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 47646576.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 32863870.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 57272161.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 31032458.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 32935649.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 41183992.0
            }
        ]
    },
    {
        "attribute": "Corporate Print Advertisement",
        "attribute_backend": "corporate_print_advertisement",
        "subtotal": 705.0,
        "description": "Number of Insert at Corporate Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 201.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 141.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 68.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 204.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 89.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 2.0
            }
        ]
    },
    {
        "attribute": "Corporate TV Advertisement",
        "attribute_backend": "corporate_tv_advertisement",
        "subtotal": 19.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 11.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 2.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 7.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Harrier TV Advertisement",
        "attribute_backend": "harrier_tv_advertisement",
        "subtotal": 0.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "IPL",
        "attribute_backend": "ipl",
        "subtotal": 2.0,
        "description": "0: No IPL\n1: IPL",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual(1/0)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Manpower Incentive",
        "attribute_backend": "manpower_incentive",
        "subtotal": 74589761.0,
        "description": "Total Rs value spend in the Month",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual ( in Cr)",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 3804066.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 2628875.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 5831054.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 7139249.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 8105926.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 6032101.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 8662982.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 5314596.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 9045181.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 5401808.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 5827981.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 6795943.0
            }
        ]
    },
    {
        "attribute": "Safari TV Advertisement",
        "attribute_backend": "safari_tv_advertisement",
        "subtotal": 514.0,
        "description": "Number of GRP at Brand Level",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Actual",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 331.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 21.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 119.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 43.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    },
    {
        "attribute": "Seasonal Index",
        "attribute_backend": "seasonal_index",
        "subtotal": 13.24,
        "description": "",
        "allow_decimal": 1,
        "frozen": 0,
        "to_show_in": "Not To show",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 0.62
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 0.38
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 0.88
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.08
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 1.13
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 1.05
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 1.55
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.94
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 1.36
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 1.41
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 1.35
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 1.48
            }
        ]
    },
    {
        "attribute": "Turnaround Time",
        "attribute_backend": "turnaround_time",
        "subtotal": 4.0,
        "description": "0: No Waiting Period\n1: Higher Waiting Period",
        "allow_decimal": 0,
        "frozen": 0,
        "to_show_in": "Not To show",
        "month_data": [
            {
                "month": 4,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 5,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 6,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 7,
                "frozen": 0,
                "value": 1.0
            },
            {
                "month": 8,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 9,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 10,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 11,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 12,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 1,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 2,
                "frozen": 0,
                "value": 0.0
            },
            {
                "month": 3,
                "frozen": 0,
                "value": 0.0
            }
        ]
    }
]);

  const [tablepredictionsdataset, settablepredictionsdataset] = useState([]);
  const [tablecontributiondataset, settablecontributiondataset] = useState([]);
  const sectionRef = useRef(null);
  const [monthlist, setmonthlist] = useState(["April", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec", "Jan", "Feb", "March"])
  useEffect(() => {
    handlebrandmenu();
    fetchscenariooptions();
    //fetchdatasettableonload();

  }, []);

 
  const downloadSampleFile = async () => {

    const fileUrl = `assets/Files/SCurvedata.xlsx`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'samplefile.xlsx'); // Set the name of the file for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  }
  const scrollToTop = () => {
    if (sectionRef.current) {
      const yOffset = -50; // Adjust this value based on your preference
      const elementTop = sectionRef.current.offsetTop;
      const offsetPosition = elementTop + yOffset;

      window.scrollBy(500, 500);
      // window.scroll({
      //   top: offsetPosition,
      //   behavior: 'smooth',
      // });
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
    document.getElementById("userparabtn").classList.add("d-none");
    //if (document.getElementById("sidenav")) { document.getElementById("sidenav").style.width = "750px" };
    if (document.getElementById("mainscreen")) { document.getElementById("mainscreen").classList.add("blurry-component") };
    if (document.getElementById("subcontainer3")) {
      document.getElementById("subcontainer3").classList.add("blurry-component");
    }
  };

  function matchHeights() {
    // Get the elements
    var element1 = document.getElementById('sidenav');
    var element2 = document.getElementById('mainscreen');

    // Find the maximum height
    var maxHeight = Math.max(element1.offsetHeight, element2.offsetHeight);

    // Set the height of both elements
    element1.style.height = maxHeight + 25 + 'px';
    element2.style.height = maxHeight + 'px';
  }
  const handleMouseLeave = () => {
    setIsHovered(false);
    document.getElementById("userparabtn").classList.remove("d-none");
    //if (document.getElementById("sidenav")) { document.getElementById("sidenav").style.width = "160px" };
    //document.getElementById("sidenav").classList.remove("col-sm-6");
    if (document.getElementById("mainscreen")) { document.getElementById("mainscreen").classList.remove("blurry-component") };

    // document.getElementById("mainscreen").classList.remove("col-sm-6");
    // document.getElementById("mainscreen").classList.add("col-sm-10");
  };
  const handlebrandmenu = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("jwttoken", UserService.getToken());
        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/brand/getbrands`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {

          setbrandoptions(getResponse.data);
          setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
        });
      }, 1000);
    }
  };
  const scrollToSection = (sectionname) => {
    // Replace 'section2' with the id of the section you want to scroll to

    if (document.getElementById(sectionname)) {
      document
        .getElementById(sectionname)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      dispatch(
        getNotification({
          message: "Please proceed with first analysis ",
          type: "Default",
        })
      );
    }
  };

  const fetchscenariooptions = async () => {
    if (UserService.isLoggedIn()) {
      if (true) {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("jwttoken", UserService.getToken());

          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/Simulation/getscenario`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: sendData,
          };
          const getResponse = await axios(config);
          //console.log(getResponse);

          if (getResponse.status === 200) {
            if (getResponse.data !== "Invalid User!") {
              // setscenariooptions(getResponse.data?.map((item)=>{
              //   return {label:item.scenario_name,value:item.scenario_name}
              // }));
              setscenariooptions(getResponse.data);
              // setselectedscenarioname(getResponse.data[0].scenario_name);
              // setselectedscenarioid(getResponse.data[0].id)
              // setselectedscenarionametimestamp(getResponse.data[0].updated_dt);
            }
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      } else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
        });
      }, 1000);
    }
    setloader(false);
  };





  const fetchdatasettable = async () => {
    if (UserService.isLoggedIn()) {
      try {
        setloader(true)
        let config = {};
        // if (selectedscenarioname === "Base Scenario") {
        //   const FormData = require("form-data");
        //   const sendData = new FormData();
        //   sendData.append("jwttoken", UserService.getToken());
        //   sendData.append("brand", selectedbrand);
        //   sendData.append("fin_year", selectedyear);

        //   config = {
        //     method: "post",
        //     url: `${REACT_APP_UPLOAD_DATA}/Simulation/get_base_scenariodata`,
        //     headers: {
        //       Accept: "text/plain",
        //       "Content-Type": "application/json",
        //     },
        //     data: sendData,
        //   };

        // }
        // else {
        //   const FormData = require("form-data");
        //   const sendData = new FormData();
        //   sendData.append("jwttoken", UserService.getToken());
        //   sendData.append("scenario_name", selectedscenarioname);
        //   sendData.append("scenario_id", selectedscenarioid);
        //   sendData.append("scenario_user", "admin");

        //   config = {
        //     method: "post",
        //     url: `${REACT_APP_UPLOAD_DATA2}/getscenariodata`,
        //     headers: {
        //       Accept: "text/plain",
        //       "Content-Type": "application/json",
        //     },
        //     data: sendData,
        //   };

        // }

        // const getResponse = await axios(config);
const getResponse={data:[
  {
      "attribute": "Competition Event",
      "attribute_backend": "competition_event",
      "subtotal": 6.0,
      "description": "Values to be entered 0 & 1\n0 :No competetion Event\n1 : Competition Event exists",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual(1/0)",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 1.0
          }
      ]
  },
  {
      "attribute": "Consumer Offer",
      "attribute_backend": "consumer_offer",
      "subtotal": 434714824.0,
      "description": "Total Rs value spend in the Month",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual ( in Cr)",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 21692490.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 14110899.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 35713362.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 38284834.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 41917530.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 40061003.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 47646576.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 32863870.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 57272161.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 31032458.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 32935649.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 41183992.0
          }
      ]
  },
  {
      "attribute": "Corporate Print Advertisement",
      "attribute_backend": "corporate_print_advertisement",
      "subtotal": 705.0,
      "description": "Number of Insert at Corporate Level",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 201.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 141.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 68.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 204.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 89.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 2.0
          }
      ]
  },
  {
      "attribute": "Corporate TV Advertisement",
      "attribute_backend": "corporate_tv_advertisement",
      "subtotal": 19.0,
      "description": "Total Rs value spend in the Month",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual ( in Cr)",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 11.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 2.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 7.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 0.0
          }
      ]
  },
  {
      "attribute": "Harrier TV Advertisement",
      "attribute_backend": "harrier_tv_advertisement",
      "subtotal": 0.0,
      "description": "Number of GRP at Brand Level",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 0.0
          }
      ]
  },
  {
      "attribute": "IPL",
      "attribute_backend": "ipl",
      "subtotal": 2.0,
      "description": "0: No IPL\n1: IPL",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual(1/0)",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 0.0
          }
      ]
  },
  {
      "attribute": "Manpower Incentive",
      "attribute_backend": "manpower_incentive",
      "subtotal": 74589761.0,
      "description": "Total Rs value spend in the Month",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual ( in Cr)",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 3804066.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 2628875.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 5831054.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 7139249.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 8105926.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 6032101.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 8662982.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 5314596.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 9045181.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 5401808.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 5827981.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 6795943.0
          }
      ]
  },
  {
      "attribute": "Safari TV Advertisement",
      "attribute_backend": "safari_tv_advertisement",
      "subtotal": 514.0,
      "description": "Number of GRP at Brand Level",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Actual",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 331.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 21.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 119.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 43.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 0.0
          }
      ]
  },
  {
      "attribute": "Seasonal Index",
      "attribute_backend": "seasonal_index",
      "subtotal": 13.24,
      "description": "",
      "allow_decimal": 1,
      "frozen": 0,
      "to_show_in": "Not To show",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 0.62
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 0.38
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 0.88
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 1.08
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 1.13
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 1.05
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 1.55
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 0.94
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 1.36
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 1.41
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 1.35
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 1.48
          }
      ]
  },
  {
      "attribute": "Turnaround Time",
      "attribute_backend": "turnaround_time",
      "subtotal": 4.0,
      "description": "0: No Waiting Period\n1: Higher Waiting Period",
      "allow_decimal": 0,
      "frozen": 0,
      "to_show_in": "Not To show",
      "month_data": [
          {
              "month": 4,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 5,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 6,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 7,
              "frozen": 0,
              "value": 1.0
          },
          {
              "month": 8,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 9,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 10,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 11,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 12,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 1,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 2,
              "frozen": 0,
              "value": 0.0
          },
          {
              "month": 3,
              "frozen": 0,
              "value": 0.0
          }
      ]
  }
]}
        //handleMouseLeave();
        if (getResponse.data) {
       setTimeout(()=>{   if (Array.isArray(getResponse.data) && !getResponse.data[0].Exception && getResponse.data.length > 0 && getResponse.data[0].Data !== "No Records Found" && getResponse.data[0].Error !== "Invalid User!"
       ) {
         setviewscenariodatatable(true)
         setresultscreen(true)
         setdisplaynames({
           ...displaynames,
           brand: selectedbrand,
           scenarioname: selectedscenarioname,
           id: selectedscenarioid,
           zone: selectedzone,
           year: selectedyear,
           timestamp: selectedscenarionametimestamp,
           market:market
         })

         setsampledataset(getResponse?.data)
         setoriginalset(getResponse?.data);
       setTimeout(()=>{
         document.getElementById("main1").classList.remove("col-sm");
         document.getElementById("main1").classList.add("col-sm-2");
         document.getElementById("main2").classList.remove("col-sm");
         document.getElementById("main2").classList.add("col-sm-10");
       },100)


       }
       else if (getResponse.data[0].Error === "Invalid User!") {
         UserService.doLogin({
           redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
         });
       }
       else {
         //console.log(scenariooptions[0].scenario_name)
        //  if (displaynames) {
        //    setselectedbrand(displaynames.brand || brandoptions[0].brand)
        //    setselectedscenarioname(displaynames.scenarioname || scenariooptions[0].scenario_name)
        //    setselectedzone(displaynames.zone || 'National')
        //    setselectedyear(displaynames.year || '2021-22')
        //    setselectedscenarioid(displaynames.id || scenariooptions[0].id)
        //  }
         // else{
         //   setselectedscenarioname(scenariooptions[0].scenario_name)
         //   setselectedscenarioid(scenariooptions[0].id)
         //   setselectedbrand(brandoptions[0]?.brand)
         //   setselectedzone('National')
         //   setselectedyear("2021-22")
         // }

         dispatch(
           getNotification({
             message: `There is no valid data to display for selected brand and scenario combination`,
             type: "default",
           })

         );
         //handleMouseEnter()
         setresultscreen2(false)
       }

       },3000)
        }
      } catch (err) {
        console.log("Server Error", err);
        if (displaynames) {
          setselectedbrand(displaynames.brand || brandoptions[0].brand)
          setselectedscenarioname(displaynames.scenarioname || scenariooptions[0].scenario_name)
          setselectedzone(displaynames.zone || 'National')
          setselectedyear(displaynames.year || '2021-22')
          setselectedscenarioid(displaynames.id || scenariooptions[0].id)
        }

        // else{
        //       setselectedscenarioname(scenariooptions[0].scenario_name)
        //       setselectedscenarioid(scenariooptions[0].id)
        //       setselectedbrand(brandoptions[0]?.brand)
        //       setselectedzone('National')
        //       setselectedyear('2021-22')
        // }
        //handleMouseEnter()
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
        });
      }, 1000);
    }
    setloader(false);
  };

  const changeelements = (variableIndex, valueIndex, e) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].month_data[valueIndex].frozen === 0) {
      if (updatedDataset[variableIndex].frozen === 0) {
        updatedDataset[variableIndex].month_data[valueIndex].value =
          parseInt(e.target.value) || 0;
        updatedDataset[variableIndex].subtotal = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, value) => acc + value.value, 0);
      } else {
        const subtotalFrozenValues = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, item, index) => {
          if (item.frozen === 1) {
            return acc + item.value;
          } else {
            return acc;
          }
        }, 0);
        const subtotalValue =
          updatedDataset[variableIndex].subtotal -
          (parseInt(e.target.value) || 0) -
          subtotalFrozenValues;
        const month_data = updatedDataset[variableIndex].month_data;
        // console.log(subtotalValue, month_data);

        if (subtotalValue < 0) {
          dispatch(getNotification({ message: "Values not valid", type: "danger" }))
        }
        // else if(subtotalValue===0){
        //   console.log("h")
        //   updatedDataset[variableIndex].month_data[valueIndex].value =
        //   parseInt(e.target.value) || 0;
        // }
        else {
          updatedDataset[variableIndex].month_data[valueIndex].value =
            parseInt(e.target.value) || 0;
          const nonFrozenArray = updatedDataset[
            variableIndex
          ].month_data.filter((it, index) => {
            if (index === valueIndex) {
            } else {
              return it.frozen === 0;
            }
          });
          //console.log(nonFrozenArray);

          const numberOfNonFrozenMonths = nonFrozenArray.length;
          if (numberOfNonFrozenMonths > 0) {
            const subtotalNonFrozenValues = nonFrozenArray.reduce(
              (acc, item) => acc + item.value,
              0
            );
            //console.log(subtotalNonFrozenValues);

            updatedDataset[variableIndex].month_data = month_data.map(
              (item, index) => {
                if (item.frozen === 0 && index !== valueIndex) {
                  const prorataValue = parseFloat(
                    (subtotalValue * (item.value / subtotalNonFrozenValues)).toFixed(
                      2
                    )
                  );

                  return {
                    ...item,
                    value: prorataValue,
                  };
                } else {
                  return item;
                }
              }
            );
          }
        }
      }

      setsampledataset(updatedDataset);
    }
  };

  const changeelementsdecimal = (variableIndex, valueIndex, e) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].month_data[valueIndex].frozen === 0) {
      if (updatedDataset[variableIndex].frozen === 0) {

        updatedDataset[variableIndex].month_data[valueIndex].value =
          e.target.value || 0;
        updatedDataset[variableIndex].subtotal = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, value) => acc + parseFloat(value.value), 0);
        console.log(updatedDataset[
          variableIndex
        ].month_data.reduce((acc, value) => acc + parseFloat(value.value), 0))
      }
      else {
        const subtotalFrozenValues = updatedDataset[
          variableIndex
        ].month_data.reduce((acc, item, index) => {
          if (item.frozen === 1) {
            return acc + parseFloat(item.value);
          } else {
            return acc;
          }
        }, 0);
        //console.log(subtotalFrozenValues);
        const subtotalValueforprorata = updatedDataset[variableIndex].subtotal - (parseFloat(e.target.value) || 0) - subtotalFrozenValues;
        ;


        if (subtotalValueforprorata < 0) {
          dispatch(getNotification({ message: "Values not valid", type: "danger" }))
        }
        // else if(subtotalValue===0){
        //   console.log("h")
        //   updatedDataset[variableIndex].month_data[valueIndex].value =
        //   parseInt(e.target.value) || 0;
        // }
        else {
          updatedDataset[variableIndex].month_data[valueIndex].value =
            parseFloat(e.target.value) || 0;
          const nonFrozenArray = updatedDataset[
            variableIndex
          ].month_data.filter((it, index) => {
            if (index === valueIndex) {
            } else {
              return it.frozen === 0;
            }
          });
          //console.log(nonFrozenArray);

          const numberOfNonFrozenMonths = nonFrozenArray.length;
          if (numberOfNonFrozenMonths > 0) {
            const subtotalNonFrozenValues = nonFrozenArray.reduce((acc, item) => acc + parseFloat(item.value), 0);


            //console.log(subtotalNonFrozenValues);
            updatedDataset[variableIndex].month_data = updatedDataset[variableIndex].map(
              (item, index) => {
                if (item.frozen === 0 && index !== valueIndex) {
                  const prorataValue =
                    (subtotalValueforprorata * (parseFloat(item.value) / subtotalNonFrozenValues)

                    );

                  return {
                    ...item,
                    value: parseFloat(prorataValue),
                  };
                } else {
                  return item;
                }
              }
            );
          }
        }
      }
      setsampledataset(updatedDataset);
    }
  };

  const changesubtotal = (e, variableIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].frozen === 0) {
      const subtotalValue = parseInt(e.target.value) || 0;

      if (subtotalValue === 0 || Number.isNaN(subtotalValue)) {

        updatedDataset[variableIndex].subtotal = 0;
        setsampledataset(updatedDataset);
      }
      else {

        const month_data = updatedDataset[variableIndex].month_data;
        const nonFrozenArray = updatedDataset[variableIndex].month_data.filter(
          (it) => it.frozen === 0
        );
        const numberOfNonFrozenMonths = nonFrozenArray.length;
        if (numberOfNonFrozenMonths > 0) {
          const subtotalNonFrozenValues = nonFrozenArray.reduce(
            (acc, item) => acc + item.value,
            0
          );
          //.log(subtotalNonFrozenValues)
          updatedDataset[variableIndex].month_data = month_data.map((item) => {
            if (item.frozen === 0) {
              //console.log(subtotalValue)
              const prorataValue =
                subtotalValue * (item.value / subtotalNonFrozenValues);

              return {
                ...item,
                value: prorataValue,
              };
            } else {
              return item;
            }
          });
          updatedDataset[variableIndex].subtotal = subtotalValue;
          setsampledataset(updatedDataset);
        }

      }
    }
  };

  const changesubtotaldecimal = (e, variableIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].frozen === 0) {
      const subtotalValue = e.target.value || 0;
      if (subtotalValue === "0" || subtotalValue === 0 || Number.isNaN(subtotalValue)) {

        // console.log("Condition met. Do nothing.");
        updatedDataset[variableIndex].subtotal = 0;
        setsampledataset(updatedDataset);
      }
      else {

        const month_data = updatedDataset[variableIndex].month_data;
        const nonFrozenArray = updatedDataset[variableIndex].month_data.filter(
          (it) => it.frozen === 0
        );
        const numberOfNonFrozenMonths = nonFrozenArray.length;
        if (numberOfNonFrozenMonths > 0) {
          const subtotalNonFrozenValues = nonFrozenArray.reduce(
            (acc, item) => acc + item.value,
            0
          );
          updatedDataset[variableIndex].month_data = month_data.map((item) => {
            if (item.frozen === 0) {
              const prorataValue =
                subtotalValue * (item.value / subtotalNonFrozenValues);
              return {
                ...item,
                value: parseFloat(prorataValue),
              };
            } else {
              return item;
            }
          });
          updatedDataset[variableIndex].subtotal = subtotalValue;
          setsampledataset(updatedDataset);
        }
      }
    }
  };

  const handlecancel = (variableIndex) => {

    const updatedDataset1 = [...sampledataset];
    const updatedDataset2 = [...originalset];
    updatedDataset1[variableIndex].month_data = updatedDataset2[
      variableIndex
    ].month_data.map((it) => {
      return it;
    });
    updatedDataset1[variableIndex].subtotal = updatedDataset2[variableIndex].subtotal;
    updatedDataset1[variableIndex].frozen = 0;
    setsampledataset(updatedDataset1);
    //setsampledataset(originalset);
  };

  const clearAll = (variableIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset));
    updatedDataset[variableIndex].month_data = updatedDataset[
      variableIndex
    ].month_data.map((item) => {
      return { ...item, value: 0, frozen: 0 };
    });
    updatedDataset[variableIndex].subtotal = 0;
    //console.log(updatedDataset[variableIndex]);
    setsampledataset(updatedDataset);
  };
  const togglelock = (variableIndex, valueIndex) => {
    const updatedDataset = JSON.parse(JSON.stringify(sampledataset))
    if (updatedDataset[variableIndex].month_data[valueIndex].frozen === 0) {
      updatedDataset[variableIndex].month_data[valueIndex].frozen = 1;
    } else if (
      updatedDataset[variableIndex].month_data[valueIndex].frozen === 1
    ) {
      updatedDataset[variableIndex].month_data[valueIndex].frozen = 0;
    }
    setsampledataset(updatedDataset);
  };
  const togglelocksubtotal = (variableIndex) => {
    const updatedDataset = [...sampledataset];
    if (updatedDataset[variableIndex].frozen === 0) {
      updatedDataset[variableIndex].frozen = 1;
    } else if (updatedDataset[variableIndex].frozen === 1) {
      updatedDataset[variableIndex].frozen = 0;
    }
    setsampledataset(updatedDataset);
  };
  const getUserID = () => {
    const token = UserService.getToken();
    const subs = parseJwt(token);
    // console.log(token);
    const sub = subs.sub;
    //console.log(sub);
    return sub;
  };
  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };
  const predictchart = async (newname) => {
    if (UserService.isLoggedIn()) {

      if (market && selectedscenarioname && selectedbrand) {
        try {
          setloader(true);
          // if (displaynames) {
          //   setselectedbrand(displaynames.brand)
          //   setselectedscenarioname(displaynames.scenarioname)
          //   setselectedzone(displaynames.zone)
          //   setselectedyear(displaynames.year)
          //   setselectedscenarioid(displaynames.id)
          // }
         // const userid = getUserID();
          // const timestamp=Math.floor(new Date().getTime()/1000)
          // const requestData = {
          //   scenario_name: newname ? newname : selectedscenarioname,
          //   scenario_timestamp: selectedscenarionametimestamp,
          //   user_id: "admin",
          //   brand: selectedbrand,
          //   f_year: '2021-22',
          //   model_id: 1,
          //   dataset: originalset,
          // };

          // const config = {
          //   method: "post",
          //   url: `${REACT_APP_UPLOAD_DATA2}/predict`,
          //   headers: {
          //     Accept: "text/plain",
          //     "Content-Type": "application/json",
          //   },
          //   data: requestData,
          // };
          // const getResponse = await axios(config);
const getResponse={data:{
  "base": {
      "model_id": "[1]",
      "f_year": "[\"2021-22\"]",
      "brand": "[\"Harrier\"]",
      "user_id": "[\"admin\"]",
      "created_dt": "[\"2024-09-24T11:50:15\"]",
      "scenario_name": "[\"Base Scenario\"]",
      "marketing_pc": [
          0.0
      ],
      "marketing_variable": [
          0.0
      ],
      "base_pc": [
          100.0
      ],
      "base": "[28053.087898353]"
  },
  "contribution": {
      "model_id": "[1,1,1,1,1,1,1,1,1]",
      "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
      "marketing_variable": [
          "Competition Event",
          "Consumer Offer",
          "Corporate Print Advertisement",
          "Corporate TV Advertisement",
          "Harrier TV Advertisement",
          "IPL",
          "Manpower Incentive",
          "Safari TV Advertisement",
          "Turnaround Time"
      ],
      "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
      "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
      "created_dt": "[\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\"]",
      "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
      "contribution_pc": [
        11,13,9,5,3,18,20,3,18
      
        
      ],
      "contribution": [
          -3856.28,
          881.74,
          1363.33,
          0.0,
          0.0,
          385.31,
          387.32,
          2899.41,
          -2259.77
      ]
  },
  "roi": {
      "model_id": "[1,1,1,1,1,1,1,1,1,1]",
      "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
      "marketing_variable": [
          "Competition Event",
          "Consumer Offer",
          "Corporate Print Advertisement",
          "Corporate TV Advertisement",
          "Harrier TV Advertisement",
          "IPL",
          "Manpower Incentive",
          "Safari TV Advertisement",
          "Seasonal Index",
          "Turnaround Time"
      ],
      "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
      "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
      "created_dt": "[\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\"]",
      "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
      "roi": [
          0.0,
          1.81,
          32.1,
          -1.0,
          0.0,
          0.0,
          9.11,
          28.83,
          0.0,
          0.0
      ]
  },
  "predictions": {
      "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
      "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
      "created_dt": "[\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\"]",
      "week_year": [
          "2021-APR",
          "2021-MAY",
          "2021-JUN",
          "2021-JUL",
          "2021-AUG",
          "2021-SEP",
          "2021-OCT",
          "2021-NOV",
          "2021-DEC",
          "2022-JAN",
          "2022-FEB",
          "2022-MAR"
      ],
      "weekly_predicted_sales": [
          2629.226125987585,
          765.5225672413728,
          1754.3702375491735,
          2110.5942683931476,
          2765.7224618237374,
          2438.3735914272515,
          2886.2376084099155,
          2034.2668355295707,
          2983.657651389547,
          2625.013716855235,
          2383.0941637194605,
          2677.008670026999
      ]
  },
  "monthly_base": {
      "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
      "month_year": "[\"2021-04\",\"2021-05\",\"2021-06\",\"2021-07\",\"2021-08\",\"2021-09\",\"2021-10\",\"2021-11\",\"2021-12\",\"2022-01\",\"2022-02\",\"2022-03\"]",
      "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
      "created_dt": "[\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\"]",
      "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
      "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
      "predict_sales": "[2629.2261259876,765.5225672414,1754.3702375492,2110.5942683931,2765.7224618237,2438.3735914273,2886.2376084099,2034.2668355296,2983.6576513895,2625.0137168552,2383.0941637195,2677.008670027]",
      "contribution": "[-593.3595620963,-73.8427621351,49.0663187678,-21.0691520724,700.9914359948,446.3723366005,-354.303280711,330.0285244472,221.1123705946,-239.6831025592,-304.9802063867,-359.2610329304]",
      "base": "[3222.5856880839,839.3653293765,1705.3039187814,2131.6634204656,2064.7310258289,1992.0012548267,3240.5408891209,1704.2383110824,2762.5452807949,2864.6968194144,2688.0743701062,3036.2697029574]",
      "model_id": "[1,1,1,1,1,1,1,1,1,1,1,1]"
  },
  "monthly_contribution": {
      "f_year": "[\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\",\"2021-22\"]",
      "month_year": "[\"2021-04\",\"2021-05\",\"2021-06\",\"2021-07\",\"2021-08\",\"2021-09\",\"2021-10\",\"2021-11\",\"2021-12\",\"2022-01\",\"2022-02\",\"2022-03\"]",
      "scenario_name": "[\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\",\"Base Scenario\"]",
      "created_dt": "[\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\",\"2024-09-24T11:50:15\"]",
      "user_id": "[\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\",\"admin\"]",
      "brand": "[\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\",\"Harrier\"]",
      "competition_event": "[-544.4467533937,0.0,0.0,0.0,0.0,-504.9259827033,-597.6673820063,0.0,-617.8406282674,-543.5744691678,-493.4790003964,-554.3413192182]",
      "consumer_offer": "[90.9588834088,0.0,50.3192703313,64.9988614321,92.7790746246,78.4497450922,107.9985512344,53.1914634713,128.158186085,64.0401113464,62.4711872599,88.3712023764]",
      "corporate_print_advertisement": "[0.0,0.0,207.1448456196,233.612348388,263.7470413056,288.6578718218,0.0,0.0,301.4520201538,0.0,0.0,68.7160195629]",
      "corporate_tv_advertisement": "[0.0,0.0,0.0,0.0000510764,0.0000121692,0.0,0.0000444481,0.0,0.0,0.0,0.0,0.0]",
      "harrier_tv_advertisement": "[0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]",
      "ipl": "[0.0,0.0,0.0,0.0,0.0,385.3138110414,0.0,0.0,0.0,0.0,0.0,0.0]",
      "manpower_incentive": "[35.7286150497,0.0,20.9083536692,31.4969274954,46.475472259,30.289034636,51.3377249214,21.4366796887,54.9740890003,28.2980282368,28.3827216041,37.9930643485]",
      "safari_tv_advertisement": "[642.8122402627,164.4453288639,316.7855683213,305.798038983,297.9898356364,168.5878567124,84.0277806914,255.4003812872,354.3687036229,211.5532270254,97.6448851456,0.0]",
      "turnaround_time": "[-818.4125474238,-238.288090999,-546.0917191737,-656.9753794474,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0]",
      "model_id": "[1,1,1,1,1,1,1,1,1,1,1,1]"
  }
}}
          // setplot1list(Array.from(getResponse.data));
          if (getResponse.data) {

          setTimeout(()=>{
setloader(false)
if (getResponse.data.base) {
  setresultscreen2(true);

  setoptions1(
    {
      chart: {
        id: `Download-Predicted Sales Vs Time`,
        type: "bar",

        toolbar: {
          show: true,
        },
      },
      grid:{
        show:false
      },
      title: {
        text: 'Predicted Sales Vs Time',
        align: 'center',
        style:{
          fontWeight:"100",
       color: "#43474B"
        }
      },
      plotOptions: {

      },
      colors: ['#27ae60','#f4d03f'],
      dataLabels: {
        enabled: true,
        
        formatter: function (val) {
          return val 
        }
      },

      xaxis: {
        categories: getResponse.data.predictions?.week_year.map((item, index) => {
          return `${item.split("-")[1]}-${item.split("-")[0]}`
          
        }),
        title: {
          text: 'Month-Year',
          style: {
            fontWeight:"100",
          },
        },

        labels: {
          style: {
 fontWeight:"100",
 fontSize:"9px"
          },
          

          formatter: function (val) {
            return val
          }
        },
      },
      yaxis: [
        {
          seriesName: 'Sales',
          opposite: false,
          labels: {
           
          
            
            formatter: function (val) {
              return val 
            }
          },
          axisBorder: {
            show: true // Ensures Y-axis line is visible
          },
          title: {
            text: "Precited Sales",
            showAlways: true,
            floating: true,
            style: {
              fontWeight:"100"
            },

          },
        },
        ,
      

      ],

    });
  setplotdata1([
    { name: "Sales", data: getResponse.data.predictions?.week_year.map((item, index) => {
    
        return getResponse.data.predictions?.weekly_predicted_sales[index] !== null ? getResponse.data.predictions?.weekly_predicted_sales[index].toFixed(0) : ""
      
    }) },

  ])

  

  setoptions2(
    {
      chart: {
        id: `Download-Contribution Quantity vs Variables`,
        type: "bar",

        toolbar: {
          show: true,
        },
      },
      grid:{
        show:false
      },
      title: {
        text: ' Contribution Quantity vs Variables',
        align: 'center',
        style:{
          fontWeight:"100",
       color: "#43474B"
      },},
      plotOptions: {

      },
      colors: ['#7D1007'],
      dataLabels: {
        enabled: true,
      
        formatter: function (val) {
          return val
        }
      },
      plotOptions:{
        bar:{dataLabels: {
          position: 'top',
      },
      },},
      xaxis: {
        categories: getResponse.data.contribution?.marketing_variable.map((item, index) => {
          return item
          
        }),
        axisBorder: {
          show: true // Ensures X-axis line is visible
        },
        title: {
          text: 'Variables',
style:{fontWeight:"100"}
        },

        labels: {
          style: {
 fontWeight:"100",
 fontSize:"9px"
          },
          

          formatter: function (val) {
            return val
          }
        },
      },
      yaxis: [
        {
          seriesName: 'Contribution Qty',
          opposite: false,
          axisBorder: {
            show: true // Ensures Y-axis line is visible
          },
          
          labels: {
            style: {
           fontWeight:"100",
          fontSize:'0.8em !important'

            },
            formatter: function (val) {
              return val 
            }
          },
          title: {
            text: "Contribution Qty",
            showAlways: true,
            floating: true,
            style:{
              fontWeight:"100"
            },

          },
        },
        ,
      

      ],

    annotations: {
    yaxis: [{
      y: 0, // Place the annotation at zero
      borderColor: '#939598',
      label: {
        text: '',
        style: {
          color: '#939598',
          background: '#000'
        }
      }
    }]
  }  });
  setplotdata2([
    { name: "Contribution Qty", data:getResponse.data.contribution?.marketing_variable.map((item, index) => {
      return getResponse.data.contribution.contribution[index] !== null ? getResponse.data.contribution.contribution[index].toFixed(0) : ""
      
    }) },

  ])
  setoptions3(
    {
      labels: ['Base', 'Marketing'],
      title: {
        text: '',
        align: 'center',
        style:{
          fontWeight:"100",
       color: "#43474B"
      }},

      plotOptions: {
        pie: {
          donut: {
            size: '20%',
          },
        },

      },
      colors: ['#007bff', '#f4d03f'],
      chart: {
        id: `Download-Base vs Marketing chart`,
        type: "pie",
        toolbar: {
          show: true,
        },
      },

      dataLabels: {
        enabled: true,
        style: {
         
        },
        formatter: function (val) {
          return val + '%'
        }
      },





    });

  setplotdata3([{ name: "Base", data: 83 },
  { name: "Marketing", data: 17 }

  ])

  //console.log(getResponse.data.contribution?.contribution_pc.map((it)=>{return it.toFixed(0)}))
  setoptions4(
    {
      chart: {
        id: `Download-Contribution by Variable`,
        type: "bar",

        toolbar: {
          show: true,
        },
      },
      grid:{
        show:false
      },
      title: {
        text: 'Contribution-by Variable',
        align: 'center',
        style:{
          fontWeight:"100",
       
      }},
  
      colors: ['#F79548'],
      dataLabels: {
        enabled: true,
        style: {
      
        },
        formatter: function (val) {
          return val + '%'
        }
      },
      plotOptions:{
        bar:{dataLabels: {
          position: 'top',
      },
      },},
      xaxis: {
        categories: getResponse.data.contribution?.marketing_variable,
        title: {
          text: 'Variable',
          style: {
            fontWeight:"100"
          },
        },

        labels: {
          style: {
 fontWeight:"100",
 fontSize:"0.8em"
          },
          

          formatter: function (val) {
            return val
          }
        },
      },
      yaxis: [
        {
          seriesName: 'Contribution Percentage',
          opposite: false,
          axisBorder: {
            show: true // Ensures Y-axis line is visible
          },

          labels: {
            style: {
              fontWeight:"100"

            },
            formatter: function (val) {
              return val + '%'
            }
          },
          title: {
            text: "Contribution Percentage",
            showAlways: true,
            floating: true,
            style: {
              fontWeight:"100"
            },

          },
          
        },
        ,
        // {
        //   seriesName: 'Predicted Sales',
        //   opposite: true,
        //   title: {
        //     text: "Predicted Sales",
        //     showAlways: true,
        //     floating: true, 
        //      style:{
        //     color:"#FFC107"
        //   },

        //   },


        // },
      ],
      annotations: {
        yaxis: [{
          y: 0, // Place the annotation at zero
          borderColor: '#939598',
          label: {
            text: '',
            style: {
              color: '#939598',
              background: '#000'
            }
          }
        }]
      } 
    });
  setplotdata4([{ name: "Contribution Percentage", data: getResponse.data.contribution?.contribution_pc.map((it) => { return Number(it.toFixed(0)) }) },

  ])
  setoptions5(
    {
      chart: {
        id: `Download-ROI by Variable`,
        type: "bar",

        toolbar: {
          show: true,
        },
      },
      grid:{
        show:false
      },
      title: {
        text: 'ROI-by Variable',
        align: 'center',
        style:{fontWeight:"100"}
      },
     
      colors: ['#0068A6'],
      dataLabels: {
        enabled: true,
        style: {
        
          fontWeight: 900
        },
        formatter: function (val) {
          return val + '%'
        }
      },
      plotOptions:{
        bar:{dataLabels: {
          position: 'top',
      },
      },},
      xaxis: {
        categories: getResponse.data.contribution?.marketing_variable,
        title: {
          text: 'Variable',
          style:{
            fontWeight:"100",
           
          },
          offsetY:-10
        },
        labels: {
          style: {
 fontWeight:"100",fontSize:"0.8em"
 
          },
          
          formatter: function (val) {
            return val
          }
        },
      },
      yaxis: [
        {
          axisBorder: {
            show: true // Ensures Y-axis line is visible
          },
          seriesName: 'ROI',
          opposite: false,
          labels: {
            style: {
           

            },
            formatter: function (val) {
              return val + '%'
            }
          },
          title: {
            text: "ROI",
            showAlways: true,
            floating: true,
            style: {
             fontWeight:"100"
            },

          },
        },
        ,
        // {
        //   seriesName: 'Predicted Sales',
        //   opposite: true,
        //   title: {
        //     text: "Predicted Sales",
        //     showAlways: true,
        //     floating: true, 
        //      style:{
        //     color:"#FFC107"
        //   },

        //   },


        // },
      ],
      annotations: {
        yaxis: [{
          y: 0, // Place the annotation at zero
          borderColor: '#939598',
          label: {
            text: '',
            style: {
              color: '#939598',
              background: '#000'
            }
          }
        }]
      } 

    });
  setplotdata5([{ name: "Contribution Percentage", data: getResponse.data.roi?.roi.map((it) => { return it.toFixed(0) }) },

  ])


  // setTimeout(() => {
  //   document.getElementById("SimulatorInputs").click()
  // }, 1000)
  scrollToTop();

  //settablegraphdataset(getResponse.data);
} else {
  dispatch(
    getNotification({
      message: "There is no data for selected options",
      type: "default",
    })
  );
}
          },3000)
            //(getResponse.data)
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      } else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
        });
      }, 1000);
    }


  };
  function formatDate() {
    // Create a Date object using the specified date string
    const date = new Date();

    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    // Return the date in "YYYY-MM-DD" format
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  const updatescenario = async (update) => {
    if (UserService.isLoggedIn()) {
      try {
        const updatedDataset = [...sampledataset];
        //console.log(updatedDataset[0].frozen)
        updatedDataset.map((item) => {
          if (item.frozen === 0) {
            if (item.allow_decimal === 0) {
              item.subtotal = item.month_data.reduce((acc, value) => acc + parseInt(value.value), 0); // Use parseInt for integer values
            }
          } else {
            item.subtotal = item.month_data.reduce((acc, value) => acc + parseFloat(value.value), 0); // Use parseFloat for decimal values
          }


        })
        setoriginalset(updatedDataset);
        let arr = [];
        setedit(arr);
        //console.log(formatDate())

        const requestData = {
          scenario_name: displaynames.scenarioname === "Base Scenario" ? newscenarionamegiven : displaynames.scenarioname,
          scenario_timestamp: formatDate(),
          user_id: "admin",
          brand: displaynames.brand,
          f_year: displaynames.year,
          model_id: 1,
          dataset: originalset,
          override: displaynames.scenarioname === "Base Scenario" ? "No" : "Yes"
        };

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA2}/updatescenario`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: requestData
        };
        const getResponse = await axios(config);

        if (getResponse.status === 200) {
          fetchscenariooptions();
          // predictchart(newscenarionamegiven);
          document.getElementById("closemodal").click()
          setdisplaynames({ ...displaynames, scenarioname: newscenarionamegiven });
          fetchscenariooptions();
          setselectedscenarioname(newscenarionamegiven)
          dispatch(
            getNotification({
              message: "Scenario has been saved successfully",
              type: "success",
            })
          );
        }
        setTimeout(() => {
          if (update) {
            setselectedbrand(displaynames.brand)
            setselectedscenarioname(displaynames.scenarioname)
            setselectedzone(displaynames.zone)
            setselectedyear(displaynames.year)
            setselectedscenarioid(displaynames.id)

          }
          else {
            setselectedbrand(displaynames.brand)
            setselectedscenarioname(newscenarionamegiven)
            setselectedzone(displaynames.zone)
            setselectedyear(displaynames.year)
            setselectedscenarioid(displaynames.id)

          }

        }, 2000);

      }
      catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          if (err.response.data.detail === `scenario name: ${newscenarionamegiven} already present in the DB, please try again with new scenario name`) {
            dispatch(
              getNotification({
                message: "Please provide unique scenario name",
                type: "default",
              })
            );
          }
          else {
            dispatch(
              getNotification({
                message: "There is some server error.Please try again later!",
                type: "default",
              })
            );
          }
        }
        else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }

      }

    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
        });
      }, 1000);
    }





  };
  const savescenario = async (update) => {
    if (UserService.isLoggedIn()) {
      try {
        const updatedDataset = [...sampledataset];
        //console.log(updatedDataset[0].frozen)
        updatedDataset.map((item) => {
          if (item.frozen === 0) {
            if (item.allow_decimal === 0) {
              item.subtotal = item.month_data.reduce((acc, value) => acc + parseInt(value.value), 0); // Use parseInt for integer values
            }
          } else {
            item.subtotal = item.month_data.reduce((acc, value) => acc + parseFloat(value.value), 0); // Use parseFloat for decimal values
          }


        })

        setoriginalset(updatedDataset);
        let arr = [];
        setedit(arr);
        //console.log(formatDate())

        const requestData = {
          scenario_name: displaynames.scenarioname === "Base Scenario" ? newscenarionamegiven : displaynames.scenarioname,
          scenario_timestamp: formatDate(),
          user_id: "admin",
          brand: displaynames.brand,
          f_year: displaynames.year,
          model_id: 1,
          dataset: originalset,
          override: displaynames.scenarioname === "Base Scenario" ? "No" : "Yes"
        };

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA2}/savescenario`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: requestData
        };
        const getResponse = await axios(config);

        if (getResponse.status === 200) {
          fetchscenariooptions();
          // predictchart(newscenarionamegiven);
          document.getElementById("closemodal").click()
          setdisplaynames({ ...displaynames, scenarioname: newscenarionamegiven });
          fetchscenariooptions();
          setselectedscenarioname(newscenarionamegiven)
          dispatch(
            getNotification({
              message: "Scenario has been saved successfully",
              type: "success",
            })
          );
        }
        setTimeout(() => {
          if (update) {

            setselectedbrand(displaynames.brand)
            setselectedscenarioname(displaynames.scenarioname)
            setselectedzone(displaynames.zone)
            setselectedyear(displaynames.year)
            setselectedscenarioid(displaynames.id)

          }
          else {
            setselectedbrand(displaynames.brand)
            setselectedscenarioname(newscenarionamegiven)
            setselectedzone(displaynames.zone)
            setselectedyear(displaynames.year)
            setselectedscenarioid(displaynames.id)

          }

        }, 2000);

      }
      catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          if (err.response.data.detail === `scenario name: ${newscenarionamegiven} already present in the DB, please try again with new scenario name`) {
            dispatch(
              getNotification({
                message: "Please provide unique scenario name",
                type: "default",
              })
            );
          }
          else {
            dispatch(
              getNotification({
                message: "There is some server error.Please try again later!",
                type: "default",
              })
            );
          }
        }
        else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }

      }

    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/simulator`,
        });
      }, 1000);
    }





  };
  const updatedataset = (variableIndex) => {

    const updatedDataset = [...sampledataset];

    if (updatedDataset[variableIndex].frozen === 0) {
      updatedDataset[variableIndex].subtotal = updatedDataset[
        variableIndex
      ].month_data.reduce((acc, value) => acc + value.value, 0);
    }

    setoriginalset(updatedDataset);

    let arr = [];
    setedit(arr);
    dispatch(getNotification({
      message: `Value updated for ${updatedDataset[variableIndex].attribute}`,
      type: "Success"
    }))

  }
  const updatedatasetdecimal = (variableIndex) => {
    const updatedDataset = [...sampledataset];

    updatedDataset[variableIndex].subtotal = updatedDataset[
      variableIndex
    ].month_data.reduce((acc, value) => acc + parseFloat(value.value), 0);

    setoriginalset(updatedDataset);

    let arr = [];
    setedit(arr);
    dispatch(getNotification({
      message: `Value updated for ${updatedDataset[variableIndex].attribute}`,
      type: "Success"
    }))

  }
  const uploadnewscenariofile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
       // Log the first selected file
      setnewscenariofile(e.target.files[0]); // Store the first file in state
      setviewscenariobtn(true)
    } 
  };
  return (
    <>
    <style>
      {`
    .apexcharts {
  font-size: inherit !important; 
}


.apexcharts-datalabel {
  font-size: 10px !important;  
}


.apexcharts-xaxis-label {
  font-size: 11px !important; 
}
.apexcharts-yaxis-label {
  font-size: 0.8em !important;  
} `}
      
    </style>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title" id="exampleModalLabel">
                Save Scenario
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <label>Please enter Scenario Name: </label>
              <input
                type="text"
                id="scenarionamebox"
                className="form-control"
                value={newscenarionamegiven}
                onChange={(e) => setnewscenarionamegiven(e.target.value)}
              />

              <br />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                  if (newscenarionamegiven === "" || newscenarionamegiven === "Base Scenario") {
                    document.getElementById("scenarionamebox").focus();
                  } else {
                    savescenario();

                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="">

        <div className="bgpages px-2 py-3 " >

          <div style={{ backgroundColor: "" }} className="row my-2 mx-2">
            {/* <div className="col-sm-10 align-self-center">
              <h5 className='orangetheme' style={{ fontWeight: "500" }} >SIMULATOR</h5>
              <p className="text-light" >
                Dissects expenditure trends among multiple brands in a specific source of expenditure over a defined time frame.
              </p>
            </div> */}
            {/* <div className="col-sm-2">
              {resultscreen && displaynames
             
                &&
                <div className="rounded-4  m-1 p-2 card" style={{ backgroundColor: "#FAFFE7", border: "2px solid red", width: "300px", fontSize: "14.5px" }}>
                  <span className=" orangetheme" ><b>User Selections</b>
                    <hr className="m-1" style={{ border: "1px solid #f15e23" }} />
                  </span>
                  <table className="mx-3">
                    <thead>
                      <tr>
                        <th style={{ color: "darkblue" }}>Parameter</th>
                        <th className="text-right " style={{ color: "darkblue" }}>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="" className="" style={{ color: "darkblue" }} >Brand</td>
                        <td className="text-right">{displaynames.brand}</td>

                      </tr>
                      <tr>
                        <td scope="" className="" style={{ color: "darkblue" }}>Scenario</td>
                        <td className="text-right">{displaynames.scenarioname}</td>
                      </tr>

                      <tr>
                        <td scope="" className="" style={{ color: "darkblue" }}>Zone</td>
                        <td className="text-right">{displaynames.zone}</td>
                      </tr>
                      <tr>
                        <td scope="" className="" style={{ color: "darkblue" }}>Year</td>
                        <td className="text-right">{displaynames.year}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>}
            </div> */}
          </div>
          <div className="rounded-5 card mx-1 mb-3" style={{ backgroundColor: "white", border: "1px solid #E4CCFF" }}>

            <div style={{ fontWeight: "500", backgroundColor: "#EAF7FF" }} className="headingcolor p-3 rounded-5" >
              {resultscreen2 ? <div>Metrics Customizer
                <span className="float-end">
                  {/* <button className="btn btn-outline-secondary btn-sm m-1 position-relative" onClick={()=>{setresultscreen(false)}}>Compare</button> */}
                  {/* <button className="btn btn-outline-secondary btn-sm m-1"
         data-toggle="modal"
         data-target="#exampleModal" onClick={()=>savescenario()}>Save Scenario</button> */}



                  {resultscreen2 && (
                    <>
                      <button
                        className={"btn btn-outline-danger  btn-sm m-1"}
                        onClick={() => {
                          setresultscreen(false)
                          setresultscreen2(false)
                          setscenarionewoldscreen("select")
                          setmarket("")
                          //setresultscreen(false)
                          //setoriginalset([])
                          //setsampledataset([])
                          setdisplaynames({})
                          setselectedscenarioname("")
setnewscenariofile("")
                        }}
                      >
                        Reset
                      </button>
                      {/* {displaynames.scenarioname !== "Base Scenario" && <button className="btn btn-sm btn-danger m-1 noborder"
                        onClick={() => {
                          // savescenario();
                          updatescenario(true);
                        }}>
                        Update Scenario
                      </button>} */}
                      {displaynames.scenarioname !== "Base Scenario" && <button className="btn btn-sm btn-danger noborder"
                        onClick={() => {
                          // savescenario();
                          predictchart()
                        }}>
                        Simulate
                      </button>}

                      {/* {displaynames.scenarioname === "Base Scenario" &&
                        <button className="btn btn-sm btn-danger noborder"
                          data-toggle={displaynames.scenarioname === "Base Scenario" ? "modal" : ""}
                          data-target="#exampleModal"
                          onClick={() => {
                            //savescenario();
                          }}> Save Scenario
                        </button>} */}
                        </>
                  )}

                </span>
              </div> : "SIMULATOR"}

            </div>
            <div className="row px-3" >
              {loader ? (
                <div
                  className="row d-flex  justify-content-center align-items-center "
                  style={{ height: "60vh" }}
                >
                  <Loader
                    type="box-rectangular"
                    bgColor={"#007bff"}
                    title={"Fetching data..."}
                    color={"#000000"}
                    size={75}
                  />
                </div>
              ) : resultscreen2 ?
                <>
                  <div className="d-flex justify-content-around py-2 px-3 shadow" style={{ backgroundColor: "#fafafa" }} >


                    <div className="my-2">
                      <label>Brand:<span className="text-danger">*</span></label>

                      <select

                        value={selectedbrand}
                        className="form-select "
                        onChange={(e) => setselectedbrand(e.target.value)}
                      >

                        {brandoptions?.map((option) => {
                          return <option>{option.brand}</option>;
                        })}

                      </select>
                    </div>
                    <div className="my-2">
                      <label>Market:<span className="text-danger">*</span></label>
                      <select className="form-select"
                        placeholder="Select market"
                        options={marketoptions}
                        onChange={(e) => {
                          setmarket(e.target.value);
                        }}
                        value={market}
                      >
                        {marketoptions?.map((item)=>{
                          return <option>{item}</option>
                        })}
                      </select>





                    </div>
                    <div>
                      <label>Scenario:<span className="text-danger">*</span></label>
                    
                      

                        <select
                          className="form-select"
                          value={selectedscenarioname}
                          onChange={(e) => {
                            setselectedscenarioname(e.target.value);
                            console.log(e.target.value)
                            let arr = scenariooptions.filter((it) => { return e.target.value === it.scenario_name })
                            setselectedscenarioid(arr[0].id)
                            setselectedscenarionametimestamp(arr[0].updated_dt)
                          }}
                        >
                       
                          {scenariooptions?.map((option) => {
                            return (
                              <option

                              >
                                {option.scenario_name}
                              </option>
                            );
                          })}
                        </select>
                    </div><div>
                   
                   
                    </div>
                    {/* <div>

                      {" "}
                      <label htmlFor="textarea">Year: </label>

                      height={400} className="form-select" value={selectedyear} onChange={(e) => setselectedyear(e.target.value)}>
                        <option>2021-22</option>
                        <option>2022-23</option>
                        <option>2023-24</option>
                      </select>


                    </div> */}
                    {/* <div className="form-check ">
                      <input
                        className="form-check-input "
                        type="radio"
                        checked
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onClick={() => { }}
                      />
                      <label
                        className="form-check-label selected"
                        for="flexRadioDefault2"
                      >
                        Annual
                      </label>{" "}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        disabled
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        onClick={() => { }}
                      />
                      <label className="form-check-label" for="flexRadioDefault1">
                        Quarterly
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        disabled
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onClick={() => { }}
                      />
                      <label className="form-check-label" for="flexRadioDefault2">
                        Semi-Annual
                      </label>{" "}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        disabled
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onClick={() => { }}
                      />
                      <label className="form-check-label" for="flexRadioDefault2">
                        Define
                      </label>{" "}
                    </div> */}
                    <div>
                      {/* <button
                        className="btn btn-primary my-2"
                        onClick={() => {
                          fetchdatasettable();
                        }}
                      >
                        Submit
                      </button> */}
                    </div>
                  </div>




                  <div className="col-sm-12 p-5 mt-3">
                    <div className="" id="">
                      {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne" >
                          <button id="SimulatorInputs" className="accordion-button" style={{ backgroundColor: "#cfe2ff" }} type="button" data-toggle={resultscreen2 ? "collapse" : ""} data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Simulator Inputs
                          </button>
                        </h2>
                        <div id="collapseOne" className="show collapse" aria-labelledby="headingOne" >
                          <div className="accordion-body">
                            <table className="table table-sm my-3 table-striped shadow table-bordered table-responsive-md" style={{ fontSize: "1.7vh" }}>


                              <thead>
                                <th>Attribute</th>

                                {monthlist.slice(0, sampledataset[0].month_data?.length).map((it) => {
                                  return <th>{it}</th>
                                })}

                                <th>Subtotal</th>
                                <th></th>
                              </thead>
                              <tbody>
                                {sampledataset?.map((item, variableIndex) => {
                                  return (
                                    <tr key={`outside${variableIndex}`}>
                                      <td>{item.attribute}</td>
                                      {item.month_data?.map((it, valueIndex) => {
                                        return (
                                          <td>
                                            {edit[variableIndex] ? (
                                              <div>
                                                <input
                                                  className={it.frozen ? "noborder" : ""}
                                                  style={{ width: "60px" }}
                                                  value={it.value}
                                                  onChange={(e) => {

                                                    item.allow_decimal === 0 ?
                                                      changeelements(
                                                        variableIndex,
                                                        valueIndex,
                                                        e
                                                      ) : changeelementsdecimal(
                                                        variableIndex,
                                                        valueIndex,
                                                        e
                                                      );
                                                  }}
                                                />
                                                <button
                                                  className="btn btn-sm  my-2"

                                                  onClick={() => {
                                                    togglelock(
                                                      variableIndex,
                                                      valueIndex
                                                    );
                                                  }}
                                                >
                                                  {it.frozen === 0
                                                    ? <i class="fa fa-unlock text-warning"></i>
                                                    : <i class="fa fa-lock text-secondary" ></i>}
                                                </button>

                                              </div>
                                            ) : (

                                              Number.isInteger(it.value) ? Number(it.value).toLocaleString("en-IN") :
                                                parseFloat(it.value).toFixed(2)
                                            )}
                                          </td>
                                        );
                                      })}
                                      <td>
                                        {edit[variableIndex] ? (
                                          <div>
                                            <input
                                              className={item.frozen ? "noborder ml-1" : ""}
                                              style={{ width: "60px" }}
                                              defaultValue={item.subtotal}
                                              value={item.subtotal}
                                              onChange={(e) => {
                                                item.allow_decimal === 0 ?
                                                  changesubtotal(e, variableIndex) : changesubtotaldecimal(e, variableIndex);
                                              }}
                                            />
                                            <button
                                              className="btn btn-sm my-2"
                                              onClick={() => {
                                                togglelocksubtotal(variableIndex);
                                              }}
                                            >
                                              {item.frozen === 0
                                                ? <i class="fa fa-unlock text-warning"></i>
                                                : <i class="fa fa-lock text-secondary" ></i>}
                                            </button>
                                          </div>
                                        ) : (
                                          Number.isInteger(item.subtotal) ? Number(item.subtotal).toLocaleString("en-IN") :
                                            parseFloat(item.subtotal).toFixed(2)

                                        )}
                                      </td>
                                      <td>
                                        {edit[variableIndex] ? (
                                          <div className="d-flex">

                                            <button
                                              className="btn btn-sm m-1 checktickbtn"

                                              onClick={() => { item.allow_decimal === 0 ? updatedataset(variableIndex) : updatedatasetdecimal(variableIndex) }}
                                            >
                                              <i class="fa fa-check" style={{ color: "green", fontSize: "15px" }}></i>
                                            </button>
                                            <button
                                              className="btn btn-sm   m-1"
                                              onClick={() => {
                                                clearAll(variableIndex);
                                              }}
                                            >
                                              <i class='fas fa-trash-alt' style={{ color: "red", fontSize: "15px" }} ></i>
                                            </button>
                                            <button
                                              className="btn btn-sm  m-1"
                                              onClick={() => {
                                                let arr = [];
                                                setedit(arr);
                                                handlecancel(variableIndex);
                                              }}
                                            >
                                              <i class="fa fa-arrow-circle-left text-primary" style={{ fontSize: "15px" }}></i>
                                            </button>

                                          </div>
                                        ) : (
                                          <button
                                            className="btn"

                                            onClick={() => {
                                              //handlecancel();
                                              let arr = [];
                                              arr[variableIndex] = true;
                                              setedit(arr);
                                            }}
                                          >
                                            <i class='fas fa-edit text-primary' ></i>
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> */}
                      {resultscreen2 &&
                        <div className=" my-2">
                          {/* <h2 className=" " id="headingTwo">
                            <button className="accordion-button collapsed orangetheme2"
                              type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Simulator Output/Result
                            </button>
                          </h2> */}
                          <div id="" className="" aria-labelledby="" >
                            <div className="">
                              {
                                loader ?
                                  <div
                                    className="d-flex  justify-content-center align-items-center "
                                    style={{ height: "10vh" }}
                                  >
                                    <Loader
                                      type="box-rectangular"
                                      bgColor={"#007bff"}
                                      title={"Fetching data..."}
                                      color={"#000000"}
                                      size={75}
                                    /></div> :

                                  <div>
                                    <div className="row mx-auto">

                                    <h5 className="my-4 orangetheme">Simulated Data</h5>
                                      <div className="col-sm card">
                                       
                                      <Chart
                                          align="center"
                                          options={options1}
                                          series={plotdata1}
                                          type="bar"
                                          height={400}
                                          width={'80%'}
                                        />
                                          </div>

                                    
                                    </div>
                                    <div className="row my-4  ">
                                    <div className="col-sm card mx-3">
                                      <Chart
                                          align="center"
                                          options={options2}
                                          series={plotdata2}
                                          type="bar"
                                          height={400}
                                          width={'80%'}
                                        />
                                     
                                      </div>

                                      <div className="col-sm card mx-3">
                                     
                           <span className="text-center">Base vs Marketing</span>
                                       
                                        {plotdata3.length > 0 && (

                                          <Chart 
                                          className="my-auto"
                                            align="center"
                                            type="donut"
                                            height={300}
                                            options={options3}
                                            series={plotdata3.map((item) => item.data)}
                                          
                                            
                                          />

                                        )}
                                      </div></div>
                                    <div className="row">
                                    <div className="card col-sm mx-3 p-2" id="" >

{plotdata4.length > 0 && (
  <Chart
    align="center"
    options={options4}
    series={plotdata4}
    type="bar"
    height={400}
    width={'90%'}
  />
  
)}
</div>
<div className="card col-sm mx-3 p-2">

{plotdata5.length > 0 && (
  <Chart
    align="center"
    options={options5}
    series={plotdata5}
    type="bar"
    height={400}
    width={'90%'}
  />
)}
</div></div>
                                  </div>}
                            </div>
                          </div>


                        </div>}
                    </div>
                  </div>

                </> :
                <div className="row">
                  <div className="col-sm" id="main1">
                    {brandoptions.length > 0 && scenariooptions.length > 0 ?

                      <div className="">
                        <div className=" px-3 pt-2 " >

                          <div>
                            <label >Brand:<span className="text-danger">*</span></label>

                            <select
                              value={selectedbrand}
                              className="form-select mb-2"
                              onChange={(e) => setselectedbrand(e.target.value)}
                            >
                              {brandoptions?.map((option) => {
                                return <option>{option.brand}</option>;
                              })}
                            </select>
                            <div className="my-2">
                              <label>Market:<span className="text-danger">*</span></label>
                              <select className="form-select"
                        placeholder="Select market"
                        options={marketoptions}
                        onChange={(e) => {
                          setmarket(e.target.value);
                        }}
                        value={market}
                      >
                        {marketoptions?.map((item)=>{
                          return <option>{item}</option>
                        })}
                      </select>





                            </div>
                            <label>Scenario:<span className="text-danger">*</span></label>
                            <select className="form-select" value={scenarionewoldscreen} onChange={(e) => {setscenarionewoldscreen(e.target.value);setviewscenariodatatable(false);setviewscenariobtn(false)}}>
                             <option value="select">Select</option>
                              <option value="old">Old Scenarios</option>
                              <option value="new">New Scenario</option>
                            </select>
                            {scenarionewoldscreen === "old" &&
                              <select
                                value={selectedscenarioname}
                                className="form-select my-2"
                                onChange={(e) => {
                                 if(e.target.value!=="Select"){
                                  setviewscenariodatatable(false)
                                  setselectedscenarioname(e.target.value);
                                  console.log(e.target.value)
                                  let arr = scenariooptions.filter((it) => { return e.target.value === it.scenario_name })
                                  setselectedscenarioid(arr[0].id)
                                  
                                  setselectedscenarionametimestamp(arr[0].updated_dt)
                                 }
                                }}
                              >
                                <option>Select</option>
                                {scenariooptions?.map((option) => {
                                  return (
                                    <option

                                    >
                                      {option.scenario_name}
                                    </option>
                                  );
                                })}
                              </select>}
                            {scenarionewoldscreen === "new" &&
                              <div className="mt-3">
                                <div className="d-flex justify-items-between">
                                  <div className="form-check mx-2  mb-2">
                                    <input
                                      className="form-check-input "
                                      type="radio"
                                                                          name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      onClick={() => { }}
                                    />
                                    <label
                                      className="form-check-label selected"
                                      for="flexRadioDefault2"
                                    >
                                      Yearly
                                    </label>{" "}
                                  </div>
                                  <div className="form-check mx-2 mb-2">
                                    <input
                                      className="form-check-input"

                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      onClick={() => { }}
                                    />
                                    <label className="form-check-label" for="flexRadioDefault1">
                                      Quarterly
                                    </label>
                                  </div>
                                  <div className="form-check mx-2 mb-2">
                                    <input
                                      className="form-check-input"

                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      onClick={() => { }}
                                    />
                                    <label className="form-check-label" for="flexRadioDefault2">
                                      Half-Yearly
                                    </label>{" "}
                                  </div>
                                  <div className="form-check mx-2 mb-2">
                                    <input
                                      className="form-check-input"

                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      onClick={() => { }}
                                    />
                                    <label className="form-check-label" for="flexRadioDefault2">
                                      Monthly
                                    </label>{" "}
                                  </div>
                                  <div className="form-check mx-2 ">
                                    <input
                                      className="form-check-input"

                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      onClick={() => { }}
                                    />
                                    <label className="form-check-label" for="flexRadioDefault2">
                                      Custom
                                    </label>{" "}
                                  </div></div>
                                <a className='d-flex flex-row-reverse mb-1' onClick={() => { downloadSampleFile() }}>Sample File</a>
                                <input className="form-control" type="file" onChange={(e) => { uploadnewscenariofile(e) }} />
                               
                                 
                              </div>}
                              { scenarionewoldscreen==="old" && <div className="mt-2">
                                  <button className="btn-sm btn-primary btn" onClick={() =>{if(viewscenariodatatable){setviewscenariodatatable(false)
document.getElementById("main2").classList.remove("col-sm-10");
document.getElementById("main1").classList.remove("col-sm-2");
 document.getElementById("main1").classList.add("col-sm");

document.getElementById("main2").classList.add("col-sm");


                                    }
                                    else{fetchdatasettable();}}}>
                                    {!viewscenariodatatable ? "View Scenario Data" : "Hide"}
                                    </button></div> }
                                    {viewscenariobtn && scenarionewoldscreen==="new" && <div className="mt-2">
                                  <button className="btn-sm btn-primary btn" onClick={() =>{if(viewscenariodatatable){setviewscenariodatatable(false);document.getElementById("main2").classList.remove("col-sm-10");
document.getElementById("main1").classList.remove("col-sm-2");
 document.getElementById("main1").classList.add("col-sm");

document.getElementById("main2").classList.add("col-sm"); }else{setviewscenariodatatable(true)}}}>
                                  {!viewscenariodatatable ? "View Scenario Data" : "Hide"}
                                    </button></div> }
                              <div className="d-flex flex-row-reverse my-2"> <button
                              className=" btn btn-primary "
                              onClick={() => {
                              // fetchdatasettable()
                              predictchart()
                              }}
                            >
                              Simulate
                            </button></div>
                            {/* <label>Zone:</label>
                            <select className="form-select  mb-2" value={selectedzone} onChange={(e) => setselectedzone(e.target.value)}>
                              <option selected>National</option>
                              <option>East</option>
                              <option>West</option>
                              <option>North</option>
                              <option>South</option>
                              <option>Campaign Proxy</option>
                            </select> */}
                            {/* <div>
                              {" "}
                              <label htmlFor="textarea">Year: </label>
                              <div className="d-flex justify-items-between">
                                <div className="form-check mx-2  mb-2">
                                  <input
                                    className="form-check-input "
                                    type="radio"
                                    checked
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    onClick={() => { }}
                                  />
                                  <label
                                    className="form-check-label selected"
                                    for="flexRadioDefault2"
                                  >
                                    Annual
                                  </label>{" "}
                                </div>
                                <div className="form-check mx-2 mb-2">
                                  <input
                                    className="form-check-input"
                                    disabled
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    onClick={() => { }}
                                  />
                                  <label className="form-check-label" for="flexRadioDefault1">
                                    Quarterly
                                  </label>
                                </div>
                                <div className="form-check mx-2 mb-2">
                                  <input
                                    className="form-check-input"
                                    disabled
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    onClick={() => { }}
                                  />
                                  <label className="form-check-label" for="flexRadioDefault2">
                                    Semi-Annual
                                  </label>{" "}
                                </div>
                                <div className="form-check mx-2 mb-2">
                                  <input
                                    className="form-check-input"
                                    disabled
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    onClick={() => { }}
                                  />
                                  <label className="form-check-label" for="flexRadioDefault2">
                                    Define
                                  </label>{" "}
                                </div>  </div>
                              <div className="">

                                <select className="form-select  mb-2" value={selectedyear} onChange={(e) => setselectedyear(e.target.value)}>
                                  <option>2021-22</option>
                                  <option>2022-23</option>
                                  <option>2023-24</option>
                                </select>
                              </div>

                            </div> */}

                          </div>
                        </div>



                      </div> : "Loading..."}
                  </div>
                  <div className="col-sm" id="main2">
                 {!viewscenariodatatable ? 
                    <img src="../Assets/Images/image1.png" className="" align="right" style={{ width: "500px" }} />
                 :
                 
                   <div className="my-3 ml-3">
                                  <table className="table table-sm rounded-lg table-striped shadow table-bordered table-responsive-sm " style={{fontSize:"1.6vh"}}>
                            <thead>
                              <th>Attribute</th>

                              {monthlist.slice(0, sampledataset[0].month_data?.length).map((it) => {
                                return <th>{it}</th>
                              })}

                              <th>Subtotal</th>
                              <th></th>
                            </thead>
                            <tbody>
                              {sampledataset?.map((item, variableIndex) => {
                                return (
                                  <tr key={`outside${variableIndex}`}>
                                    <td>{item.attribute}</td>
                                    {item.month_data?.map((it, valueIndex) => {
                                      return (
                                        <td>
                                          {edit[variableIndex] ? (
                                            <div>
                                              <input
                                                className={it.frozen ? "noborder" : ""}
                                                style={{ width: "60px" }}
                                                value={it.value}
                                                onChange={(e) => {

                                                  item.allow_decimal === 0 ?
                                                    changeelements(
                                                      variableIndex,
                                                      valueIndex,
                                                      e
                                                    ) : changeelementsdecimal(
                                                      variableIndex,
                                                      valueIndex,
                                                      e
                                                    );
                                                }}
                                              />
                                              <button
                                                className="btn btn-sm  my-2"

                                                onClick={() => {
                                                  togglelock(
                                                    variableIndex,
                                                    valueIndex
                                                  );
                                                }}
                                              >
                                                {it.frozen === 0
                                                  ? <i class="fa fa-unlock text-warning"></i>
                                                  : <i class="fa fa-lock text-secondary" ></i>}
                                              </button>

                                            </div>
                                          ) : (

                                            Number.isInteger(it.value) ? Number(it.value).toLocaleString("en-IN") :
                                              parseFloat(it.value).toFixed(2)
                                          )}
                                        </td>
                                      );
                                    })}
                                    <td>
                                      {edit[variableIndex] ? (
                                        <div>
                                          <input
                                            className={item.frozen ? "noborder ml-1" : ""}
                                            style={{ width: "60px" }}
                                            defaultValue={item.subtotal}
                                            value={item.subtotal}
                                            onChange={(e) => {
                                              item.allow_decimal === 0 ?
                                                changesubtotal(e, variableIndex) : changesubtotaldecimal(e, variableIndex);
                                            }}
                                          />
                                          <button
                                            className="btn btn-sm my-2"
                                            onClick={() => {
                                              togglelocksubtotal(variableIndex);
                                            }}
                                          >
                                            {item.frozen === 0
                                              ? <i class="fa fa-unlock text-warning"></i>
                                              : <i class="fa fa-lock text-secondary" ></i>}
                                          </button>
                                        </div>
                                      ) : (
                                        Number.isInteger(item.subtotal) ? Number(item.subtotal).toLocaleString("en-IN") :
                                          parseFloat(item.subtotal).toFixed(2)

                                      )}
                                    </td>
                                    <td>
                                      {edit[variableIndex] ? (
                                        <div className="d-flex">

                                          <button
                                            className="btn btn-sm m-1 checktickbtn"

                                            onClick={() => { item.allow_decimal === 0 ? updatedataset(variableIndex) : updatedatasetdecimal(variableIndex) }}
                                          >
                                            <i class="fa fa-check" style={{ color: "green", fontSize: "15px" }}></i>
                                          </button>
                                          <button
                                            className="btn btn-sm   m-1"
                                            onClick={() => {
                                              clearAll(variableIndex);
                                            }}
                                          >
                                            <i class='fas fa-trash-alt' style={{ color: "red", fontSize: "15px" }} ></i>
                                          </button>
                                          <button
                                            className="btn btn-sm  m-1"
                                            onClick={() => {
                                              let arr = [];
                                              setedit(arr);
                                              handlecancel(variableIndex);
                                            }}
                                          >
                                            <i class="fa fa-arrow-circle-left text-primary" style={{ fontSize: "15px" }}></i>
                                          </button>

                                        </div>
                                      ) : (
                                        <button
                                          className="btn"

                                          onClick={() => {
                                            //handlecancel();
                                            let arr = [];
                                            arr[variableIndex] = true;
                                            setedit(arr);
                                          }}
                                        >
                                          <i class='fas fa-edit text-primary' ></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                                  </div>}
                   
           </div>
                              
                  <div id="" className="p-4  ">
                    <div>
                   
                    </div>
                  </div>





                </div>}
            </div>
          </div>




        </div>




      </div>

      <div className='' >
        <FooterPages />
      </div>
    </>
  );
}

export default Simulator;
