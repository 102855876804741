import React from "react";
import quationlogoBg0 from "./Images/quationlogoBg0.png";
import { useState, useEffect, useRef } from "react";
import Loader from "react-js-loader";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { useDispatch } from "react-redux";
import UserService from "../services/UserService";
import mmxlogom from "./Images/mmxlogom.png";
import logosocialmedia from "./Images/logossocialmodeia.png";
import getNotification from "../Redux/Action/action";
import axios from "axios";

import FooterPages from "./FooterPages";
import Chart from "react-apexcharts";
const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_UPLOAD_DATA2 } = process.env;
function ModelPerformance() {
  const dispatch = useDispatch();
const [tablescreen,settablescreen]=useState(false)
  const [loader, setloader] = useState(false);
  const [scenariooptions, setscenariooptions] = useState([]);
  
  const [brandoptions, setbrandoptions] = useState([
    { value: "Brand_1", label: "Brand_1" },
    { value: "Brand_2", label: "Brand_2" },
    { value: "Brand_3", label: "Brand_3" },
    { value: "Brand_4", label: "Brand_4" },
    { value: "Brand_5", label: "Brand_5" },
    { value: "Brand_6", label: "Brand_6" },
  ]);
  const [series, setseries] = useState([])
  const [selectedmarket, setselectedmarket] = useState("")
  const [displaynames, setdisplaynames] = useState({});
  const [marketoptions, setmarketoptions] = useState(["APTG","DL","GJ","HR","KA","KL","MH","MPCH"])
  const [market, setmarket] = useState("");
  const colors = [
    "red",
    "blue",
    "green",
    "purple",
    "orange",
    "pink",
    "teal",
    "indigo",
    "brown",
    "grey",
    "olive",
    "navy",
    "maroon",
    "gold",
    "darkgreen",
    "darkred",
    "darkblue",
    "darkorange",
    "darkcyan",
    "darkmagenta",
    "darkyellow",
    "darklime",
    "darkpink",
    "darkteal",
    "darkviolet",
  ];
const [options,setoptions]=useState({})
  const [selectedzone, setselectedzone] = useState("National")
  const [selectedbrand, setselectedbrand] = useState("");
  const [selectedscenarioname, setselectedscenarioname] = useState("");
  const [selectedscenarioid, setselectedscenarioid] = useState("");
  const [selectedscenarionametimestamp, setselectedscenarionametimestamp] =
    useState("");
  const [selectedyear, setselectedyear] = useState("2021-22");
  const [resultscreen, setresultscreen] = useState(false);
  const [resultscreen2, setresultscreen2] = useState(false);
  const [resultscreen3, setresultscreen3] = useState(false);
  const [newscenarionamegiven, setnewscenarionamegiven] = useState("");
  const [predictedsalesdata, setpredictedsalesdata] = useState([]);
  const [actualsalesdata, setactualsalesdata] = useState([]);


  const sectionRef = useRef(null);
  const [monthlist, setmonthlist] = useState(["April", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec", "Jan", "Feb", "March"])
  useEffect(() => {
    handlebrandmenu();
    fetchscenariooptions();
    //fetchdatasettableonload();

  }, []);

  const orderedMonths = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

  const scrollToTop = () => {
    if (sectionRef.current) {
      const yOffset = -50; // Adjust this value based on your preference
      const elementTop = sectionRef.current.offsetTop;
      const offsetPosition = elementTop + yOffset;

      window.scrollBy(500, 500);
      // window.scroll({
      //   top: offsetPosition,
      //   behavior: 'smooth',
      // });
    }
  };
 
  const handlebrandmenu = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("jwttoken", UserService.getToken());
        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/brand/getbrands`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          setbrandoptions(getResponse.data);
          setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/modelperformance`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/modelperformance`,
        });
      }, 1000);
    }
  };

  const fetchscenariooptions = async () => {
    if (UserService.isLoggedIn()) {
      if (true) {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("jwttoken", UserService.getToken());

          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/Simulation/getscenario`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: sendData,
          };
          const getResponse = await axios(config);
          //console.log(getResponse);

          if (getResponse.status === 200) {
            if (getResponse.data !== "Invalid User!") {
              // setscenariooptions(getResponse.data?.map((item)=>{
              //   return {label:item.scenario_name,value:item.scenario_name}
              // }));
              setscenariooptions(getResponse.data);
              // setselectedscenarioname(getResponse.data[1].scenario_name);
              // setselectedscenarioid(getResponse.data[1].id)
              // setselectedscenarionametimestamp(getResponse.data[1].updated_dt);
            }
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      } else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/modelperformance`,
        });
      }, 1000);
    }
    setloader(false);
  };
  const fetchdatasettable = async () => {
    if (UserService.isLoggedIn()) {
     if(selectedbrand && market!=="Select" && market && selectedscenarioname){ try {
      setloader(true)
      let config = {};
    
      //   const requestData = {
      //     scenario_name:"scenario_0909",
      //     scenario_timestamp: "2024-09-09T15:26:43",
      //     user_id: "admin",
      //     brand:selectedbrand,
      //     f_year: selectedyear,
      //     model_id: 1,

      //   };
        

      //   config = {
      //     method: "post",
      //     url: `${REACT_APP_UPLOAD_DATA2}/Model Performance`,
      //     headers: {
      //       Accept: "text/plain",
      //       "Content-Type": "application/json",
      //       "access_token": UserService.getToken(),
      //     },
      //     data: requestData,
      //   };

      // const getResponse = await axios(config);
    const getResponse={data:{
      "actual_sales": [
          {
              "month_year": "2021-04",
              "year": "2021",
              "month": "04",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 1470.0
          },
          {
              "month_year": "2021-05",
              "year": "2021",
              "month": "05",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 889.0
          },
          {
              "month_year": "2021-06",
              "year": "2021",
              "month": "06",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2084.0
          },
          {
              "month_year": "2021-07",
              "year": "2021",
              "month": "07",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2543.0
          },
          {
              "month_year": "2021-08",
              "year": "2021",
              "month": "08",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2659.0
          },
          {
              "month_year": "2021-09",
              "year": "2021",
              "month": "09",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2483.0
          },
          {
              "month_year": "2021-10",
              "year": "2021",
              "month": "10",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 3655.0
          },
          {
              "month_year": "2021-11",
              "year": "2021",
              "month": "11",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2216.0
          },
          {
              "month_year": "2021-12",
              "year": "2021",
              "month": "12",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 3198.0
          },
          {
              "month_year": "2022-01",
              "year": "2022",
              "month": "01",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2361.0
          },
          {
              "month_year": "2022-02",
              "year": "2022",
              "month": "02",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2239.0
          },
          {
              "month_year": "2022-03",
              "year": "2022",
              "month": "03",
              "brand": "Harrier",
              "scenario_name": "Base Scenario",
              "f_year": "2021-22",
              "model_id": 1,
              "sales": 2661.0
          }
      ],
      "predictions": [
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-04",
              "weekly_predicted_sales": 2629.2261259876,
              "temp_month_year": 1617235200000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-05",
              "weekly_predicted_sales": 765.5225672414,
              "temp_month_year": 1619827200000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-06",
              "weekly_predicted_sales": 1754.3702375492,
              "temp_month_year": 1622505600000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-07",
              "weekly_predicted_sales": 2110.5942683931,
              "temp_month_year": 1625097600000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-08",
              "weekly_predicted_sales": 2765.7224618237,
              "temp_month_year": 1627776000000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-09",
              "weekly_predicted_sales": 2438.3735914273,
              "temp_month_year": 1630454400000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-10",
              "weekly_predicted_sales": 2886.2376084099,
              "temp_month_year": 1633046400000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-11",
              "weekly_predicted_sales": 2034.2668355296,
              "temp_month_year": 1635724800000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2021-12",
              "weekly_predicted_sales": 2983.6576513895,
              "temp_month_year": 1638316800000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2022-01",
              "weekly_predicted_sales": 2625.0137168552,
              "temp_month_year": 1640995200000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2022-02",
              "weekly_predicted_sales": 2383.0941637195,
              "temp_month_year": 1643673600000,
              "financial_year": 2021
          },
          {
              "scenario_name": "scenario_0909",
              "f_year": "2021-22",
              "created_dt": "2024-09-09T15:26:43",
              "week_year": "2022-03",
              "weekly_predicted_sales": 1638.9092782476,
              "temp_month_year": 1646092800000,
              "financial_year": 2021
          }
      ]
  }}
      console.log(getResponse)

      if (getResponse.data) {
    setTimeout(() => {
      setresultscreen(true);
      setloader(false);
      setpredictedsalesdata(getResponse.data?.predictions)
      
      setactualsalesdata([{name:"Actual Sales",data:getResponse.data?.actual_sales.map((it)=>it.sales.toFixed(0))},
        {name:"Predicted Sales",data:getResponse.data?.predictions.map((it)=>it.weekly_predicted_sales.toFixed(0))}
      ])
      setoptions (
        {
        chart: {    
          id: `Download`,
          type: "line",
      
       
          
          toolbar: {
            show: true,
          },
        },  grid: {
          show: false // This disables the grid
        },
        stroke: {
          width: 2 // Change this value to reduce the line width
        },
        title: {
          text: 'Actual vs Predicted',
          align: 'center',
        },
        plotOptions: {
        
        },
        colors: ['#4CAF50', '#FFC107'],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '8px !important',
       
          },
          formatter: function (val) {
            return val
          }
        },
      
        xaxis: {
          categories:getResponse.data.actual_sales.map((it)=>it.month_year) ,
          title: {
            text: 'Months',
            style:{
              color:"#FFC107",
                },
          },
          labels: {
            style: {
              fontSize: "10px !important",
              
            },
            formatter: function (val) {
              return val 
            }
          },
        },
        yaxis: [
          {
            seriesName: 'Sales',
            opposite: false,
            axisBorder: {
              show: true // Ensures Y-axis line is visible
            },
            title: {
              text: "Sales",
              showAlways: true,
              floating: true,
              style:{
                color:"#4CAF50",
                  },
              
            },
            labels: {
              style: {
                fontSize:'10px !important' ,
                
              }}
                    },
          ,
          // {
          //   seriesName: 'Predicted Sales',
          //   opposite: true,
          //   title: {
          //     text: "Predicted Sales",
          //     showAlways: true,
          //     floating: true, 
          //      style:{
          //     color:"#FFC107"
          //   },
            
          //   },
                          
            
          // },
        ],
    
      });
         setdisplaynames({
          ...displaynames,
          brand: selectedbrand,
          scenarioname: selectedscenarioname,
          id:selectedscenarioid,
        
          year: selectedyear,
          timestamp: selectedscenarionametimestamp
        })
    }, 3000);
        // if (Array.isArray(getResponse.data?.table1) && getResponse.data !== "No Records Found" && getResponse.data?.table1.length !== 0
        // ) {

        //   setresultscreen(true)
        //   setdisplaynames({
        //     ...displaynames,
        //     brand: selectedbrand,
        //     scenarioname: selectedscenarioname,
        //     id:selectedscenarioid,
        //     zone: selectedzone,
        //     year: selectedyear,
        //     timestamp: selectedscenarionametimestamp
        //   })


        //   setsampledataset(getResponse?.data?.table1.sort((a, b) => a.is_optimizable - b.is_optimizable))
        //   setactualsalesdata(getResponse?.data?.table1.sort((a, b) => a.is_optimizable - b.is_optimizable));
        //   //setsampledataset2(getResponse?.data?.table2)
        //   setpredictedsalesdata(getResponse?.data?.table2);
        //   setresultscreen2(true)
        //   let arr = new Array(13).fill(0); // Initialize an array of size 13 with all values set to 0

        //   getResponse?.data?.table1.forEach((item) => {
        //     item.month_data.forEach((monthData, index) => {

        //       arr[monthData.month] += Number(monthData.value);
        //     });
        //   });
        //   arr = arr.slice(1);
        //   arr.push(getResponse?.data?.table1.reduce((prev, next) => prev + next.subtotal, 0))
        //   // Remove the first element if you don't need it, as months are 1-based


        //   console.log(arr);
        //   setmonthtotals(arr)

        // }
        // else if (getResponse.data?.table1.length === 0) {
        //   if(displaynames){
        //     setselectedbrand(displaynames.brand)
        //     setselectedscenarioname(displaynames.scenarioname)
        //     setselectedzone(displaynames.zone)
        //     setselectedyear(displaynames.year)
        //     setselectedscenarioid(displaynames.id)
        //   }
        //   else{
        //     fetchscenariooptions()
        //     handlebrandmenu()
        //   }
        //   dispatch(
        //     getNotification({
        //       message: `There is no valid data to display for selected brand and scenario combination`,
        //       type: "default",
        //     }))
        // }
        // else if (getResponse?.data[0]?.Error === "Invalid User!") {
        //   UserService.doLogin({
        //     redirectUri: `${REACT_APP_REDIRECT_URI}/modelperformance`,
        //   });
        // }
        // else {
        
        //   if(displaynames){
        //     setselectedbrand(displaynames.brand || brandoptions[0].brand)
        //     setselectedscenarioname(displaynames.scenarioname || scenariooptions[0].scenario_name)
        //     setselectedzone(displaynames.zone || 'National')
        //     setselectedyear(displaynames.year || '2021-22')
        //     setselectedscenarioid(displaynames.id || scenariooptions[0].id)
        //   }
        //   dispatch(
        //     getNotification({
        //       message: `There is no valid data to display for selected brand and scenario combination`,
        //       type: "default",
        //     })

        //   );

        //   setresultscreen2(false)
        // }
      }
    } catch (err) {
      console.log("Server Error", err);
      if(displaynames){
        setselectedbrand(displaynames.brand || brandoptions[0].brand)
        setselectedscenarioname(displaynames.scenarioname || scenariooptions[1].scenario_name)
        setselectedzone(displaynames.zone || 'National')
        setselectedyear(displaynames.year || '2021-22')
        setselectedscenarioid(displaynames.id || scenariooptions[1].id)
      }
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        ); setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }}
     else{
      dispatch(
        getNotification({
          message: "Please fill all entries",
          type: "danger",
        })
      );
     }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/modelperformance`,
        });
      }, 1000);
    }
  
  };

 

 
  const getUserID = () => {
    const token = UserService.getToken();
    const subs = parseJwt(token);
    // console.log(token);
    const sub = subs.sub;
    //console.log(sub);
    return sub;
  };
  const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  function formatDate() {
    // Create a Date object using the specified date string
    const date = new Date();

    // Extract year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    // Return the date in "YYYY-MM-DD" format
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  
 


  return (
    <>
 

    <div className="">

      <div className="bgpages px-2 py-3 " >

        <div style={{ backgroundColor: "" }} className="row my-2 mx-2">
          <div className="col-sm-9  align-self-center">
            {/* <h5 className='orangetheme' style={{ fontWeight: "700" }} >MODEL PERFORMANCE</h5>
            <p className="text-light" >
              Dissects sales trends by comparing predicted sales with base sales
            </p> */}
          </div>
    
        </div>
        <div className="rounded-5 card mx-1 mb-3" style={{ backgroundColor: "white", border: "1px solid #E4CCFF" }}>

          <div style={{ fontWeight: "500", backgroundColor: "#EAF7FF" }} className="headingcolor p-3 rounded-5" >
            {resultscreen ? <div>
              <span className="float-end">
                              {resultscreen && (
                  <>
                    <button
                      className={"btn btn-outline-danger  btn-sm m-1"}
                      onClick={() => {
                        setresultscreen(false)
                        // setresultscreen2(false)
                        // setresultscreen3(false)
                        // setactualsalesdata([])
                        // setpredictedsalesdata([])
                        // settotalBudget(0)
                        setselectedscenarioid("")
                        setdisplaynames({})

                      }}
                    >
                      Reset
                    </button>


            
                  </>
                )}

              </span>
            </div> :"MODEL PERFORMANCE"}

          </div>
          <div className="row px-3 " >
            {loader ? (
              <div
                className="row d-flex  justify-content-center align-items-center "
                style={{ height: "60vh" }}
              >
                <Loader
                  type="box-rectangular"
                  bgColor={"#007bff"}
                  title={"Fetching your data..."}
                  color={"#000000"}
                  size={75}
                />
              </div>
            ) : resultscreen ?
              <>
                <div className="d-flex justify-content-around px-4 py-2 shadow" style={{ backgroundColor: "#fafafa" }} >



                  <div>
                    <label>Brand:<span className="text-danger">*</span></label>

                    <select
                      value={selectedbrand}
                      className="form-select mb-2"
                      onChange={(e) => setselectedbrand(e.target.value)}
                    >
                      <option>YIPPEE NOODLES</option>
                      {/* {brandoptions?.map((option) => {
                        return <option>{option.brand}</option>;
                      })} */}
                    </select>
                  </div>
                  <div className="my-2">
                     <label>Market:<span className="text-danger">*</span></label>
                     <select className="form-select"
                        placeholder="Select market"
                        options={marketoptions}
                        onChange={(e) => {
                          setmarket(e.target.value);
                        }}
                        value={market}
                      >
                        <option>Select</option>
                        {marketoptions?.map((item)=>{
                          return <option>{item}</option>
                        })}
                      </select>





                   </div>
                  <div>
                    <label>Model Callibration:</label>

                    <select
                      className="form-select mb-2"
                      value={selectedscenarioname}
                      onChange={(e) => {
                        setselectedscenarioname(e.target.value);
                        let arr = scenariooptions.filter((it) => { return e.target.value === it.scenario_name })
                        setselectedscenarioid(arr[0].id)
                        console.log(arr[0].updated_dt)
                        setselectedscenarionametimestamp(arr[0].updated_dt)
                      }}
                    >
                      <option>Select</option>
                      {scenariooptions?.map((option) => {
                        return (
                          <option
                          
                          >
                            {option.scenario_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div>
                    <label>Zone:</label>
                    <select className="form-select  mb-2" value={selectedzone} onChange={(e) => setselectedzone(e.target.value)}>
                      <option selected>National</option>
                      <option>East</option>
                      <option>West</option>
                      <option>North</option>
                      <option>South</option>
                    </select>
                  </div> */}

                  <div>
                    {" "}
                    <label htmlFor="textarea">Year: </label>
                    <div className="">
                      <select className="form-select  mb-2" value={selectedyear} onChange={(e) => setselectedyear(e.target.value)}>
                        <option>2021-22</option>
                        <option>2022-23</option>
                        <option>2023-24</option>
                      </select>
                    </div>
                  </div>



                  {/* <div className="form-check ">
                    <input
                      className="form-check-input "
                      type="radio"
                      checked
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => { }}
                    />
                    <label
                      className="form-check-label selected"
                      for="flexRadioDefault2"
                    >
                      Annual
                    </label>{" "}
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      disabled
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onClick={() => { }}
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Quarterly
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      disabled
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => { }}
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Semi-Annual
                    </label>{" "}
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      disabled
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => { }}
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Define
                    </label>{" "}
                  </div> */}



                  <div>
               
                  </div>


                </div>
                <div className="col-sm-12 p-3">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne" >
                        <button id="OptimizationInputs" className="accordion-button" style={{ backgroundColor: "#cfe2ff" }} type="button" data-toggle={resultscreen3 ? "collapse" : ""} data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                           Comparison
                        </button>
                      </h2>
                      <div id="collapseOne" className="show collapse" aria-labelledby="headingOne" >
                        <div className="accordion-body mt-3">
                        <table className='text-center table table-sm my-3 table-striped shadow table-bordered table-responsive-md'>
            <thead className='text-center' style={{}}>
              <tr>  <th>S. No.</th>
                <th>Brand</th>
                <th>Market</th>
                <th colspan="2">Data Available</th>
                <th colspan="2">Model Refresh</th>
                <th>R<sup>2</sup></th>
                <th>MAPE</th>
                <th>Color Code</th></tr>
                <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>From</th>
                <th>To</th>
                <th>From</th>
                <th>To</th>
                <th></th>
                <th></th>
                <th></th>
                
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>YIPPEE NOODLES</td>
                    <td>APTG</td>
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.67</td>
                     <td >14%</td>
                     <td style={{backgroundColor:"#a9d08e"}}></td>
                </tr>
                <tr>
                    <td>2</td>
                      <td>YIPPEE NOODLES</td>
                    <td>DL</td>
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.10</td>
                     <td >31%</td>
                     <td style={{backgroundColor:"#ff0000"}}></td>
                </tr>
            
                <tr>
                    <td>3</td>
                      <td>YIPPEE NOODLES</td>
                    <td>GJ</td>
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.45</td>
                     <td>20%</td>
                     <td style={{backgroundColor:"#e2efda"}}></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>YIPPEE NOODLES</td>
                    <td>HR</td>
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.27</td>
                     <td >51%</td>
                     <td style={{backgroundColor:"#ff0000"}}></td>
                </tr> 
                <tr>
                    <td>5</td>
                      <td>YIPPEE NOODLES</td>
                    <td>KA</td>
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.51</td>
                     <td >14%</td>
                     <td style={{backgroundColor:"#a9d08e"}}></td>
                </tr>
                <tr>
                    <td>6</td>
                      <td>YIPPEE NOODLES</td>
                    <td>KL</td>
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.65</td>
                     <td >9%</td>
                     <td style={{backgroundColor:"#548235"}}></td>
                </tr>
                <tr>
                    <td>7</td>
                      <td>YIPPEE NOODLES</td>
                    <td>MH</td>
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.60</td>
                     <td >12%</td>
                     <td style={{backgroundColor:"#a9d08e"}}></td>
                </tr>
                <tr>
                <td>8</td>
                    <td>YIPPEE NOODLES</td>
                    <td>MH</td>
                     
                   
                     <td>2023-01-01</td>
                     <td>2023-12-31</td>
                     <td>2024-01-01</td>
                     <td>2024-08-31</td>
                     <td>0.47</td>
                     <td >9%</td>
                     <td style={{backgroundColor:"#548235"}}></td>
                </tr>
            </tbody>
         </table>
                         <Chart
                         align="center"
        options={options}
        series={actualsalesdata}
        height={400}
        width={'80%'}
      /> 
                        </div>
                      </div>
                    </div>
                    {resultscreen3 && <div className="accordion-item accordion-item2  my-2">
                      <h2 className="accordion-header " id="headingTwo">
                        <button className="accordion-button collapsed orangetheme2"
                          type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Optimization Output/Result
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse show" aria-labelledby="headingTwo" >
                        <div className="accordion-body">
                          <div >
                            {
                              loader ?
                                <div
                                  className="d-flex  justify-content-center align-items-center "
                                  style={{ height: "50vh" }}
                                >
                                  <Loader
                                    type="box-rectangular"
                                    bgColor={"#007bff"}
                                    title={"Optimization in progress..."}
                                    color={"#000000"}
                                    size={75}
                                  /></div> :

                                <div>
                                  <div className="row ">

                                    <h5 className="my-4">Optimized Data</h5>
                               




                                  </div>


                                </div>}
                          </div>      </div>
                      </div>
                    </div>}

                  </div>



                </div>
              </> :
              <div className="row">
                <div className="col-sm">
                  {brandoptions.length > 0 && scenariooptions.length > 0 ?

                    <div className="">
                      <div className="p-4 " >

                        <div>
                          <label>Brand:<span className="text-danger">*</span></label>

                          <select
                            value={selectedbrand}
                            className="form-select mb-2"
                            onChange={(e) => setselectedbrand(e.target.value)}
                          >
                                  <option>YIPPEE NOODLES</option>
                            {/* {brandoptions?.map((option) => {
                              return <option>{option.brand}</option>;
                            })} */}
                          </select>
                          <div className="my-2">
                     <label>Market:<span className="text-danger">*</span></label>
                     <select className="form-select"
                        placeholder="Select market"
                        options={marketoptions}
                        onChange={(e) => {
                          setmarket(e.target.value);
                        }}
                        value={market}
                      >
                            <option>Select</option>
                        {marketoptions?.map((item)=>{
                          return <option>{item}</option>
                        })}
                      </select>





                   </div>
                          <label>Model Callibration:<span className="text-danger">*</span></label>

                          <select
                            className="form-select mb-2"
                            value={selectedscenarioname}
                            onChange={(e) => {
                              
                              setselectedscenarioname(e.target.value);
                              let arr = scenariooptions.filter((it) => { return e.target.value === it.scenario_name })
                              setselectedscenarioid(arr[0].id)
                              setselectedscenarionametimestamp(arr[0].updated_dt)
                            }}
                          >
                             <option>Select</option>
                            {scenariooptions?.map((option) => {
                              return (
                                option.scenario_name!=="Base Scenario" &&
                                <option
                               
                                >
                                  {option.scenario_name}
                                </option>
                              );
                            })}
                          </select>
                          {  <div className=''>
                            <div className="d-flex flex-row-reverse">
                  <button
                      className="btn btn-primary my-2"
                      onClick={() => {
                        fetchdatasettable();
                      }}
                    >
                      Submit
                    </button>
                  </div>
      
        
        </div>}
                          {/* <label>Zone:</label>
                          <select className="form-select  mb-2" value={selectedzone} onChange={(e) => setselectedzone(e.target.value)}>
                            <option selected>National</option>
                            <option>East</option>
                            <option>West</option>
                            <option>North</option>
                            <option>South</option>
                          </select> */}
{/*                       
                          <button
                            className="btn btn-primary mt-2"
                            onClick={() => {
                              fetchdatasettable();
                            }}
                          >
                            Submit
                          </button> */}
                        </div>

                      </div>



                    </div> : <span className="my-2">Loading...</span>}</div>
                {/* <div className="col-sm-4 my-auto">
                  <img src="../Assets/Images/image1.png" className="" align="right" style={{width:"500px"}} />
                </div> */}

                <div id="" className="p-4  ">
                  <div>
                    {resultscreen2 &&

                      <div>

                        {/* <div className="text-end">
            <label>Brand:</label>
            <span className="mx-2">{displaynames.brand}</span>
          </div>
          <div className="text-end">
            <label>Model Callibration:</label>
            <span className="mx-2">{displaynames.scenarioname}</span>
          </div> */}
                        <div className="text-end">

                        

                         

                        </div>

                      
                        <div id="simulateddatacharts" ref={sectionRef}>
                          {
                            loader ?
                              <div
                                className="d-flex  justify-content-center align-items-center "
                                style={{ height: "10vh" }}
                              >
                                <Loader
                                  type="box-rectangular"
                                  bgColor={"#007bff"}
                                  title={"Fetching data..."}
                                  color={"#000000"}
                                  size={75}
                                /></div> :
                              resultscreen &&
                              <div>
                                <div className="row ml-4">

                                  <h5 className="my-4">Optimized Data</h5>
                                  <div className="col-sm-3">
                                  </div>




                                </div>


                              </div>}
                        </div>
                      </div>



                    }
                  </div>
                </div>





              </div>}
          </div>
        </div>




      </div>




    </div>





    <div className='' >
      <FooterPages />
    </div>
  </>
    
  )
}

export default ModelPerformance