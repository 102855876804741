import React from 'react'
import Navbar2 from './Navbar2'
import FooterPages from './FooterPages'
//import note from "/Assets/Images/note.png"

import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'

function Dashboard() {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <>
        <Navbar2/>
        <div className='container-fluid text-sm'>
          <div class="row" >

  
            <div  className="d-flex justify-content-between align-items-center  bg-white py-2">
                <div class=" rounded d-flex flex-row flex-grow-1 flex-fill justify-content-center py-2 text-white px-1 news" style={{backgroundColor:"#F15E22"}}><span class="d-flex align-items-center p-1">Important<i class='fas fa-bell ml-2 '></i></span></div>
                <marquee class="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();"> 
                <a href="#" >Brand  Campaign No 5 is due in 2 days. </a> <span class="dot"></span> 
                  <a href="#"> Brand Analysis report ready for download.</a>
                </marquee>
            </div>
        
      </div>
                <div className='row dashboard '
                //  style={{ backgroundColor: "#f0eded" }}
                 >
                  
                    
                    <div className='col-sm-8  m-3  d-flex justify-content-between flex-wrap' >

                    <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className='my-2  card'>
                            <div className='text-right'>
<button className='btn-sm btn m-3 btn-dark' style={{ color:"white" }}>Diagnostics</button>
</div>
                                <div className='mx-4'>
                                    <div className="" style={{ color: "#426AB3" }}><b>Brand Analysis</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}>Brand Analysis dissects expenditure trends among single brand with multiple tactics in a specific source of expenditure over a defined time frame, providing valuable insights for analytics.</span>
                                </div>
                                <div className='text-right'>
                                <button className='btn-sm btn m-2  mt-2 btn-outline-dark' onClick={()=>navigate('/brandanalysis')}>Try Now</button>
                            </div></div>
        
                    
                            <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className='my-2 card'>
                            <div className='text-right'>
                               <button className='btn-sm btn btn-secondary m-3' style={{ color:"white" }}>Admin Control</button>
                                
                                </div>
                                <div className='mx-4 '>
                                    <div className="" style={{color:"#426AB3"}}><b> Refresh Model</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}>Refresh Model feature enables the administrator to upload a new Excel file containing updated data and refresh the system's model accordingly.</span>
                                </div>
                                <div className='text-right'>
                                <button className='btn-sm btn m-2 mt-2 btn-outline-dark' onClick={()=>navigate('/refreshmodel')}>Try Now</button>
                                </div>
                                
                            </div>
                            <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className='my-2 card'>
                               <div className='text-right'>
                               <button className='btn-sm btn m-3 btn-secondary' style={{color:"white" }}>Admin Control</button>
                                                                </div>
                                                                 <div className='mx-4'>
                                    <div className="" style={{ color: "#426AB3" }}><b>Model Performance</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}> Model Performance compares actual outcomes with predicted results to assess accuracy and reliability of the model which provides insights for refining and improving model predictions over time.</span>
                                </div>
                                <div className='text-right'>
                                <button className='btn-sm btn m-2 float-right mt-2 btn-outline-danger' onClick={()=>navigate('/modelperformance')}>Try Now</button>
                            
                                </div>
                                </div>
                             
                            <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className='my-2  card'>
                               <div className='text-right'>
                               <button className='btn-sm btn m-3 btn-danger' style={{color:"white" }}>Manipulation</button>
                                                                </div>
                                                                 <div className='mx-4'>
                                    <div className="" style={{ color: "#426AB3" }}><b>Simulator</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}>Simulator offers an advanced solution for analyzing expenditure data, transforming raw financial inputs into comprehensive charts,uncovering patterns and trends, enabling users to gain actionable insights.</span>
                                </div>
                                <div className='text-right'>
                                <button className='btn-sm btn m-2 float-right mt-2 btn-outline-danger' onClick={()=>navigate('/simulator')}>Try Now</button>
                            
                                </div>
                                </div>


                    
                            <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className=' my-2 card'>
                            <div className='text-right'>
                             <button className='btn-sm btn m-3 btn-danger' style={{ color:"white" }}>Manipulation</button>
                                                                </div><div className='mx-4'>
                                    <div className="" style={{ color: "#426AB3" }}><b>Optimizer</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}>Optimizer analyzes expenditure data and user-defined budgets to recommend optimal spending strategies helping in allocation of resources, ensuring maximum return on investment.</span>
                                </div>
                              <div className='text-right'>
                              <button className='btn-sm btn m-2  mt-2 btn-outline-danger' onClick={()=>navigate('/optimizer')}>Try Now</button>
                            
                            </div> 
                                </div>
                                <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className=' my-2 card'>
                            <div className='text-right'>
                             <button className='btn-sm btn m-3 btn-danger' style={{ color:"white" }}>Manipulation</button>
                                                                </div><div className='mx-4'>
                                    <div className="" style={{ color: "#426AB3" }}><b>Compare Optimized Scenario</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}>Optimizer analyzes expenditure data and user-defined budgets to recommend optimal spending strategies helping in allocation of resources, ensuring maximum return on investment.</span>
                                </div>
                              <div className='text-right'>
                              <button className='btn-sm btn m-2  mt-2 btn-outline-danger' onClick={()=>navigate('/compareoptimizedscenario')}>Try Now</button>
                            
                            </div> 
                                </div>  
                            <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className=' my-2 card'>
                            <div className='text-right'>
                              <button className='btn-sm btn m-3' style={{ backgroundColor: "#0068A6",color:"white" }}>Campaigns</button>
                              </div>
                                <div className='mx-4'>
                                    <div className="" style={{ color: "#426AB3" }}><b>Event Management</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}>Event Management allows users to create, edit, and customize campaigns. It also provides real-time monitoring, enabling users to track performance and make adjustments for optimal campaign results.</span>
                                </div>
                                <div className='text-right'>
                                <button className='btn-sm btn m-2 float-right mt-2 btn-outline-primary'  onClick={()=>navigate('/eventmanagement')}>Try Now</button>
                            
                                </div>
                               </div>
                         

                       
 
       <div style={{ backgroundColor: "white" ,border:"5px solid #EAF7FF",  width: "300px",height:"300px"  }} className='my-2 card'>
                            <div className='text-right'>
                              <button className='btn-sm btn m-3 btn-secondary' style={{ color:"white" }}>User Saved</button>
                                
                            </div>
                                <div className='mx-4 '>
                                    <div className="" style={{ color: "#426AB3" }}><b>Saved Reports</b></div>
                                    <hr className="" style={{ backgroundColor: "black", height: "1px" }} />
                                    <span style={{ fontSize: "13px" }}>
                                    Saved Reports lets User to access securely their saved reports for future reference and continued analysis.</span>
                                </div>
                                <div className='text-right'>
                                <button className='btn-sm btn btn-outline-secondary m-2 float-right mt-2'  onClick={()=>navigate('/savedreports')}>Try Now</button>
                      </div>  
                          </div>
                          
                        
                    </div>
                    <div className='col-sm-3 mt-5 ml-5 position-relative'>
                       
                       <div className='card p-1 mb-1 ' style={{backgroundColor: "white",border:"2px solid #F79548",minHeight:"55vh" }}>
                           <div className='text-center mt-3'>
                               <span style={{ color: "#F79548" }}><b>UPDATES</b></span>
                               <hr className="mx-2" style={{ backgroundColor: "black", height: "1px" }} />
                              
                           </div>
                       <div className=''>
                       <div>
                               <ul>
                                   <li>Brand Analysis report ready for download
                                   </li>
                                   <li>Campaign No 5 is due in 2 days</li>
                               </ul>
                           </div>
                         
                       </div>
                       </div>
                       <div className='position-absolute imagedashboard'>
                       <img src="/Assets/Images/image4.png" className='mx-auto '  />
                       </div>
                       
                       </div>
                  
                              
                      

                      
                 
                    {/* <div className='col-sm-1 d-flex align-items-end'>
             
             <img src="/Assets/Images/note.png" className='' align="right" style={{ height: "100px" }} />
             </div>  */}
                      </div>

        </div>
        <FooterPages/>
        </>
    )
}

export default Dashboard