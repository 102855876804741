import "./App.css";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";
import Navbar from "./Components/Navbar";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Provider } from "react-redux";
import store from "./Redux/Store/store";
import Simulator from "./Components/Simulator"
import Home from "./Components/Home.jsx";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Optimizer from "./Components/Optimizer";
import BrandAnalysis from "./Components/BrandAnalysis";
import CompetitiveAnalysis from "./Components/CompetitiveAnalysis";
import ModelPerformance from "./Components/ModelPerformance";
import SavedReports from "./Components/SavedReports";
import Support from "./Components/Support";
import Testtoken from "./Components/Testtoken";
import EventManagement from "./Components/EventManagement"; 
import Dashboard from "./Components/Dashboard";
import Navbar2 from "./Components/Navbar2";
import RefreshModel from "./Components/RefreshModel.jsx";
import CompareOptimizedScenario from "./Components/CompareOptimizedScenario.jsx";


function App() {
  return (
    <div className="App">
      <Provider store={store}>
   
       <BrowserRouter>
        <ReactNotifications />
        <Navbar />
        <RenderOnAuthenticated> 
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/simulator" element={<Simulator/>}/>
            <Route exact path="/optimizer" element={<Optimizer/>}/>
            <Route exact path="/eventmanagement" element={<EventManagement/>}/>
            <Route exact path="/competitiveanalysis" element={<CompetitiveAnalysis/>}/>
            <Route exact path="/brandanalysis" element={<BrandAnalysis/>}/>
            <Route exact path="/dashboard" element={<Dashboard/>}/>
            <Route exact path="/modelperformance" element={<ModelPerformance/>}/>
            <Route exact path="/savedreports" element={<SavedReports/>}/>
            <Route exact path="/support" element={<Support/>}/>
            <Route exact path="/refreshmodel" element={<RefreshModel/>}/>
            <Route exact path="/compareoptimizedscenario" element={<CompareOptimizedScenario/>}/>
          </Routes> 
        </RenderOnAuthenticated>
        <RenderOnAnonymous>
          <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/simulator" element={<Simulator/>}/>
          <Route exact path="/optimizer" element={<Optimizer/>}/>
          <Route exact path="/competitiveanalysis" element={<CompetitiveAnalysis/>}/>
          <Route exact path="/eventmanagement" element={<EventManagement/>}/>
          <Route exact path="/brandanalysis" element={<BrandAnalysis/>}/>
          <Route exact path="/dashboard" element={<Dashboard/>}/>
          <Route exact path="/modelperformance" element={<ModelPerformance/>}/>
          <Route exact path="/savedreports" element={<SavedReports/>}/>
          <Route exact path="/support" element={<Support/>}/>
          <Route exact path="/compareoptimizedscenario" element={<CompareOptimizedScenario/>}/>
          <Route exact path="/refreshmodel" element={<RefreshModel/>}/>
          </Routes> 
           </RenderOnAnonymous>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
