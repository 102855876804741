import React,{useEffect,useState,useRef} from "react";
import UserService from "../services/UserService";
import getNotification from "../Redux/Action/action";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import quationlogoBg0 from "./Images/quationlogoBg0.png";
import home1 from "./Images/home1.png";
import home2 from "./Images/home2.png";
import home3 from "./Images/home3.png";
import home4 from "./Images/home4.png";
import home5 from "./Images/home5.png";
import home6 from "./Images/home6.mp4";
import home7 from "./Images/home7.mp4";
import home8 from "./Images/home8.mp4";
import home9 from "./Images/home9.mp4";
import home10 from "./Images/home10.mp4";
import mmxlogo from "./Images/mmx logo.png";
import logosocialmedia from "./Images/logossocialmodeia.png";

import './home.css';
import Navbar2 from "./Navbar2";
import { useSpring, animated,useScroll,useTrail,useTransition,useInView } from '@react-spring/web';

function Home() {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const scrollToSection = (sectionname) => {
    // Replace 'section2' with the id of the section you want to scroll to


  if(sectionname){
    document
    .getElementById(sectionname)
    .scrollIntoView({ behavior: "smooth" });
  }
  else{
    window.location.href = "/";
  }
    
  };
  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const onScroll = () => {
    if (elementRef.current && isElementInViewport(elementRef.current)) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll(); // Check if the element is already in the viewport

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

 const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
   

  <Navbar2  scrollToSection={scrollToSection}/>

<div className="row m-auto " >

  <div className="col-xxl-5 col-xl-5 col-sm-12 mx-5 my-3 ">
  
    <img align="right" src="/Assets/Images/home1.jpg"/>

  </div>
 
    <div className="col-xxl-5 items-center mt-lg-5  ">
 
<p className="h4 text-start" style={{color:"#0068A6"}}>Revenue Radar Tool built for the modern era</p>

<p className="h6  mt-4 ml-lg-5"> Measure the true performance of your advertsising spend  with</p>
<p className="h6 ml-lg-5 ">Revenue Radar, solutions built for the modern marketer</p>
<div className="my-4 ml-lg-5">
<p className="ml-5"><i className="fa fa-check-circle text-success mr-2"></i>Optimize Budget allocation for highest returns</p>
<p className="ml-5"><i className="fa fa-check-circle text-success mr-2"></i>Predict future marketing with precision</p>
<p className="ml-5"><i className="fa fa-check-circle text-success mr-2"></i>Plan  and propose budgets & scenarios that work for you</p>
</div>
<div className='text-right  mb-3'><button  className="btn btn-danger " onClick={()=>{
 document.getElementById("contactus").scrollIntoView({ behavior: "smooth" });
}}>Request a demo</button></div>


  </div>
    </div>
    <div className="row m-auto" >
 <div className="col-xxl-12 text-center" style={{backgroundColor:"#f9e7d9"}}>
 <img className="" align="center" src="/Assets/Images/home2.png"/>
 </div>

</div>
<div className="row mt-5 ">
<div className="col-xxl-2 " ></div>
<div className="col-xxl-5 position-relative" >
<div className=" position-absolute" style={{left:"500px"}} >
 <img className="" src="/Assets/Images/home3.png"/>
 </div>
 <div className=" p-5" id="overview" style={{backgroundColor:"#0068A6",borderRadius:"50px"}}>

<p className="h3 text-white">Data-driven Business Planning Tool</p>
<div className="mx-4 mt-4  h6">
<p className="text-white ">Robust & reliable model-building that is fast and easy to scale
</p>
<p className="text-white ">Actionable insights for budget planning and optimization</p>
<p className="text-white ">Prove incremental contribution of each channel</p>
<p className="text-white ">Refine budget allocation & optimization</p>
<p className="text-white ">Maximize the impact of your marketing spend</p>
</div>

 </div>

 </div>

 <div className="col-xxl-1" ></div>
</div>
<div className="row mt-5 text-center p-5" id="products" style={{backgroundColor:"#CACCCE"}}>
  <p className="h5 orangetheme">PRODUCTS WE OFFER</p>
<div className="mt-3">
<p className="m-0 p-0">Whether you are looking to run Marketing Mix Modeling and Planning in-house or want to work with a </p>
<p className="m-0 p-0">partner who will manage the process for you, our solution provides flexibility, transparency and focuses</p>
<p className="m-0 p-0">on continuous improvement and insight creation.</p>
</div>
<div className="line-container mt-3">
            <svg height="90" width="100%">
                <defs>
                    <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
                        <polygon points="0 0, 10 3.5, 0 7" />
                    </marker>
                </defs>
                <line x1="50%" y1="0" x2="15%" y2="70" stroke="black" stroke-width="1.5" marker-end="url(#arrowhead)" />
                <line x1="50%" y1="0" x2="40%" y2="50" stroke="black" stroke-width="1.5" marker-end="url(#arrowhead)" />
                <line x1="50%" y1="0" x2="50%" y2="50" stroke="black" stroke-width="1.5" marker-end="url(#arrowhead)" />
                <line x1="50%" y1="0" x2="60%" y2="50" stroke="black" stroke-width="1.5" marker-end="url(#arrowhead)" />
                <line x1="50%" y1="0" x2="80%" y2="70" stroke="black" stroke-width="1.5" marker-end="url(#arrowhead)" />
            </svg>
        </div>
<div className=" d-flex justify-content-around">
{/* {trailSprings.map((spring, index) => (
        <animated.div
          key={index}
          style={{
            ...spring,
          
            
          }}
        >
          <div className="box">COMPETITIVE ANALYSIS</div>
          </animated.div >
      ))} */}
    
            <div className="box">COMPETITIVE ANALYSIS</div>
            <div className="box">BRAND ANALYSIS</div>
            <div className="box">SIMULATOR</div>
            <div className="box">OPTIMIZER</div>
            <div className="box">EVENT MANAGEMENT</div>
        </div>

</div>
<div className="position-relative" id="contactus">
<div className="container  my-5 " >
  <p className="h4"><img src="./Assets/Images/home6.png"/></p>
  <p>Are you interested in learning more? Enter your
information in the form and a representative will contact you shortly.</p>
  <div className="d-flex justify-content-around flex-wrap col-sm-6">
    <div className="my-1 ">
      <p>FIRST NAME</p>
      <input className="form-control" />
    </div>
    <div className="my-1">
      <p>LAST NAME</p>
      <input className="form-control"/>
    </div>
    <div className="my-1">
      <p>EMAIL ADDRESS</p>
      <input className="form-control"/>
    </div>
    <div className="my-1">
      <p>COMPANY NAME</p>
      <input className="form-control"/>
    </div>
    <div className="my-1">
      <p>PHONE NUMBER</p>
      <input className="form-control"/>
    </div>
    <div className="my-1">
      <p>COUNTRY</p>
      <input className="form-control"/>
    </div>
   
  </div>
   <div className="my-2 ml-5" >
      <p>YOUR MESSAGE</p>
      <textarea className="form-control" />

     <button className="btn btn-danger my-3 form-control position-relative">Send Now</button>


    </div>
    <img className="position-absolute" style={{right:"20px",bottom:"0px"}} src="./Assets/Images/home7.png"/>
</div>
</div>
<div className="row" id="pricing" style={{backgroundColor:"#426AB3",borderRadius:"5%"}}>


<p className="container text-white h5 mt-3">Pick a plan to grow your brand!!</p>

<div className="row mx-5 my-4">
<div className="col-sm-5 bg-light mx-5 " style={{borderRadius:"55px"}}>
  <p className="text-center text-danger h5 mt-3">Standard</p>
  <p className="text-center text-danger h4">Rs 999 Monthly/Rs 10000 Yearly</p>
      <p className="text-center">
      <img className="" src="./Assets/Images/home4.png"/>
      </p>
  
  <p className="text-secondary text-center m-0 p-0">
  For businesses actively

  </p>
  <p className="text-secondary text-center m-0 p-0"> investing in marketing strategies </p>
<div className="text-center">
<button className="btn  btn-danger my-3">Get Started</button>
<hr className="border-secondary"/>
<p>Store & Process upto 15 gb business data</p>
<hr className="border-secondary"/>
<p>Organize and manage upto 1 campaign monthly</p>
<hr className="border-secondary"/>
<p>GST applicable</p>

</div>
</div>
<div className="col-sm-5 bg-light mx-5 " style={{borderRadius:"55px"}}>
  <p className="text-center text-info h5 mt-3">Pro</p>
  <p className="text-center text-info h4">Rs 2999 Monthly/Rs 30000 Yearly</p>
      <p className="text-center">
      <img className="" src="./Assets/Images/home5.png"/>
      </p>
  
  <p className="text-secondary text-center m-0 p-0">
  For businesses looking to build a 

  </p>
  <p className="text-secondary text-center m-0 p-0"> lasting brand and drive growth </p>
<div className="text-center">
<button className="btn  btn-info my-3">Get Started</button>
<hr className="border-secondary"/>
<p>Store & Process unlimited business data</p>
<hr className="border-secondary"/>
<p>Unlimited access to Campaign Creations </p>
<hr className="border-secondary"/>
<p>GST applicable</p>

</div>
</div>
</div>

</div>

      <div
        className="row m-5"
        style={{ backgroundColor: "white", fontSize: "2vh" }}
      >
        <div className="col-sm-1 text-center"></div>
        <div className="col-sm-3 ">
          <h5>
            {" "}
            <img className="m-1" src={mmxlogo} style={{ height: "30px" }} />
         
          </h5>

          <div>
            <p>With near real time data modelling,</p>
            <p>You can expand your business to heights.</p>
            <p>
              <img src={logosocialmedia} />
            </p>
          </div>
        </div>
        <div className="col-sm-2 ">
          <h5>Company</h5>
          <p>About Us </p>
          <p>Support</p>
        </div>
        <div className="col-sm-2 ">
          <h5>Features</h5>
          <p>Data Modelling</p>
          <p>Analytics</p>
          <p>Decision Support</p>
        </div>
        <div className="col-sm-4">
          <h5>Contact Us</h5>

          <p>
            Quation Solutions Private Limited <br />
            6th Floor, Tower 3, WARP SJR I Park, EPIP Zone Opp. Sri Sathya Sai
            Hospital Whitefield Bangalore
          </p>
          <p>
            <i className="fa fa-envelope"></i>: Quation@quation.in
          </p>
          <p>
            <i className="fas fa-mobile-alt"></i>:  +91-80-49568423
          </p>
          <label>Subscribe to our Newsletter:</label>
          <input
            className="form-control"
            placeholder="Enter your email address here"
          ></input>
        </div>

        {/* <div className=" d-flex align-items-center justify-content-center">Contact Us </div>  */}
      </div>
      <div className="text-center p-1" style={{ backgroundColor: "#fafafa" }}>
        {" "}
        <img
          className=""
          style={{ width: "160x", height: "35px" }}
          src={quationlogoBg0}
        />
      </div>
      <div className=" text-center p-2" style={{ backgroundColor: "#fafafa",color:"black" }}>
      Copyright©2023,Quation Solutions Private Limited.All rights reserved.
      </div>
    </>
  );
}

export default Home;
