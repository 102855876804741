import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'

function FooterPages() {
    const navigate = useNavigate()
    const location = useLocation()
  return (
    <div className="container-fluid p-2" >
    <div className='row mx-4'  >
<div className='col-sm'>
<img src="/Assets/Images/quationlogoBg0.png" style={{ height: "32px" }}/>
<span style={{color:"grey",fontSize:"12px"}}>        Copyright©2023,Quation Solutions Private Limited.All rights reserved.</span>
</div>
        <div className='col-sm d-flex justify-content-between mr-4'>
                  
                    
                        <Link className='nav-link text-black'>
                        <img className="mr-1" width="20" height="20" src="https://img.icons8.com/fluency/20/mail--v1.png" alt="mail--v1" />
                            Contact Us : quation@quation.in
                        </Link>
             
                        <Link style={{ color: "#000000" }} className="nav-link text-black" to="/privacy-policy">
                            <span className='underline-nav'>Privacy Policy</span>
                        </Link>
                        <Link style={{ color: "#000000" }} className="nav-link text-black" to="/terms-of-service">
                            <span className='underline-nav'>Terms of service</span>
                        </Link>
           
                </div>
    </div>
    </div>
  )
}

export default FooterPages